import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import { ADD_ON_DEMAND_TASK, GET_DOWNLOAD, SAVE_PROJECT_INFO } from "../../../redux/project/projectActions";
import { selectAzimuthalIntegration, selectAzimuthalIntegrationAzimuthalBinning, selectAzimuthalIntegrationAzimuthalRange, selectAzimuthalIntegrationIntegration, selectAzimuthalIntegrationNumberOfAzimuthalBins, selectAzimuthalIntegrationNumberOfPoints, selectAzimuthalIntegrationPartitions, selectAzimuthalIntegrationRange, selectAzimuthalIntegrationXAxisUnit } from "../../../redux/azimuthalIntegration/azimuthalIntegrationSelectors";
import { SET_AZIMUTHAL_INTEGRATION_AZIMUTHAL_BINNING, SET_AZIMUTHAL_INTEGRATION_AZIMUTHAL_RANGE_LOWER, SET_AZIMUTHAL_INTEGRATION_AZIMUTHAL_RANGE_UPPER, SET_AZIMUTHAL_INTEGRATION_INTEGRATION, SET_AZIMUTHAL_INTEGRATION_NUMBER_OF_AZIMUTHAL_BINS, SET_AZIMUTHAL_INTEGRATION_NUMBER_OF_PARTITIONS, SET_AZIMUTHAL_INTEGRATION_NUMBER_OF_POINTS, SET_AZIMUTHAL_INTEGRATION_PARTITIONS, SET_AZIMUTHAL_INTEGRATION_RANGE_LOWER, SET_AZIMUTHAL_INTEGRATION_RANGE_UPPER, SET_AZIMUTHAL_INTEGRATION_X_AXIS_UNIT } from "../../../redux/azimuthalIntegration/azimuthalIntegrationActions";
import DropDown from "../shared/drop-down";
import { AzimuthalIntegrationXAxis } from "../../../data/azimuthal_integrations";
import toast from "react-hot-toast";
import { AzimuthalIntegrationModel } from "../../../models/analysis.azimuthalIntegration.model";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { FileSelection } from "../shared/file-selection/file-selection";
import { getLabelForValue } from "../../../helpers/redux";
import { validateInputAzimuthalIntegration } from "./azimuthal-integration-helpers";
import { selectCurrentProject } from "../../../redux/project/projectSelectors";


const AzimuthalIntegrationInput = ({ dispatch, pipeline=false }: { dispatch: Dispatch, pipeline?: boolean }) => {
	const partitions = useSelector(selectAzimuthalIntegrationPartitions);
	const xAxisUnit = useSelector(selectAzimuthalIntegrationXAxisUnit);
	const range = useSelector(selectAzimuthalIntegrationRange);
	const rangeAzimuthal = useSelector(selectAzimuthalIntegrationAzimuthalRange);
	const numberOfPoints = useSelector(selectAzimuthalIntegrationNumberOfPoints);
	const azimuthalBinning = useSelector(selectAzimuthalIntegrationAzimuthalBinning);
	const azimuthalNumberOfBins = useSelector(selectAzimuthalIntegrationNumberOfAzimuthalBins);
	const integration = useSelector(selectAzimuthalIntegrationIntegration);
	const azimuthalIntegration: AzimuthalIntegrationModel = useSelector(selectAzimuthalIntegration);
	const currentProject = useSelector(selectCurrentProject);

	const handleNumberOfPartitionsChange = (event: number | number[]) => {
		if (Array.isArray(event)) {
			event = event[0];
		}
	
		dispatch({ type: SET_AZIMUTHAL_INTEGRATION_NUMBER_OF_PARTITIONS, payload: event });
		const newPartitions = [...partitions];
		if (newPartitions.length < event) {
			for (let i = newPartitions.length; i < event; i++) {
				newPartitions.push({ name: `Partition ${i + 1}`, dataFiles: [], calibrationFile: "", darkCurrentFile: "", maskFile: "" });
			}
		} else {
			newPartitions.splice(event);
		}

		dispatch({ type: SET_AZIMUTHAL_INTEGRATION_PARTITIONS, payload: newPartitions });
	};

	return (
		<>
		<div className={"flex flex-col flex-1 pt-2 bg-primary-800 rounded-md overflow-x-hidden"}>
			<h1 className={`text-white font-bold mb-2 pl-2 text-center ${pipeline ? "text-3xl": "text-xl"}`}>Azimuthal Integration</h1>
				<div className={`p-2 flex flex-col bg-primary-600 mb-2 rounded-md mx-1 ${pipeline ? "mt-4": ""}`}>
					<h1 className={"font-bold text-white text-base mb-2 text"}>
						Partitions
					</h1>
					<div className="flex flex-col items-center w-full">
						<div className="flex items-center space-x-4 relative my-5 w-3/4">
							<Slider
								min={1}
								max={4}
								onChange={handleNumberOfPartitionsChange}
								dots={true}
								defaultValue={4}
								marks={{ 1: 1, 2: 2, 3: 3, 4: 4 }}
								step={1}
								value={partitions.length}
							/>
						</div>
					</div>
					<h1 className={"font-bold text-white text-base mb-2 mt-4"}>
						Select Files
					</h1>
					<div className={"p-2 flex flex-col bg-primary-600 mb-2 rounded-md w-full"}>
						{!pipeline && (
							<>
							<FileSelection
								dispatch={dispatch}
								title="Select Detector Image(s)"
								extensions={[".tif", ".h5"]}
								multiple={true}
								multipleFileSelection={{
									multipleTitle: "Detector",
									key: "dataFiles",
									selected: partitions,
									setSelected: (groups) => {
										dispatch({type: SET_AZIMUTHAL_INTEGRATION_PARTITIONS, payload: groups})
									}
								}}
							/>
							<br />
							</>
						)}
						<FileSelection
							dispatch={dispatch}
							title="Select Calibration/PONI File(s)"
							extensions={[".poni"]}
							defaultExtension={".poni"}
							multiple={false}
							multipleFileSelection={{
								multipleTitle: "Detector",
								key: "calibrationFile",
								selected: partitions,
								setSelected: (groups) => {
									dispatch({type: SET_AZIMUTHAL_INTEGRATION_PARTITIONS, payload: groups})
								}
							}}
						/>
						<br />
						<FileSelection
							dispatch={dispatch}
							title="Select Dark Current File(s)"
							extensions={[".tif"]}
							defaultExtension={".tif"}
							multiple={false}
							multipleFileSelection={{
								multipleTitle: "Detector",
								key: "darkCurrentFile",
								selected: partitions,
								setSelected: (groups) => {
									dispatch({type: SET_AZIMUTHAL_INTEGRATION_PARTITIONS, payload: groups})
								}
							}}
						/>
						<br />
						<FileSelection
							dispatch={dispatch}
							title="Select Mask File(s)"
							extensions={[".edf", ".h5", ".tif", ".npy"]}
							multiple={false}
							multipleFileSelection={{
								multipleTitle: "Detector",
								key: "maskFile",
								selected: partitions,
								setSelected: (groups) => {
									dispatch({type: SET_AZIMUTHAL_INTEGRATION_PARTITIONS, payload: groups})
								}
							}}
						/>
					</div>
				</div>
				<div className={"p-2 flex flex-col bg-primary-600 mb-2 rounded-md mx-1 text-white"}>
					<h1 className={"font-bold text-white text-base mb-2"}>
						Units
					</h1>
					<div className={"flex items-center flex-col mb-2 mt-3 text-center"}>
						<DropDown
							DropDownOptions={AzimuthalIntegrationXAxis}
							setSelected={(item) => dispatch({ type: SET_AZIMUTHAL_INTEGRATION_X_AXIS_UNIT, payload: item })}
							selected={xAxisUnit}
							multiple={false}
							title={xAxisUnit ? `Select Radial (x-axis) Unit: ${getLabelForValue(xAxisUnit, AzimuthalIntegrationXAxis)}` : "Select Radial (x-axis) Unit"}
						/>
					</div>
					<h1 className={"font-bold text-white text-base mb-2 mt-5"}>
						x-Axis Range
					</h1>
					<div className={"flex flex-row items-center justify-center"}>
						<div className={"flex flex-col items-center justify-center w-1/2 ml-5 mr-5"}>
							<label className={"text-white"}>
								Range Lower
							</label>
							<input
								className={`${false ? "border-red-500" : "border-gray-500"} bg-primary-700 p-2 rounded-md border border-solid text-white w-full`}
								type={"number"}
								step="0.001"
								value={range.lower.toString()}
								onChange={(e) => dispatch({type: SET_AZIMUTHAL_INTEGRATION_RANGE_LOWER, payload: Number(e.target.value)})}
							/>
						</div>
						<div className={"flex flex-col items-center justify-center w-1/2 ml-5 mr-5"}>
							<label className={"text-white"}>
								Range Upper
							</label>
							<input
								className={`${false ? "border-red-500" : "border-gray-500"}
									bg-primary-700 p-2 rounded-md border border-solid text-white w-full`}
								type={"number"}
								step="0.01"
								value={range.upper.toString()}
								onChange={(e) => dispatch({type: SET_AZIMUTHAL_INTEGRATION_RANGE_UPPER, payload: Number(e.target.value)})}
							/>
						</div>
					</div>
					<h1 className={"font-bold text-white text-base mt-5"}>
						Number of Data Points
					</h1>
					<div className={"flex flex-row items-center justify-center"}>
						<div className={"flex flex-col w-1/2 ml-5 mr-5"}>
							<label className={"text-white text-center"}>
								Number of Points
							</label>
							<input
								className={`${false ? "border-red-500" : "border-gray-500"}
									bg-primary-700 p-2 rounded-md border border-solid text-white w-full`}
								type={"number"}
								step="1"
								value={numberOfPoints.toString()}
								onChange={(e) => dispatch({type: SET_AZIMUTHAL_INTEGRATION_NUMBER_OF_POINTS, payload: Number(e.target.value)})}
							/>
						</div>
					</div>
					<h1 className={"font-bold text-white text-base mb-2 mt-5"}>
						Azimuthal Range (degrees)
					</h1>
					<div className={"flex flex-row items-center justify-center"}>
						<div className={"flex flex-col items-center justify-center w-1/2 ml-5 mr-5"}>
							<label className={"text-white"}>
								Range Lower
							</label>
							<input
								className={`${false ? "border-red-500" : "border-gray-500"}
									bg-primary-700 p-2 rounded-md border border-solid text-white w-full`}
								type={"number"}
								step="0.1"
								min={0}
								max={360}
								value={rangeAzimuthal.lower.toString()}
								onChange={(e) => dispatch({type: SET_AZIMUTHAL_INTEGRATION_AZIMUTHAL_RANGE_LOWER, payload: Number(e.target.value)})}
							/>
						</div>
						<div className={"flex flex-col items-center justify-center w-1/2 ml-5 mr-5"}>
							<label className={"text-white"}>
								Range Upper
							</label>
							<input
								className={`${false ? "border-red-500" : "border-gray-500"}
									bg-primary-700 p-2 rounded-md border border-solid text-white w-full`}
								type={"number"}
								step="0.1"
								min={0}
								max={360}
								value={rangeAzimuthal.upper.toString()}
								onChange={(e) => dispatch({type: SET_AZIMUTHAL_INTEGRATION_AZIMUTHAL_RANGE_UPPER, payload: Number(e.target.value)})}
							/>
						</div>
					</div>
					<h1 className={"font-bold text-white text-base mb-2 mt-5"}>
						Azimuthal Binning
					</h1>
					<div className={"flex flex-row items-center justify-center mb-3"}>
						<div className="flex items-center justify-start w-3/4">
							<input
								type="checkbox"
								id="checkbox-1"
								className="w-4 h-4 bg-white border border-white-300 rounded mr-2"
								onChange={() => dispatch({type: SET_AZIMUTHAL_INTEGRATION_AZIMUTHAL_BINNING, payload: !azimuthalBinning})}
								checked={azimuthalBinning}
							/>
							<label htmlFor="checkbox" className="flex items-center cursor-pointer text-white">
								<span>Azimuthal Binning</span>
							</label>
						</div>
					</div>
					{azimuthalBinning &&
					<>
						<div className={"flex flex-row items-center justify-center mt-1"}>
							<div className={"flex flex-col w-1/2 ml-5 mr-5"}>
							<label className={"text-white text-center"}>
								Number of Azimuthal Bins
							</label>
							<input
								className={`${false ? "border-red-500" : "border-gray-500"}
									bg-primary-700 p-2 rounded-md border border-solid text-white w-full`}
								type={"number"}
								step="1"
								value={azimuthalNumberOfBins.toString()}
								onChange={(e) => dispatch({type: SET_AZIMUTHAL_INTEGRATION_NUMBER_OF_AZIMUTHAL_BINS, payload: Number(e.target.value)})}
							/>
						</div>
					</div>
					</>
					}
					<h1 className={"font-bold text-white text-base mb-2 mt-5"}>
						Full Integration
					</h1>
					<div className={"flex flex-row items-center justify-center mb-3"}>
						<div className="flex items-center justify-start w-3/4">
							<input
								type="checkbox"
								id="checkbox-2"
								className="w-4 h-4 bg-white border border-white-300 rounded mr-2"
								onChange={() => dispatch({type: SET_AZIMUTHAL_INTEGRATION_INTEGRATION, payload: !integration})}
								checked={integration}
							/>
							<label htmlFor="checkbox" className="flex items-center cursor-pointer text-white">
								<span>Integration</span>
							</label>
						</div>
					</div>
				</div>
				<button
					className={"cursor-pointer w-11/12 text-white font-bold bg-primary-600 m-4 p-4 rounded mt-6"}
					style={{ marginLeft: "20px"}}
					onClick={() => {

						if (pipeline) {
							dispatch({ type: SAVE_PROJECT_INFO, payload: {
								...azimuthalIntegration,
								caking: azimuthalIntegration.azimuthalBinning,
								userId: currentProject.userId,
								projectId: currentProject.projectId,
								projectType: "azimuthalIntegration"
							} });
							return;
						}
						
						try {
							const valid = validateInputAzimuthalIntegration(partitions, numberOfPoints, rangeAzimuthal, azimuthalBinning, azimuthalNumberOfBins, range);

							if (!valid) {
								return;
							}

						} catch (e) {
							toast.error("Invalid inputs")
							console.error(e);
							return;
						}

						let orderArgs = {
							partitions: azimuthalIntegration.partitions,
							xAxisUnit: azimuthalIntegration.xAxisUnit,
							range: azimuthalIntegration.range,
							numberOfPoints: azimuthalIntegration.numberOfPoints,
							azimuthalRange: azimuthalIntegration.azimuthalRange,
							azimuthalBinning: azimuthalIntegration.azimuthalBinning,
							numberOfAzimuthalBins: azimuthalIntegration.numberOfAzimuthalBins,
							integration: azimuthalIntegration.integration,
							caking: azimuthalIntegration.azimuthalBinning,
							analysisType: "azimuthalIntegration",
						}

						dispatch({type: ADD_ON_DEMAND_TASK, payload: orderArgs});
					}}
				>
					{pipeline ? "Save Inputs" : "Save and calculate"}
				</button>
				{!pipeline && (
					<button
						className={`cursor-pointer w-11/12 ${true ? "text-white bg-primary-600" : "text-gray-600 bg-primary-700"} font-bold m-4 p-4 rounded-md`}
						style={{ marginLeft: "20px"}}
						onClick={() => {
							dispatch({ type: GET_DOWNLOAD, payload: { type: "azimuthalIntegration" } })
						}}
					>
						Download Data
					</button>
				)}
			</div>
		</>
	);
};

export default AzimuthalIntegrationInput;
