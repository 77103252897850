import { Dispatch } from "redux";
import { AnalysisTypes } from "../../../data/analysis_descriptions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faDrawPolygon, faEye, faEyeSlash, faHandPointer, faRing, faSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import DropDown from "../shared/drop-down";
import { DataCalibrationCalibrationType, DataCalibrationDetectorTypes, DataCalibrationPixelSize, DataCalibrationUnit } from "../../../data/data_calibration";
import { GET_DATA_CALIBRATION_DETECTOR_SIZE, GET_DATA_CALIBRATION_DOWNLOAD_MASK, GET_DATA_CALIBRATION_INTEGRATION, GET_DATA_CALIBRATION_MASKING, GET_DATA_CALIBRATION_META_FILE_INFO, GET_DATA_CALIBRATION_RING_PICKING_COMPILE, REMOVE_DATA_CALIBRATION_DRAWINGS, REMOVE_DATA_CALIBRATION_RING_PICKING, SET_DATA_CALIBRATION_CALIBRANT_TYPE, SET_DATA_CALIBRATION_CUSTOM_DETECTOR, SET_DATA_CALIBRATION_DATA_FILE, SET_DATA_CALIBRATION_DETECTOR_PIXEL_SIZE, SET_DATA_CALIBRATION_DETECTOR_PIXEL_SIZE_X, SET_DATA_CALIBRATION_DETECTOR_PIXEL_SIZE_Y, SET_DATA_CALIBRATION_DETECTOR_SIZE_X, SET_DATA_CALIBRATION_DETECTOR_SIZE_Y, SET_DATA_CALIBRATION_DETECTOR_TYPE, SET_DATA_CALIBRATION_DRAWINGS, SET_DATA_CALIBRATION_DRAWING_ACTIVE_INDEX, SET_DATA_CALIBRATION_DRAWING_MODE, SET_DATA_CALIBRATION_DRAWING_VISIBLE, SET_DATA_CALIBRATION_MASKING_FILE, SET_DATA_CALIBRATION_MASK_ABOVE, SET_DATA_CALIBRATION_MASK_BELOW, SET_DATA_CALIBRATION_META_FILE, SET_DATA_CALIBRATION_NUMBER_OF_DATA_POINTS, SET_DATA_CALIBRATION_POLARIZATION_FACTOR, SET_DATA_CALIBRATION_RADIAL_RANGE_LOWER, SET_DATA_CALIBRATION_RADIAL_RANGE_UPPER, SET_DATA_CALIBRATION_RING_PICKING_RING, SET_DATA_CALIBRATION_RING_PICKING_RING_MODE, SET_DATA_CALIBRATION_UNIT, SET_DATA_CALIBRATION_WAVELENGTH } from "../../../redux/dataCalibration/dataCalibrationActions";
import { useSelector } from "react-redux";
import { selectDataCalibrationAnalysis, selectDataCalibrationCalibrantType, selectDataCalibrationCalibrationFile, selectDataCalibrationCustomDetector, selectDataCalibrationDataFile, selectDataCalibrationDetectorPixelSize, selectDataCalibrationDetectorSize, selectDataCalibrationDetectorType, selectDataCalibrationDrawingActiveIndex, selectDataCalibrationDrawingMode, selectDataCalibrationDrawings, selectDataCalibrationMask, selectDataCalibrationMaskingFile, selectDataCalibrationMetaFile, selectDataCalibrationNumberOfDataPoints, selectDataCalibrationPolarizationFactor, selectDataCalibrationRadialRange, selectDataCalibrationRingPicking, selectDataCalibrationRingPickingMode, selectDataCalibrationRingPickingRing, selectDataCalibrationUnit, selectDataCalibrationWavelength } from "../../../redux/dataCalibration/dataCalibrationSelectors";
import { GET_DOWNLOAD } from "../../../redux/project/projectActions";
import { useEffect } from "react";
import { selectCurrentProjectFiles } from "../../../redux/project/projectSelectors";
import { DropDownOptions } from "../../../data/models";
import { getLabelForValue } from "../../../helpers/redux";
import { colorsGraph } from "../../../data/colors";
import { FileSelection } from "../shared/file-selection/file-selection";

const displayOrder = [
  { key: 'Wavelength', displayName: 'Wavelength' },
  { key: 'Distance', displayName: 'Distance' },
  { key: 'Poni1', displayName: 'PONI1' },
  { key: 'Poni2', displayName: 'PONI2' },
  { key: 'Rot1', displayName: 'Rotation 1' },
  { key: 'Rot2', displayName: 'Rotation 2' },
  { key: 'Rot3', displayName: 'Rotation 3' },
];

const DataCalibrationInput = ({ dispatch, setPerformedDelete, performedDelete }: { dispatch: Dispatch, setPerformedDelete, performedDelete }) => {
	const detectorType = useSelector(selectDataCalibrationDetectorType)
	const detectorSize = useSelector(selectDataCalibrationDetectorSize)
	const detectorPixelSize = useSelector(selectDataCalibrationDetectorPixelSize)
	const detectorCalibrantType = useSelector(selectDataCalibrationCalibrantType)
	const wavelength = useSelector(selectDataCalibrationWavelength)
	const masking = useSelector(selectDataCalibrationMask);
	const drawingMode = useSelector(selectDataCalibrationDrawingMode);
	const drawings = useSelector(selectDataCalibrationDrawings);
	const drawingActiveIndex = useSelector(selectDataCalibrationDrawingActiveIndex);
	const ringPickingRing = useSelector(selectDataCalibrationRingPickingRing);
	const polarizationFactor = useSelector(selectDataCalibrationPolarizationFactor);
	const numberOfDataPoints = useSelector(selectDataCalibrationNumberOfDataPoints);
	const unit = useSelector(selectDataCalibrationUnit);
	const radialRange = useSelector(selectDataCalibrationRadialRange);
	const ringPickingMode = useSelector(selectDataCalibrationRingPickingMode);
	const ringPicking = useSelector(selectDataCalibrationRingPicking)
	const projectFiles = useSelector(selectCurrentProjectFiles);
	const calibrationFile = useSelector(selectDataCalibrationCalibrationFile);
	const dataFile = useSelector(selectDataCalibrationDataFile);
	const customDetectorType = useSelector(selectDataCalibrationCustomDetector);
	const maskingFile = useSelector(selectDataCalibrationMaskingFile);
	const analysis = useSelector(selectDataCalibrationAnalysis);
	const metaFile = useSelector(selectDataCalibrationMetaFile);

	const analysisModel = AnalysisTypes.find((x) => x.lambda_name === "data_calibration");

	useEffect(() => {
		dispatch({ type: GET_DATA_CALIBRATION_META_FILE_INFO })
	}, [dispatch, projectFiles])

	useEffect(() => {
		dispatch({ type: GET_DATA_CALIBRATION_DETECTOR_SIZE })
	}, [dispatch, dataFile])
	
	useEffect(() => {
		if (!detectorType) return

		const detectorPixelSizeT = DataCalibrationPixelSize[detectorType]
		dispatch({ type: SET_DATA_CALIBRATION_DETECTOR_PIXEL_SIZE, payload: detectorPixelSizeT})
	}, [detectorType, dispatch])

	const dropDownRingPicking: DropDownOptions = {
    id: "ringPicking",
    label: "Ring Picking",
    options: [{
        id: "ringPicking",
        label: "Ring Picking",
        values: []
    }]
  };

  for (let i = 1; i <= 100; i++) {
    dropDownRingPicking.options[0].values.push({
      value: i,
      label: i.toString()
    });
  }

	const saveToPoniFile = () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toDateString() + " " + currentDate.toTimeString().split(" ")[0];
    let content = `# Calibration File Generated by Scatterin done at ${formattedDate}\n`;

    Object.entries(calibrationFile).forEach(([key, value]) => {
			content += `${key}: ${typeof value === 'object' ? JSON.stringify(value) : value}\n`;
    });

    const blob = new Blob([content], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'calibrationValues.poni';
    link.click();

    URL.revokeObjectURL(url);
  };

	const handleAutomaticBackgroundChange = () => {
		dispatch({ type: SET_DATA_CALIBRATION_CUSTOM_DETECTOR, payload: !customDetectorType });
	};

	return (
		<>
		<div className={"flex flex-col flex-1 pt-2 bg-primary-800 rounded-md"}>
			<h1 className={"text-white font-bold mb-2 pl-2"}>{analysisModel.header}</h1>
			<div className="px-2">
				<div className={"p-2 flex flex-col bg-primary-600 mb-2 rounded-md w-full mt-7"}>
					<h1 className={"font-bold text-white text-base mb-2"}>
						Select Files
					</h1>
					<FileSelection
						dispatch={dispatch}
						selected={dataFile}
						setSelected={files => dispatch({ type: SET_DATA_CALIBRATION_DATA_FILE, payload: files })}
						title="Select Detector Image"
						extensions={[".tif", ".cbf"]}
						multiple={false}
					/>
					<br />
					<FileSelection
						dispatch={dispatch}
						selected={metaFile}
						setSelected={files => dispatch({ type: SET_DATA_CALIBRATION_META_FILE, payload: files })}
						title="Select Meta File"
						extensions={[".meta"]}
						defaultExtension={".meta"}
						multiple={false}
					/>
					<br />
					<FileSelection
						dispatch={dispatch}
						selected={maskingFile}
						setSelected={files => dispatch({ type: SET_DATA_CALIBRATION_MASKING_FILE, payload: files })}
						title="Select Mask File"
						extensions={[".edf", ".h5", ".tif", ".npy"]}
						multiple={false}
					/>
					<br />
				</div>
				<div className={"p-2 flex flex-col bg-primary-600 mb-2 rounded-md w-full"}>
					<h1 className={"font-bold text-white text-base mb-2 mt-5"}>
						Detector Type
					</h1>
					<div className={"flex flex-row items-center justify-center mb-4 mt-2"}>
						<div className="flex items-center justify-start w-3/4">
							<input
								type="checkbox"
								id="checkbox"
								className="w-4 h-4 bg-white border border-white-300 rounded mr-2"
								onChange={handleAutomaticBackgroundChange}
								checked={customDetectorType}
							/>
							<label htmlFor="checkbox" className="flex items-center cursor-pointer text-white">
								<span>Custom Detector Type</span>
							</label>
						</div>
					</div>
					{!customDetectorType && (
						<div className={"flex items-center flex-col mb-2 mt-3 text-center"}>
							<DropDown
								DropDownOptions={DataCalibrationDetectorTypes}
								setSelected={(item) => dispatch({ type: SET_DATA_CALIBRATION_DETECTOR_TYPE, payload: item })}
								selected={detectorType}
								multiple={false}
								title={detectorType ? detectorType : "Detector Type"}
								height={"300px"}
								searchEnb={true}
							/>
						</div>
					)}
					<h1 className={"font-bold text-white text-base mb-2 mt-2"}>
						Detector Size
					</h1>
					<div className={"flex flex-row items-center justify-center"}>
						<div className={"flex flex-col items-center justify-center w-1/2 ml-5 mr-5"}>
							<label className={"text-white"}>
								X Direction
							</label>
							<div className="flex flex-row">
								<input
									className={`${false ? "border-red-500" : "border-gray-500"}
										bg-primary-700 p-2 rounded-md border border-solid text-white w-full`}
									type={"number"}
									step="0.1"
									value={detectorSize.x !== undefined ? detectorSize.x.toString() : ""}
									onChange={(event) => {dispatch({type: SET_DATA_CALIBRATION_DETECTOR_SIZE_X, payload: parseFloat(event.target.value)})}}
								/>
							</div>
						</div>
						<div className={"flex flex-col items-center justify-center w-1/2 ml-5 mr-5"}>
							<label className={"text-white"}>
								Y Direction
							</label>
							<div className="flex flex-row">
								<input
									className={`${false ? "border-red-500" : "border-gray-500"}
										bg-primary-700 p-2 rounded-md border border-solid text-white w-full`}
									type={"number"}
									step="0.1"
									value={detectorSize.y !== undefined ? detectorSize.y.toString() : ""}
									onChange={(event) => {dispatch({type: SET_DATA_CALIBRATION_DETECTOR_SIZE_Y, payload: parseFloat(event.target.value)}); }}
								/>
							</div>
						</div>
					</div>
					<h1 className={"font-bold text-white text-base mb-2 mt-2"}>
						Pixel Size [µm]
					</h1>
					<div className={"flex flex-row items-center justify-center"}>
						<div className={"flex flex-col items-center justify-center w-1/2 ml-5 mr-5"}>
							<label className={"text-white"}>
								X Direction
							</label>
							<div className="flex flex-row">
								<input
									className={`${false ? "border-red-500" : "border-gray-500"}
										bg-primary-700 p-2 rounded-md border border-solid text-white w-full`}
									type={"number"}
									step="0.1"
									value={detectorPixelSize.x !== undefined ? detectorPixelSize.x.toString() : ""}
									onChange={(event) => {dispatch({type: SET_DATA_CALIBRATION_DETECTOR_PIXEL_SIZE_X, payload: parseFloat(event.target.value)})}}
								/>
							</div>
						</div>
						<div className={"flex flex-col items-center justify-center w-1/2 ml-5 mr-5"}>
							<label className={"text-white"}>
								Y Direction
							</label>
							<div className="flex flex-row">
								<input
									className={`${false ? "border-red-500" : "border-gray-500"}
										bg-primary-700 p-2 rounded-md border border-solid text-white w-full`}
									type={"number"}
									step="0.1"
									value={detectorPixelSize.y !== undefined ? detectorPixelSize.y.toString() : ""}
									onChange={(event) => {dispatch({type: SET_DATA_CALIBRATION_DETECTOR_PIXEL_SIZE_Y, payload: parseFloat(event.target.value)}); }}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className={"p-2 flex flex-col bg-primary-600 mb-2 rounded-md w-full"}>
					<h1 className={"font-bold text-white text-base mb-2 mt-2"}>
						Detector Calibrant Type
					</h1>
					<div className={"flex items-center flex-col mb-2 mt-3 text-center"}>
						<DropDown
							DropDownOptions={DataCalibrationCalibrationType}
							setSelected={(item) => dispatch({ type: SET_DATA_CALIBRATION_CALIBRANT_TYPE, payload: item })}
							selected={detectorCalibrantType}
							multiple={false}
							title={detectorCalibrantType ? detectorCalibrantType : "Calibrant Type"}
							height={"300px"}
							searchEnb={true}
						/>
						<div className={"flex flex-col items-center justify-center w-3/6 mt-4 "}>
							<label className={"text-white"}>
								Enter Energy keV
							</label>
							<div className="flex flex-row w-full">
								<input
									className={`${false ? "border-red-500" : "border-gray-500"}
										bg-primary-700 p-2 rounded-md border border-solid text-white w-full`}
									type={"number"}
									step="0.1"
									value={wavelength !== undefined ? wavelength.toString() : ""}
									onChange={(event) => {dispatch({type: SET_DATA_CALIBRATION_WAVELENGTH, payload: parseFloat(event.target.value)}); }}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className={"p-2 flex flex-col bg-primary-600 mb-2 rounded-md w-full mt-2"}>
					<h1 className={"font-bold text-white text-base mb-2 mt-4"}>
						Masking
					</h1>
					<div className={"flex flex-row items-center justify-center"}>
						<div className={"flex flex-col items-center justify-center w-1/2 ml-5 mr-5"}>
							<label className={"text-white"}>
								Mask Below
							</label>
							<div className="flex flex-row">
								<input
									className={`${false ? "border-red-500" : "border-gray-500"}
										bg-primary-700 p-2 rounded-md border border-solid text-white w-full`}
									type={"number"}
									step="0.1"
									value={masking.below !== undefined ? masking.below.toString() : ""}
									onChange={(event) => {dispatch({type: SET_DATA_CALIBRATION_MASK_BELOW, payload: parseFloat(event.target.value)})}}
								/>
							</div>
						</div>
						<div className={"flex flex-col items-center justify-center w-1/2 ml-5 mr-5"}>
							<label className={"text-white"}>
								Mask Above
							</label>
							<div className="flex flex-row">
								<input
									className={`${false ? "border-red-500" : "border-gray-500"}
										bg-primary-700 p-2 rounded-md border border-solid text-white w-full`}
									type={"number"}
									step="0.1"
									value={masking.above !== undefined ? masking.above.toString() : ""}
									onChange={(event) => {dispatch({type: SET_DATA_CALIBRATION_MASK_ABOVE, payload: parseFloat(event.target.value)}); }}
								/>
							</div>
						</div>
					</div>
					<h1 className={"font-bold text-white text-base mb-2 mt-4"}>
						Detector Masking
					</h1>
					<div className={"flex flex-row justify-between py-2"}>
						<div className="flex flex-row">
							<FontAwesomeIcon 
								icon={faHandPointer}
								className={`text-2xl mr-2 p-2 bg-gray-400 border-2 border-gray-500 rounded-md cursor-pointer ${drawingMode === 'freeHand' ? 'bg-blue-500 text-white' : ''}`}
								onClick={() => {
									dispatch({type: SET_DATA_CALIBRATION_DRAWING_MODE, payload: "freeHand"})
									dispatch({type: SET_DATA_CALIBRATION_DRAWINGS, payload: "freeHand"})
								}}
							/>
							<FontAwesomeIcon 
								icon={faCircle}
								className={`text-2xl mr-2 p-2 bg-gray-400 border-2 border-gray-500 rounded-md cursor-pointer ${drawingMode === 'ellipse' ? 'bg-blue-500 text-white' : ''}`}
								onClick={() => {
									dispatch({type: SET_DATA_CALIBRATION_DRAWING_MODE, payload: "ellipse"})
									dispatch({type: SET_DATA_CALIBRATION_DRAWINGS, payload: "ellipse"})
								}}
							/>
							<FontAwesomeIcon 
								icon={faSquare}
								className={`text-2xl mr-2 p-2 bg-gray-400 border-2 border-gray-500 rounded-md cursor-pointer ${drawingMode === 'rectangle' ? 'bg-blue-500 text-white' : ''}`}
								onClick={() => {
									dispatch({type: SET_DATA_CALIBRATION_DRAWING_MODE, payload: "rectangle"})
									dispatch({type: SET_DATA_CALIBRATION_DRAWINGS, payload: "rectangle"})
								}}
							/>
							<FontAwesomeIcon 
								icon={faDrawPolygon}
								className={`text-2xl mr-2 p-2 bg-gray-400 border-2 border-gray-500 rounded-md cursor-pointer ${drawingMode === 'polygon' ? 'bg-blue-500 text-white' : ''}`}
								onClick={() => {
									dispatch({type: SET_DATA_CALIBRATION_DRAWING_MODE, payload: "polygon"})
									dispatch({type: SET_DATA_CALIBRATION_DRAWINGS, payload: "polygon"})
								}}
							/>
						</div>
						
						<div className="flex flex-row-reverse space-x-reverse space-x-2">
							{Object.keys(drawings).length > 0 && drawingMode && (
								<button
									className={` text-white font-bold py-2 px-4 rounded ${true ? 'bg-red-500 hover:bg-red-700' : 'bg-green-500 hover:bg-green-700'}`}
									onClick={() => {
										dispatch({ type: SET_DATA_CALIBRATION_DRAWING_MODE, payload: undefined })
										dispatch({ type: SET_DATA_CALIBRATION_DRAWING_ACTIVE_INDEX, payload: undefined })
									}}
								>
									{/* {drawingMode !== "none" ? 'Stop Drawing' : 'Start Drawing'} */}
									Stop Drawing
								</button>
							)}
							{/* <button
								className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
							>
								New Drawing
							</button> */}
						</div>
					</div>
					<div className="flex flex-col space-y-1 overflow-y-auto h-40 bg-primary-400 rounded-md">
						{Object.entries(drawings).map(([key, value]) => (
							<div key={key} className="flex justify-between items-center px-2 pt-1 bg-primary-400">
								<span
									className={`${drawingActiveIndex === parseInt(key) ? 'font-bold' : 'cursor-pointer hover:font-bold'}`}
									style={{ color: colorsGraph[parseInt(key) % colorsGraph.length] }}
									onClick={() => {
										dispatch({ type: SET_DATA_CALIBRATION_DRAWING_ACTIVE_INDEX, payload: parseInt(key) });
										dispatch({ type: SET_DATA_CALIBRATION_DRAWING_MODE, payload: value.type });
									}}
								>
									{parseInt(key) + 1}: {value.type}
								</span>
								
								<div className="flex space-x-2"> 
									<FontAwesomeIcon icon={value.visible ? faEye : faEyeSlash} className="cursor-pointer mr-2"
										onClick={() => {
											dispatch({ type: SET_DATA_CALIBRATION_DRAWING_VISIBLE, payload: { index: parseInt(key), visible: !value.visible } });
										}}
									/>
									<FontAwesomeIcon icon={faTrash} className="cursor-pointer" color="red"
										onClick={() => {
											dispatch({ type: REMOVE_DATA_CALIBRATION_DRAWINGS, payload: parseInt(key) });
											dispatch({ type: SET_DATA_CALIBRATION_DRAWING_ACTIVE_INDEX, payload: undefined });
											dispatch({ type: SET_DATA_CALIBRATION_DRAWING_MODE, payload: undefined });
											setPerformedDelete(!performedDelete);
										}}
									/>
								</div>
							</div>
						))}
					</div>
					<div className="flex flex-row w-full items-center justify-center space-x-4">
						<button
							className={"cursor-pointer w-6/12 text-white font-bold bg-primary-500 p-4 rounded mt-3 mb-2"}
							onClick={() => {
								dispatch({ type: GET_DATA_CALIBRATION_MASKING })
							}}
						>
							Save Mask
						</button>
						<button
							className={`w-6/12 text-white cursor-pointer bg-primary-500 font-bold p-4 rounded mt-3 mb-2`}
							onClick={() => {
								dispatch({ type: GET_DATA_CALIBRATION_DOWNLOAD_MASK })
							}}
						>
							Download Mask File
						</button>
					</div>
				</div>
				<div className={"p-2 flex flex-col bg-primary-600 mb-2 rounded-md w-full mt-2"}>
					<h1 className={"font-bold text-white text-base mb-2"}>
						Ring Picking
					</h1>
					{/* <div className={"flex flex-col items-center justify-center mt-4 "}>
						<label className={"text-white"}>
							Enter Peak Threshold
						</label>
						<div className="flex flex-row w-3/6">
							<input
								className={`${false ? "border-red-500" : "border-gray-500"}
									bg-primary-700 p-2 rounded-md border border-solid text-white w-full`}
								type={"number"}
								step="0.1"
								value={threshold !== undefined ? threshold.toString() : ""}
								onChange={(event) => {dispatch({type: SET_DATA_CALIBRATION_THRESHOLD_MASK, payload: parseFloat(event.target.value)}); }}
							/>
						</div>
					</div> */}
					<div className={"flex flex-row justify-between py-2 mt-2"}>
						<div className="flex flex-row">
							<FontAwesomeIcon 
								icon={faHandPointer}
								className={`text-2xl mr-2 p-2 bg-gray-400 border-2 border-gray-500 rounded-md cursor-pointer ${ringPickingMode === 'single' ? 'bg-blue-500 text-white' : ''}`}
								onClick={() => {
									dispatch({type: SET_DATA_CALIBRATION_RING_PICKING_RING_MODE, payload: "single"})
								}}
							/>
							<FontAwesomeIcon 
								icon={faRing}
								className={`text-2xl mr-2 p-2 bg-gray-400 border-2 border-gray-500 rounded-md cursor-pointer ${ringPickingMode === 'group' ? 'bg-blue-500 text-white' : ''}`} 
								onClick={() => {
									dispatch({type: SET_DATA_CALIBRATION_RING_PICKING_RING_MODE, payload: "group"})
								}}
							/>
						</div>
						
						<div className="flex flex-row-reverse space-x-reverse space-x-2">
							<div className={"flex items-center flex-col text-center"}
								style={{ marginTop: "3px" }}
							>
								<DropDown
									DropDownOptions={dropDownRingPicking}
									setSelected={(item) => dispatch({ type: SET_DATA_CALIBRATION_RING_PICKING_RING, payload: item })}
									selected={ringPickingRing}
									multiple={false}
									title={ringPickingRing ? `Ring: ${ringPickingRing.toString()}` : "Select Ring"}
									height={"200px"}
									width="w-40"
									padding="p-2"
									displayHeaders={false}
								/>
							</div>
							{ringPickingMode && (
								<button
									className={` text-white font-bold py-1 px-4 rounded ${true ? 'bg-red-500 hover:bg-red-700' : 'bg-green-500 hover:bg-green-700'}`}
									onClick={() => {
										dispatch({ type: SET_DATA_CALIBRATION_RING_PICKING_RING_MODE, payload: undefined })
									}}
								>
									Stop Picking
								</button>
							)}
						</div>
					</div>
					<div className="overflow-y-auto h-60 bg-primary-400 rounded-md">
						<table className="min-w-full bg-primary-400">
							<thead>
								<tr className="text-left">
									<th className="px-2 pt-1 font-bold">Name</th>
									<th className="px-2 pt-1 font-bold text-center">Ring</th>
									<th className="px-2 pt-1 font-bold text-center">Number Of Peaks</th>
									<th className="px-2 pt-1 font-bold text-center">Action</th>
								</tr>
							</thead>
							<tbody>
								{Object.entries(ringPicking).map(([key, value]) => (
									<tr key={key}>
										<td className="px-2 pt-1 cursor-pointer hover:font-bold" 
											style={{ color: colorsGraph[parseInt(key) % colorsGraph.length] }}>
											{parseInt(key) + 1}: {value.type}
										</td>
										<td className="px-2 pt-1 text-center">{value.ring}</td>
										<td className="px-2 pt-1 text-center">{value.numberOfPeaks ? value.numberOfPeaks === 9999 ? "No Peaks Found" : value.numberOfPeaks : "Loading..."}</td>
										<td className="px-2 pt-1 text-center">
											<FontAwesomeIcon icon={faTrash} className="cursor-pointer" color="red"
												onClick={() => {
													dispatch({ type: REMOVE_DATA_CALIBRATION_RING_PICKING, payload: parseInt(key) });
													dispatch({ type: SET_DATA_CALIBRATION_RING_PICKING_RING_MODE, payload: undefined });
													setPerformedDelete(!performedDelete);
												}}
											/>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
					<div className="flex flex-row w-full items-center justify-center space-x-4">
						<button
							className={"cursor-pointer w-6/12 text-white font-bold bg-primary-500 p-4 rounded mt-3 mb-2"}
							onClick={() => {
								dispatch({ type: GET_DATA_CALIBRATION_RING_PICKING_COMPILE })
							}}
						>
							Fit Geometry
						</button>
						<button
							className={`w-6/12 ${Object.keys(calibrationFile).length > 0 ? 'text-white cursor-pointer bg-primary-500' : 'text-gray-600 cursor-not-allowed bg-primary-700'} font-bold p-4 rounded mt-3 mb-2`}
							onClick={saveToPoniFile}
						>
							Download Calibration File
						</button>
					</div>
					{Object.keys(calibrationFile).length > 0 && (
						<div className="flex flex-col space-y-4 p-5 text-white">
							{displayOrder.map(({ key, displayName }) => {
								const value = calibrationFile[key];
								return typeof value !== 'undefined' ? (
									<div key={key} className="flex justify-between items-center">
										<span className="font-semibold">{displayName}</span>
										<span>{typeof value === 'object' ? JSON.stringify(value) : value}</span>
									</div>
								) : null;
							})}
						</div>
					)}
				</div>
				<div className={"p-2 flex flex-col bg-primary-600 mb-2 rounded-md w-full mt-2"}>
					<h1 className={"font-bold text-white text-base mb-4"}>
						Graphing
					</h1>
					<div className={"flex flex-row items-center justify-center"}>
						<div className={"flex flex-col items-center justify-center w-1/2 ml-5 mr-5"}>
							<label className={"text-white"}>
								Number of Data Points
							</label>
							<div className="flex flex-row">
								<input
									className={`${false ? "border-red-500" : "border-gray-500"}
										bg-primary-700 p-2 rounded-md border border-solid text-white w-full`}
									type={"number"}
									step="10"
									value={numberOfDataPoints !== undefined ? numberOfDataPoints.toString() : ""}
									onChange={(event) => {dispatch({type: SET_DATA_CALIBRATION_NUMBER_OF_DATA_POINTS, payload: parseFloat(event.target.value)})}}
								/>
							</div>
						</div>
						<div className={"flex flex-col items-center justify-center w-1/2 ml-5 mr-5"}>
							<label className={"text-white"}>
								Polarization Factor
							</label>
							<div className="flex flex-row">
								<input
									className={`${false ? "border-red-500" : "border-gray-500"}
										bg-primary-700 p-2 rounded-md border border-solid text-white w-full`}
									type={"number"}
									step="0.1"
									value={polarizationFactor !== undefined ? polarizationFactor.toString() : ""}
									onChange={(event) => {dispatch({type: SET_DATA_CALIBRATION_POLARIZATION_FACTOR, payload: parseFloat(event.target.value)}); }}
								/>
							</div>
						</div>
					</div>
					<div className={"flex items-center flex-col text-center mt-5"}>
						<DropDown
							DropDownOptions={DataCalibrationUnit}
							setSelected={(item) => dispatch({ type: SET_DATA_CALIBRATION_UNIT, payload: item })}
							selected={unit}
							multiple={false}
							title={unit ? `Select Radial (x-axis) Unit: ${getLabelForValue(unit, DataCalibrationUnit)}` : "Select Radial (x-axis) Unit"}
							height={"200px"}
							padding="p-3"
							displayHeaders={false}
						/>
					</div>
					<h1 className={"font-bold text-white text-base mb-1 mt-8"}>
						Radial (x-axis) Range
					</h1>
					<div className={"flex flex-row items-center justify-center"}>
						<div className={"flex flex-col items-center justify-center w-1/2 ml-5 mr-5"}>
							<label className={"text-white"}>
								Lower
							</label>
							<div className="flex flex-row">
								<input
									className={`${false ? "border-red-500" : "border-gray-500"}
										bg-primary-700 p-2 rounded-md border border-solid text-white w-full`}
									type={"number"}
									step="0.1"
									value={radialRange.lower !== undefined ? radialRange.lower.toString() : ""}
									onChange={(event) => {dispatch({type: SET_DATA_CALIBRATION_RADIAL_RANGE_LOWER, payload: parseFloat(event.target.value)})}}
								/>
							</div>
						</div>
						<div className={"flex flex-col items-center justify-center w-1/2 ml-5 mr-5"}>
							<label className={"text-white"}>
								Upper
							</label>
							<div className="flex flex-row">
								<input
									className={`${false ? "border-red-500" : "border-gray-500"}
										bg-primary-700 p-2 rounded-md border border-solid text-white w-full`}
									type={"number"}
									step="0.1"
									value={radialRange.upper !== undefined ? radialRange.upper.toString() : ""}
									onChange={(event) => {dispatch({type: SET_DATA_CALIBRATION_RADIAL_RANGE_UPPER, payload: parseFloat(event.target.value)}); }}
								/>
							</div>
						</div>
					</div>
					<div className="flex items-center justify-center flex-row">
						<button
							className={"cursor-pointer w-full text-white font-bold bg-primary-500 p-4 rounded mt-8 mb-2 mx-4"}
							onClick={() => {
								dispatch({ type: GET_DATA_CALIBRATION_INTEGRATION })
							}}
						>
							Generate Integration
						</button>
					</div>
					<button
						className={`cursor-pointer ${analysis?.lines?.length ?  "text-white bg-primary-500" : "text-gray-600 bg-primary-700"} font-bold m-4 p-4 rounded-md`}
						onClick={() => {
							dispatch({ type: GET_DOWNLOAD, payload: { type: "dataCalibration" } })
						}}
					>
						Download Data
					</button>
				</div>
			</div>
		</div>
	</>
	);
};

export default DataCalibrationInput;
