import { useEffect, useRef, useState } from "react";
import { Dispatch } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import PhaseQuantificationAnalysis from "../phase-quantification/phase-quantification";
import AzimuthalIntegrationAnalysis from "../azimuthal-integration/azimuthal-integration";
import PipelineStatus from "./status";
import PipelineReport from "./report";
import { GET_LAMBDA_CACHE, GET_METHOD_DATA, GET_PIPELINE_FILES, GET_PIPELINE_STATUS } from "../../../redux/project/projectActions";
import { selectPhaseQuantification } from "../../../redux/phaseQuantification/phaseQuantificationSelectors";
import { useSelector } from "react-redux";
import { GET_PHASE_QUANTIFICATION_CALLBACK_FITTED, GET_PHASE_QUANTIFICATION_CALLBACK_PARAMETERS } from "../../../redux/phaseQuantification/phaseQuantificationActions";

const pipelineConfigurations = {
  "azimuthalIntegration-phaseQuantification": ["status", "azimuthal_integration", "phase_quantification", "report"],
  "azimuthalIntegration-stressAnalysis": ["status", "azimuthal_integration", "report"],
};

const Pipeline = ({ dispatch, pipelineType }: { dispatch: Dispatch; pipelineType: string }) => {
  const phaseQuantification = useSelector(selectPhaseQuantification);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState("left");
  const [pipelineStatusInterval, setPipelineStatusInterval] = useState<NodeJS.Timeout | undefined>(undefined);
  const [pipelineProgressInterval, setPipelineProgressInterval] = useState<NodeJS.Timeout | undefined>(undefined);
  const [phaseQuantificationProgressInterval, setPhaseQuantificationProgressInterval] = useState<NodeJS.Timeout | undefined>(undefined);

  const nodeRef = useRef(null);

  const components = pipelineConfigurations[pipelineType];

  const getCurrentComponent = () => {
    switch (components[currentIndex]) {
      case "phase_quantification":
        return <PhaseQuantificationAnalysis dispatch={dispatch} pipeline={true} />;
      case "azimuthal_integration":
        return <AzimuthalIntegrationAnalysis dispatch={dispatch} pipeline={true} />;
      case "status":
        return <PipelineStatus dispatch={dispatch} pipelineType={pipelineType} />;
      case "report":
        return <PipelineReport dispatch={dispatch} />;
      default:
        return <></>;
    }
  };

  useEffect(() => {
		if (pipelineStatusInterval !== undefined) clearInterval(pipelineStatusInterval);

    dispatch({ type: GET_PIPELINE_STATUS, payload: { pipelineType} });

		const newInterval = setInterval(async () => {
			dispatch({ type: GET_PIPELINE_STATUS, payload: { pipelineType} });
		}, 15000);

		setPipelineStatusInterval(newInterval);

    return () => {
      clearInterval(newInterval);
    };
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

  useEffect(() => {
		if (pipelineProgressInterval !== undefined) clearInterval(pipelineProgressInterval);

    dispatch({ type: GET_PIPELINE_FILES, payload: { pipelineType } });

		const newInterval = setInterval(async () => {
			dispatch({ type: GET_PIPELINE_FILES, payload: { pipelineType } });
		}, 15000);

		setPipelineProgressInterval(newInterval);

    return () => {
      clearInterval(newInterval);
    };
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

  useEffect(() => {
		if (phaseQuantificationProgressInterval !== undefined) clearInterval(phaseQuantificationProgressInterval);

		const newInterval = setInterval(async () => {
			dispatch({ type: GET_PHASE_QUANTIFICATION_CALLBACK_PARAMETERS });
		}, 15000);

		setPhaseQuantificationProgressInterval(newInterval);

    return () => {
      clearInterval(newInterval);
    };
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

  useEffect(() => {
	  dispatch({ type: GET_METHOD_DATA, payload: {type: "azimuthalIntegration"} });
    dispatch({ type: GET_METHOD_DATA, payload: {type: "phaseQuantification"} })
    dispatch({ type: GET_LAMBDA_CACHE, payload: {callerType: "phaseQuantification"} })
    dispatch({ type: GET_LAMBDA_CACHE, payload: {callerType: "azimuthalIntegration"} })
	}, [dispatch]);

  useEffect(() => {
    if (!phaseQuantification.dataViewFile) return;

    dispatch({ type: GET_PHASE_QUANTIFICATION_CALLBACK_FITTED });
  }, [dispatch, phaseQuantification.dataViewFile])

  const handleLeftClick = () => {
    if (currentIndex === 1 || currentIndex === 0) {
      setDirection("left");
    } else {
      setDirection("right");
    }
    if (currentIndex !== 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
      
    }
  };

  const handleRightClick = () => {
    if (currentIndex === components.length - 1) {
      setDirection("right");
    } else {
      setDirection("left");
    }
    if (currentIndex !== components.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  return (
    <div className={"flex w-full gap-4 flex-1 xl:flex-col flex-grow relative overflow-hidden"}>
      <div
        className="absolute top-0 left-0 p-4 cursor-pointer transform transition-transform active:scale-95 z-10 bg-primary-500 hover:bg-primary-700 rounded-r-full flex items-center justify-center"
        onClick={handleLeftClick}
      >
        <FontAwesomeIcon icon={faArrowLeft} size="2x" className="text-white" />
      </div>
      <div
        className="absolute top-0 right-0 p-4 cursor-pointer transform transition-transform active:scale-95 hover:bg-primary-700 z-10 bg-primary-500 rounded-l-full flex items-center justify-center"
        onClick={handleRightClick}
      >
        <FontAwesomeIcon icon={faArrowRight} size="2x" className="text-white" />
      </div>
      <TransitionGroup className="flex-1">
        <CSSTransition
          nodeRef={nodeRef}
          key={currentIndex}
          timeout={300}
          classNames={direction === "right" ? "slide-right" : "slide-left"}
        >
          <div ref={nodeRef} className="flex-1" style={{ height: "100%" }}>{getCurrentComponent()}</div>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default Pipeline;