import { faArrowLeft, faCheckSquare, faChevronDown, faFile, faFolder, faSquare, faStar, faToolbox } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch } from "redux";
import { useRef, useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectBrowsingProject, selectCurrentProject, selectCurrentProjectFiles, selectProjectsList } from "../../../../redux/project/projectSelectors";
import { FileSystemS } from "./file-system";
import { selectFileFetchingInProgress, selectReloadFileSystem } from "../../../../redux/general/generalSelectors";
import { FormatName } from "../../../shared/utils";
import { compareKeys } from "../../../../helpers/general";
import { GET_BROWSING_PROJECT } from "../../../../redux/project/projectActions";
import Loader from "react-loader-spinner";

interface DropDownFilesProps {
  dispatch: Dispatch;
  title: string;
  extensions: string[];
  multiple: boolean;
  singleSelected: string[] | string;
  singleSetSelected: (selected: string[] | string) => void
  defaultExtension: string;
  templates: { label: string, value: string, directory: string }[];
  selectedTemplates: string[] | string;
  setSelectedTemplates: (selected: string[] | string) => void;
  multipleFileSelection: {
    multipleTitle: string;
    key: string;
    selected: any[];
    setSelected: (selected: any[]) => void;
  };
}


const DropDownFileSystem = ({ dispatch, title, extensions, multiple, singleSetSelected, singleSelected, defaultExtension, templates, selectedTemplates, setSelectedTemplates, multipleFileSelection }: DropDownFilesProps) => {
  const files = useSelector(selectCurrentProjectFiles)
  const currentProject = useSelector(selectCurrentProject);
  const reloadFileSystem = useSelector(selectReloadFileSystem);
  const projectList = useSelector(selectProjectsList)
  const browsingProject = useSelector(selectBrowsingProject);
  const inInLineLoader = useSelector(selectFileFetchingInProgress);

  const [fileSystem, setFileSystem] = useState<FileSystemS>(new FileSystemS());
  const [fileSystemMultiple, setFileSystemMultiple] = useState<FileSystemS[]>(
    Array.from({ length: multipleFileSelection ? multipleFileSelection.selected.length : 0 }, () => new FileSystemS())
  );
  const [fileSystemOtherProject, setFileSystemOtherProject] = useState<FileSystemS>(new FileSystemS());
  const [fileSystemOtherProjectMultiple, setFileSystemOtherProjectMultiple] = useState<FileSystemS[]>(
    Array.from({ length: multipleFileSelection ? multipleFileSelection.selected.length : 0 }, () => new FileSystemS())
  );
  const [currentPath, setCurrentPath] = useState<string>("/");
  const [dropdownMultipleExpand, setDropdownMultipleExpand] = useState(false);
  const [fileSearch, setFileSearch] = useState("");
  const [browseMode, setBrowseMode] = useState<'files' | 'directories' | 'templates'>('directories');
  const [selectedExtensions, setSelectedExtensions] = useState(defaultExtension ? new Set([defaultExtension]) : new Set());
  const [multipleFileSelectionIndex, setMultipleFileSelectionIndex] = useState(0);
  const [selectedProject, setSelectedProject] = useState("");

  const dropdownMultipleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (dropdownMultipleRef.current && !dropdownMultipleRef.current.contains(event.target as Node)) {
        setDropdownMultipleExpand(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  useEffect(() => {
    setMultipleFileSelectionIndex(0);
  }, [multipleFileSelection?.selected?.length]);

  useEffect(() => {
    let newFileSystem = null;
    let newFileSystemMultiple = [];

    if (multipleFileSelection) {
      newFileSystemMultiple = multipleFileSelection.selected.map(_ => {
        const newFileSystemI = new FileSystemS();

        files.forEach(filePath => {
          const modifiedFileName = filePath.fileRef.replace(`${currentProject.projectId}/`, "");
          newFileSystemI.addFile(modifiedFileName);
        });

        return newFileSystemI;
      });
    } else {
      newFileSystem = new FileSystemS();

      files.forEach(filePath => {
        const modifiedFileName = filePath.fileRef.replace(`${currentProject.projectId}/`, "");
        newFileSystem.addFile(modifiedFileName);
      });
    }

    if (multipleFileSelection) {
      if (Array.isArray(multipleFileSelection.selected[0][multipleFileSelection.key])) {
        Array.from({ length: multipleFileSelection.selected.length }).forEach((_, index) => {
          multipleFileSelection.selected[index][multipleFileSelection.key].forEach(file => {
            if (files.every(f => f.fileRef !== file)) return

            newFileSystemMultiple[index].selectFile(file.replace(`${currentProject.projectId}/`, ""));
          })
        })
      } else {
        Array.from({ length: multipleFileSelection.selected.length }).forEach((_, index) => {
          if (files.every(f => f.fileRef !== multipleFileSelection.selected[index][multipleFileSelection.key])) return

          newFileSystemMultiple[index].selectFile(multipleFileSelection.selected[index][multipleFileSelection.key].replace(`${currentProject.projectId}/`, ""));
        })
      }
    } else {
      if (Array.isArray(singleSelected)) {
        singleSelected.forEach(file => {
          if (files.every(f => f.fileRef !== file)) return
    
          newFileSystem.selectFile(file.replace(`${currentProject.projectId}/`, ""));
        })
      } else {
        if (!files.every(f => f.fileRef !== singleSelected)) {
          newFileSystem.selectFile(singleSelected.replace(`${currentProject.projectId}/`, ""));
        }
      }
    }

    setFileSystem(newFileSystem);
    setFileSystemMultiple(newFileSystemMultiple);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, reloadFileSystem, multipleFileSelection?.selected.length]);

  useEffect(() => {
    if (selectedProject !== browsingProject?.projectId || !browsingProject || !selectedProject) return;
    let newFileSystem = new FileSystemS();
    let newFileSystemMultiple = [];

    if (multipleFileSelection) {
      newFileSystemMultiple = multipleFileSelection.selected.map(_ => {
        const newFileSystemI = new FileSystemS();

        browsingProject.files.forEach(filePath => {
          const modifiedFileName = filePath.fileRef.replace(`${selectedProject}/`, "");
          newFileSystemI.addFile(modifiedFileName);
        });

        return newFileSystemI;
      });
    } else {
      browsingProject.files.forEach(filePath => {
        newFileSystem.addFile(filePath.fileRef.replace(`${selectedProject}/`, ""));
      });
    }
    if (multipleFileSelection) {
      if (Array.isArray(multipleFileSelection.selected[0][multipleFileSelection.key])) {
        Array.from({ length: multipleFileSelection.selected.length }).forEach((_, index) => {
          multipleFileSelection.selected[index][multipleFileSelection.key].forEach(file => {
            if (browsingProject.files.every(f => f.fileRef !== file)) return

            newFileSystemMultiple[index].selectFile(file.replace(`${browsingProject.projectId}/`, ""));
          })
        })
      } else {
        Array.from({ length: multipleFileSelection.selected.length }).forEach((_, index) => {
          if (browsingProject.files.every(f => f.fileRef !== multipleFileSelection.selected[index][multipleFileSelection.key])) return

          newFileSystemMultiple[index].selectFile(multipleFileSelection.selected[index][multipleFileSelection.key].replace(`${browsingProject.projectId}/`, ""));
        })
      }
    } else {
      if (Array.isArray(singleSelected)) {
        singleSelected.forEach(file => {
          if (!browsingProject.files.some(f => f.fileRef === file)) return
          newFileSystem.selectFile(file.replace(`${browsingProject.projectId}/`, ""));
        })
      } else {
        if (browsingProject.files.some(f => f.fileRef === singleSelected)) {
          newFileSystem.selectFile(singleSelected.replace(`${browsingProject.projectId}/`, ""));
        }
      }
    }

    setFileSystemOtherProject(newFileSystem);
    setFileSystemOtherProjectMultiple(newFileSystemMultiple);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject, browsingProject]);

  const currentDirectory = useMemo(() => {
    if (fileSystemMultiple?.length) {
      return fileSystemMultiple[multipleFileSelectionIndex].navigateTo(currentPath);
    } else {
      return fileSystem.navigateTo(currentPath);
    }
  }, [fileSystemMultiple, multipleFileSelectionIndex, currentPath, fileSystem]);

  const currentDirectoryOtherProject = useMemo(() => {
    if (fileSystemOtherProjectMultiple?.length) {
      return fileSystemOtherProjectMultiple[multipleFileSelectionIndex].navigateTo(currentPath);
    } else {
      return fileSystemOtherProject.navigateTo(currentPath);
    }
  }, [currentPath, fileSystemOtherProject, fileSystemOtherProjectMultiple, multipleFileSelectionIndex]);

  const goBack = () => {
    const parts = currentPath.split("/").filter(p => p.length > 0);
    parts.pop();

    if (browseMode === 'templates') {
      setCurrentPath(parts.join("/"));
    } else if (currentPath === "/") {
      setCurrentPath("User Projects")
    } else {
      setCurrentPath("/" + parts.join("/"));
    }
  }

  const toggleExtension = (extension) => {
    setSelectedExtensions(prevSelected => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(extension)) {
        newSelected.delete(extension);
      } else {
        newSelected.add(extension);
      }
      return newSelected;
    });
  };

  const fileClick = (file: string) => {
    const currentProjectId = selectedProject ? browsingProject.projectId : currentProject.projectId;

    if (multipleFileSelection) {
      let fileSystemCurrentMultiple = selectedProject ? fileSystemOtherProjectMultiple : fileSystemMultiple
      fileSystemCurrentMultiple[multipleFileSelectionIndex].selectFile(file);

      const copyPartitions = [...multipleFileSelection.selected];
      let newSelectedFiles = [...multipleFileSelection.selected[multipleFileSelectionIndex][multipleFileSelection.key]];


      const fileKey = `${currentProjectId}/${file}`;

      if (newSelectedFiles.includes(fileKey)) {
        newSelectedFiles = newSelectedFiles.filter(item => item !== fileKey);
      } else {
        newSelectedFiles.push(fileKey);
      }

      if (multiple) {
        copyPartitions[multipleFileSelectionIndex][multipleFileSelection.key] = newSelectedFiles;
      } else {
        copyPartitions[multipleFileSelectionIndex][multipleFileSelection.key] = fileKey;
      }
      multipleFileSelection.setSelected(copyPartitions);
    } else {
      let fileSystemCurrent = selectedProject ? fileSystemOtherProject : fileSystem

      if (!multiple) {
        fileSystemCurrent.selectSingleFile(file);

        singleSetSelected(`${currentProjectId}/${file}`);
      } else {
        fileSystemCurrent.selectFile(file);

        const newSelectedFiles = new Set(singleSelected as string[]);
        if (newSelectedFiles.has(`${currentProjectId}/${file}`)) {
          newSelectedFiles.delete(`${currentProjectId}/${file}`);
        } else {
          newSelectedFiles.add(`${currentProjectId}/${file}`);
        }
        singleSetSelected(Array.from(newSelectedFiles));
      }
    }
  }

  const folderClick = (folder: string) => {
    const currentProjectId = selectedProject ? browsingProject.projectId : currentProject.projectId;
    const folderPath = `${currentProjectId}/${folder}`
    const extensionArray: string[] = Array.from(selectedExtensions as Set<string>);

    const currentFiles = selectedProject ? browsingProject.files : files;

    const filesInFolder = currentFiles.filter(file => {
      return file.fileRef.startsWith(folderPath) && (selectedExtensions.size < 1 || extensionArray.some(ext => file.fileRef.endsWith(ext)));
    });

    if (multipleFileSelection) {
      const fileSystemCurrentMultiple = selectedProject ? fileSystemOtherProjectMultiple : fileSystemMultiple
      const copyPartitions = [...multipleFileSelection.selected]; 
      const newSelectedFiles = [...multipleFileSelection.selected[multipleFileSelectionIndex][multipleFileSelection.key]]

      let allFilesExtension = newSelectedFiles.filter(file => {
        if (extensionArray.length === 0) {
          return true;
        } else {
          return extensionArray.some(ext => file.endsWith(ext));
        }
      });

      const allFilesInFolderSelected = filesInFolder.every(file => allFilesExtension.includes(file.fileRef));
      const filesInFolderModified = filesInFolder.map(file => file.fileRef.replace(`${currentProjectId}/`, ""));

      fileSystemCurrentMultiple[multipleFileSelectionIndex].selectFolder(filesInFolderModified, allFilesInFolderSelected);

      let newSelected = [];

      if (allFilesInFolderSelected) {
        newSelected = newSelectedFiles.filter(file => !file.startsWith(folderPath));
      } else {
        newSelected = [...Array.from(new Set([...newSelectedFiles, ...filesInFolder.map(file => file.fileRef)]))];
      }

      copyPartitions[multipleFileSelectionIndex][multipleFileSelection.key] = newSelected.sort((a, b) => compareKeys(FormatName(a), FormatName(b)))
      multipleFileSelection.setSelected(copyPartitions);
    } else {
      if (!Array.isArray(singleSelected)) return

      let allFilesExtension = singleSelected.filter(file => {
        if (extensionArray.length === 0) {
          return true;
        } else {
          return extensionArray.some(ext => file.endsWith(ext));
        }
      });

      const allFilesInFolderSelected = filesInFolder.every(file => allFilesExtension.includes(file.fileRef));
      const filesInFolderModified = filesInFolder.map(file => file.fileRef.replace(`${currentProjectId}/`, ""));

      let selectedFileSystem = selectedProject ? fileSystemOtherProject : fileSystem

      selectedFileSystem.selectFolder(filesInFolderModified, allFilesInFolderSelected);

      let newSelected = [];

      if (allFilesInFolderSelected) {
        newSelected = singleSelected.filter(file => !file.startsWith(folderPath));
      } else {
        newSelected = [...Array.from(new Set([...singleSelected, ...filesInFolder.map(file => file.fileRef)]))];
      }

      singleSetSelected(newSelected);
    }
  }

  const directoryHasFilesWithExtensions = (directory, selectedExtensions) => {
    const hasFilesWithExtensions = directory.files.some(file => {
      return selectedExtensions.size === 0 || Array.from(selectedExtensions).some(ext => file.path.endsWith(ext));
    });
  
    if (hasFilesWithExtensions) {
      return true;
    }
  
    return directory.subdirectories.some(subDir => directoryHasFilesWithExtensions(subDir, selectedExtensions));
  };
  
  const renderDirectories = () => {
    const directory = selectedProject ? currentDirectoryOtherProject : currentDirectory;
    const currentAllFiles = selectedProject ? browsingProject.files.map(file => file.fileRef.replace(`${browsingProject.projectId}/`, "")) : files.map(file => file.fileRef.replace(`${currentProject.projectId}/`, ""));
  
    return (
      <>
        {directory.subdirectories.filter(dir => dir.name.includes(fileSearch) && directoryHasFilesWithExtensions(dir, selectedExtensions)).map((dir, index) => {
          let isAllSelected = false;
          if (multiple) {
            if (multipleFileSelection) {
              const currentFileSystem = selectedProject ? fileSystemOtherProjectMultiple[multipleFileSelectionIndex] : fileSystemMultiple[multipleFileSelectionIndex];
              isAllSelected = dir.isAllFilesSelected(currentFileSystem.selectedFiles, currentAllFiles, selectedExtensions as Set<string>);
            } else {
              let currentFileSystem = selectedProject ? fileSystemOtherProject : fileSystem;
              isAllSelected = dir.isAllFilesSelected(currentFileSystem.selectedFiles, currentAllFiles, selectedExtensions as Set<string>);
            }
          }
  
          return (
            <div key={index} className={`flex items-center space-x-2 cursor-pointer p-2`}>
              {multiple && (
                <FontAwesomeIcon
                  icon={isAllSelected ? faCheckSquare : faSquare}
                  size="lg"
                  color={isAllSelected ? "blue" : "gray"}
                  onClick={() => folderClick(dir.path)}
                />
              )}
              <div
                className="flex flex-row items-center w-full"
                onClick={() => setCurrentPath(dir.path)}
              >
                <FontAwesomeIcon
                  icon={faFolder}
                  size="lg"
                  color={"rgba(55, 65, 81, 1)"}
                />
                <h4 className="ml-2 text-gray-700">
                  {dir.name}
                </h4>
              </div>
            </div>
          );
        })}
        {directory.files.filter(file => file.name.includes(fileSearch)).map((file, index) => {
          const extensionArray: string[] = Array.from(selectedExtensions as Set<string>);
          if (extensionArray.length > 0 && !extensionArray.some(ext => file.path.endsWith(ext))) return null;
  
          return (
            <div
              key={index}
              className={`flex items-center space-x-2 cursor-pointer p-2`}
              onClick={() => fileClick(file.path)}
            >
              <FontAwesomeIcon
                icon={file.isSelected ? faCheckSquare : faSquare}
                size="lg"
                color={file.isSelected ? "blue" : "gray"}
              />
              <FontAwesomeIcon
                icon={faFile}
                size="lg"
                color={"rgba(55, 65, 81, 1)"}
              />
              <h4 className="text-gray-700">{file.name}</h4>
            </div>
          );
        })}
      </>
    );
  }

  const handleDirectoryClick = (directory: string) => {
    const newPath = currentPath === '' ? `${directory}/` : `${currentPath}${directory}/`;
    setCurrentPath(newPath);
    setFileSearch('');
  };

  const toggleSelectionTemplates = (fileRef) => {
    const selectedSet = new Set(Array.isArray(selectedTemplates) ? selectedTemplates : [selectedTemplates]);

    const handleFileSelection = () => {
      if (!Array.isArray(selectedTemplates)) return

      if (selectedSet.has(fileRef)) {
        setSelectedTemplates(selectedTemplates.filter(item => item !== fileRef));
      } else {
        setSelectedTemplates([...selectedTemplates, fileRef]);
      }
    };

    if (multiple) {
      handleFileSelection();
    } else {
      if (selectedSet.has(fileRef)) {
        setSelectedTemplates("");
      } else {
        setSelectedTemplates(fileRef);
        singleSetSelected("");
      }
    }
  };

  const renderTemplates = () => {
    const renderDiv = {};

    if (currentPath === '') {
      const directories = new Set(templates.map(template => template.directory));
      directories.forEach((directory, index) => {
        if (renderDiv[directory] === undefined) {
          renderDiv[directory] = (
            <div key={index} className={`flex items-center space-x-2 cursor-pointer p-2`}>
              <div onClick={() => handleDirectoryClick(directory)} className="flex flex-row items-center w-full">
                <FontAwesomeIcon icon={faFolder} size="lg" color={"rgba(55, 65, 81, 1)"} />
                <h4 className="ml-2 text-gray-700">{directory}</h4>
              </div>
            </div>
          )
        }
      });
    } else {
      const filesInDirectory = templates.filter(template => template.directory === currentPath.slice(0, -1));
      filesInDirectory.forEach((file, index) => {
        let isSelected = false;
  
        if (Array.isArray(selectedTemplates)) {
          isSelected = selectedTemplates.includes(file.value);
        } else {
          isSelected = selectedTemplates === file.value;
        }
        const icon = isSelected ? faCheckSquare : faSquare;
        const color = isSelected ? "blue" : "gray";

        if (renderDiv[file.label] === undefined) {
          renderDiv[file.label] = (
            <div key={index} className={`flex items-center space-x-2 cursor-pointer p-2`} onClick={() => toggleSelectionTemplates(file.value)} >
              <FontAwesomeIcon icon={icon} size="lg" color={color} />
              <FontAwesomeIcon icon={faFile} color={"rgba(55, 65, 81, 1)"} />
              <h4>{file.label}</h4>
            </div>
          )
        }
      });
    }

    const sortedKeys = Object.keys(renderDiv).sort((a, b) => {
      if (a.includes('.') && !b.includes('.')) {
        return 1;
      } else if (!a.includes('.') && b.includes('.')) {
        return -1;
      } else {
        return a.localeCompare(b);
      }
    });

    return sortedKeys.map(key => renderDiv[key]);
  }

  const renderProjects = () => {
    const handleProjectSelection = (project) => {
      if (currentProject.projectId === project.projectId) {
        setSelectedProject("");
      } else {
        setSelectedProject(project.projectId);
        setFileSystemOtherProject(new FileSystemS());
        dispatch({ type: GET_BROWSING_PROJECT, payload: { projectId: project.projectId }});
      }
      setCurrentPath('/');
    }

    return projectList.map((project, index) => (
      <div key={index} className={`flex items-center space-x-2 cursor-pointer p-2`}>
        <div onClick={() => handleProjectSelection(project)} className="flex flex-row items-center w-full">
          {project.projectId === currentProject.projectId && (
            <FontAwesomeIcon icon={faStar} size="2xs" color={"rgba(55, 65, 81, 1)"} />
          )}
          <h4 className={`${project.projectId === currentProject.projectId ? "ml-2" : "ml-5"} text-gray-700`}>{project.projectName}</h4>
        </div>
      </div>
    ));
  }

  return(
    <div className={`relative w-full`} ref={dropdownMultipleRef}>
      <div>
        <button
          type="button"
          className={`inline-flex items-center w-full justify-center rounded-md border-primary-500 p-4 bg-primary-500 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
          id="options-menu"
          aria-haspopup="true"
          aria-expanded="true"
          onClick={() => setDropdownMultipleExpand(!dropdownMultipleExpand)}
        >
          <h1 className={`text-white text-center flex-grow`}>
            {title}
          </h1>
          <FontAwesomeIcon icon={faChevronDown} className="ml-auto" />
        </button>
      </div>
      {dropdownMultipleExpand && (
        <div className={`origin-top-right absolute left-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10`}
          style={{ maxHeight: "400px", overflowY: "auto" }}
        >
          <div
            className="py-1 relative"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            <div className="absolute top-0 right-0 pt-2 pr-2">
              <button onClick={goBack} className="bg-primary-500 text-white px-4 py-2 rounded-md">
                <FontAwesomeIcon icon={faArrowLeft} color="white" />
              </button>
            </div>
            <div className="p-2 flex justify-between">
              <div className="">
                {templates.length > 0 && (
                  <>
                  <button
                    onClick={() => {
                      setBrowseMode('directories')
                      setCurrentPath('/')
                    }}
                    className={`${browseMode === 'directories' ? 'bg-primary-600' : 'bg-primary-500'} text-white px-4 py-2 rounded-md mr-2 mb-2`}
                  >
                    <FontAwesomeIcon icon={faFolder} color="white" /> Project Files
                  </button>
                  <button
                    onClick={() => {
                      setBrowseMode('templates')
                      setSelectedProject("")
                      setCurrentPath('')
                    }}
                    className={`${browseMode === 'templates' ? 'bg-primary-600' : 'bg-primary-500'} text-white px-4 py-2 rounded-md mr-2 mb-2`}
                  >
                    <FontAwesomeIcon icon={faToolbox} color="white" /> Browse Templates
                  </button>
                  </>
                )}
              </div>
             
            </div>
            {browseMode !== 'templates' && (
              <>
              {multipleFileSelection && (
                <div className="px-4 py-1">
                  <p
                    className="text-xs font-bold text-gray-700 uppercase"
                  >
                    Partitions
                  </p>
                  <div className="flex flex-wrap items-start">
                    {multipleFileSelection.selected.map((_, index) => {
                      const icon = multipleFileSelectionIndex === index ? faCheckSquare : faSquare;
                      const color = multipleFileSelectionIndex === index ? "blue" : "gray";

                      return (
                        <div key={index} className="flex items-center space-x-1 mr-3 cursor-pointer" onClick={() => setMultipleFileSelectionIndex(index)}>
                          <FontAwesomeIcon icon={icon} size="lg" color={color} className="cursor-pointer" style={{ marginBottom: "1px" }}/>
                          <span>{`${multipleFileSelection.multipleTitle} ${index + 1}`}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              <div className="px-4 py-1">
                <p
                  className="text-xs font-bold text-gray-700 uppercase"
                >
                  Extensions
                </p>
                <div className="flex flex-wrap items-start">
                  {extensions.map((extension, index) => {
                    const isSelected = selectedExtensions.has(extension);
                    const icon = isSelected ? faCheckSquare : faSquare;
                    const color = isSelected ? "blue" : "gray";

                    return (
                      <div
                        key={index}
                        className="flex items-center space-x-1 mr-3 cursor-pointer"
                        onClick={() => toggleExtension(extension)}
                      >
                        <FontAwesomeIcon icon={icon} size="lg" color={color} className="cursor-pointer"/>
                        <span>{extension}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="px-2">
                <label htmlFor="search" className="sr-only">
                  Search
                </label>
                <div className="mt-1 relative rounded-md shadow-sm border-gray-500 border">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-white sm:text-sm">🔍</span>
                  </div>
                  <input
                    type="text"
                    id="search"
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm text-gray-600 py-2 border-gray-500 rounded-md"
                    placeholder="Find by name"
                    value={fileSearch}
                    onChange={(event) => setFileSearch(event.target.value)}
                  />
                </div>
              </div>
              </>
            )}
            <div
              className="px-4 py-2 rounded-md"
            >
              Current Path: {currentPath || '/'}
            </div>
            <div className="file-list-container">
              {browseMode === "directories" && currentPath !== "User Projects" && renderDirectories()}
              {browseMode === "directories" && currentPath === "User Projects" && renderProjects()}
              {browseMode === "templates" && currentPath !== "User Projects" && !selectedProject && renderTemplates()}
              {inInLineLoader && (
                <div className="flex justify-center items-center h-64">
                  <Loader type="Oval" color="#68d391" height={50} width={50} />
                </div>
              )}
            </div>
					</div>
				</div>
			)}
		</div>
	);
};

export default DropDownFileSystem;
