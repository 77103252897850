import { Dispatch } from "redux";
import { useSelector } from "react-redux";
import { selectInstanceId, selectProgressMessage, selectProgressTitle } from "../../../redux/general/generalSelectors";
import ProgressBar from "../shared/progress-bar";
import InstrumentalFileCreationInput from "./instrumental-file-creation-input";
import InstrumentalFileCreationGraph from "./instrumental-file-creation-graph";
import AnalysisFilesTable from "../shared/analysis-files-table";
import { useEffect, useState } from "react";
import { compareKeys } from "../../../helpers/general";
import { selectInstrumentalFileCreationCifFile, selectInstrumentalFileCreationCifFilesABC, selectInstrumentalFileCreationCifTemplateFile, selectInstrumentalFileCreationDataActiveFiles, selectInstrumentalFileCreationDataFiles, selectInstrumentalFileCreationDataViewFile, selectInstrumentalFileCreationInstrumentalTemplateFile, selectInstrumentalFileCreationWavelength } from "../../../redux/instrumentalFileCreation/instrumentalFileCreationSelectors";
import { GET_INSTRUMENTAL_FILE_CREATION_CALLBACK, SET_INSTRUMENTAL_FILE_CREATION_CRYSTALLITE_SIZE, SET_INSTRUMENTAL_FILE_CREATION_DATA_ACTIVE_FILES, SET_INSTRUMENTAL_FILE_CREATION_DATA_VIEW_FILE } from "../../../redux/instrumentalFileCreation/instrumentalFileCreationActions";
import { GET_INSTANCE_RUNNING_STATUS, GET_METHOD_DATA, GET_PHASES } from "../../../redux/project/projectActions";
import { instrumentalFileCreationCifMapping } from "../../../data/instrumental_file_creation";

const InstrumentalFileCreationAnalysis = ({ dispatch }: {dispatch: Dispatch}) => {
  const instanceId = useSelector(selectInstanceId)
  const statusMessage = useSelector(selectProgressMessage)
  const statusTitle = useSelector(selectProgressTitle)
  const dataViewFile = useSelector(selectInstrumentalFileCreationDataViewFile)
  const dataFiles = useSelector(selectInstrumentalFileCreationDataFiles)
  const dataActiveFiles = useSelector(selectInstrumentalFileCreationDataActiveFiles)
  const cifFile = useSelector(selectInstrumentalFileCreationCifFile)
  const cifTemplateFile = useSelector(selectInstrumentalFileCreationCifTemplateFile)
  const instrumentalTemplateFile = useSelector(selectInstrumentalFileCreationInstrumentalTemplateFile)
  const wavelength = useSelector(selectInstrumentalFileCreationWavelength)
  const cifFilesABC = useSelector(selectInstrumentalFileCreationCifFilesABC)

  const [filesInformation, setFilesInformation] = useState<any>([]);
	const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    if (dataViewFile) dispatch({ type: GET_INSTRUMENTAL_FILE_CREATION_CALLBACK })
	}, [dataViewFile, dispatch]);

  useEffect(() => {
		dispatch({ type: GET_METHOD_DATA, payload: {type: "instrumentalFileCreation"} });
  }, [dispatch])

  useEffect(() => {
    if (!cifFile && !cifTemplateFile) return;
    dispatch({ type: GET_PHASES, payload: { type: "instrumentalFileCreation"} });
	}, [dispatch, cifFile, cifTemplateFile, instrumentalTemplateFile, wavelength, dataViewFile, cifFilesABC]);

	useEffect(() => {
		if (intervalId !== undefined) {
			clearInterval(intervalId);
		}

		if (instanceId !== "") {
			const newIntervalId = setInterval(async () => {
			dispatch({ type: GET_INSTANCE_RUNNING_STATUS, payload: { instanceId: instanceId, projectType: "instrumentalFileCreation" } });
			}, 5000);

			setIntervalId(newIntervalId);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [instanceId]);

	useEffect(() => {
		if (!cifTemplateFile) {
			dispatch({ type: SET_INSTRUMENTAL_FILE_CREATION_CRYSTALLITE_SIZE, payload: 0})
		} else {
			dispatch({ type: SET_INSTRUMENTAL_FILE_CREATION_CRYSTALLITE_SIZE, payload: instrumentalFileCreationCifMapping[cifTemplateFile]})
		}
	}, [dispatch, cifTemplateFile]);

  useEffect(() => {
    let filesMapping = []

    for (const file of dataFiles) {
      filesMapping.push({ fileName: file })
    }

    filesMapping = filesMapping.sort((a, b) => compareKeys(a.fileName, b.fileName))
    if (filesMapping.length > 0 && !dataViewFile) dispatch({ type: SET_INSTRUMENTAL_FILE_CREATION_DATA_VIEW_FILE, payload: filesMapping[0].fileName });

    setFilesInformation(filesMapping)
  }, [dataFiles, dataViewFile, dispatch]);

  return (
    <div className={"flex w-full h-full gap-4 flex-1 xl:flex-col flex-grow"}>
      {instanceId &&
        <ProgressBar
          message={statusMessage}
          title={statusTitle}
          hideProgress={true}
        />
      }
      <div className={"flex flex-col bg-primary-800 rounded-md w-2/6"}>
          <div className={"flex flex-col border-b border-primary-900 border-solid overflow-y-auto"}>
              <InstrumentalFileCreationInput dispatch={dispatch} />
          </div>
      </div>
      <div className={"flex flex-col flex-1 bg-primary-800 rounded-md w-2/3"}>
          <InstrumentalFileCreationGraph dispatch={dispatch} />
          <AnalysisFilesTable
            data={filesInformation}
            dataViewFile={dataViewFile}
            setDataViewFile={(file) => dispatch({ type: SET_INSTRUMENTAL_FILE_CREATION_DATA_VIEW_FILE, payload: file })}
            setDataActiveFiles={(files) => dispatch({type: SET_INSTRUMENTAL_FILE_CREATION_DATA_ACTIVE_FILES, payload: files})}
            dataActiveFiles={dataActiveFiles}
          />
        </div>
    </div>
  );
};

export default InstrumentalFileCreationAnalysis;
