import { Dispatch } from "redux";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import PhaseQuantificationInput from "./phase-quantification-input";
import { compareKeysV2 } from "../../../helpers/general";
import { selectInstanceId, selectProgress, selectProgressMessage } from "../../../redux/general/generalSelectors";
import ProgressBar from "../shared/progress-bar";
import AnalysisFilesTable from "../shared/analysis-files-table";
import { selectPhaseQuantificationDataFiles, selectPhaseQuantification, selectPhaseQuantificationDataViewFile, selectPhaseQuantificationDataActiveFiles, selectPhaseQuantificationDebyeWallerParameter, selectPhaseQuantificationInstrumentalFile, selectPhaseQuantificationInstrumentalTemplateFile, selectPhaseQuantificationCifFiles, selectPhaseQuantificationCifTemplateFiles, selectPhaseQuantificationWavelength, selectPhaseQuantificationHistogram, selectPhaseQuantificationCifFilesABC } from "../../../redux/phaseQuantification/phaseQuantificationSelectors";
import { GET_INSTANCE_RUNNING_STATUS, GET_INSTRUMENTAL_FILE_WAVELENGTH, GET_LAMBDA_CACHE, GET_METHOD_DATA, GET_PHASES } from "../../../redux/project/projectActions";
import { numberShortener } from "../../../helpers/name";
import PhaseQuantificationGraph from "./phase-quantification-graph";
import { GET_PHASE_QUANTIFICATION_CALLBACK_FITTED, GET_PHASE_QUANTIFICATION_CALLBACK_PARAMETERS, SET_PHASE_QUANTIFICATION_DATA_ACTIVE_FILES, SET_PHASE_QUANTIFICATION_DATA_VIEW_FILE } from "../../../redux/phaseQuantification/phaseQuantificationActions";

const PhaseQuantificationAnalysis = ({ dispatch, pipeline=false }: { dispatch: Dispatch, pipeline?: boolean }) => {
  const phaseQuantificationDataFiles = useSelector(selectPhaseQuantificationDataFiles);
  const phaseQuantification = useSelector(selectPhaseQuantification);
  const phaseQuantificationDataViewFile = useSelector(selectPhaseQuantificationDataViewFile);
  const instanceId = useSelector(selectInstanceId)
  const progress = useSelector(selectProgress)
  const statusMessage = useSelector(selectProgressMessage)
  const dataActiveFiles = useSelector(selectPhaseQuantificationDataActiveFiles)
  const debyeWellerParameters = useSelector(selectPhaseQuantificationDebyeWallerParameter)
	const instrumentalFile = useSelector(selectPhaseQuantificationInstrumentalFile);
	const instrumentalFileTemplate = useSelector(selectPhaseQuantificationInstrumentalTemplateFile);
  const cifFiles = useSelector(selectPhaseQuantificationCifFiles);
  const cifTemplateFiles = useSelector(selectPhaseQuantificationCifTemplateFiles);
  const instrumentalTemplateFile = useSelector(selectPhaseQuantificationInstrumentalTemplateFile);
  const wavelength = useSelector(selectPhaseQuantificationWavelength);
  const dataFiles = useSelector(selectPhaseQuantificationDataFiles);
  const dataViewFile = useSelector(selectPhaseQuantificationDataViewFile);
  const histogram = useSelector(selectPhaseQuantificationHistogram);
  const cifFilesABC = useSelector(selectPhaseQuantificationCifFilesABC);

  const [filesInformation, setFilesInformation] = useState<any>([]);
  const [headersFileInformation, setHeadersFileInformation] = useState<any>([]);
	const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>(undefined);
  const [lastFile, setLastFile] = useState<string>("");

  useEffect(() => {
    if (pipeline) return;

		if (intervalId !== undefined) clearInterval(intervalId);

		if (instanceId !== "") {
			const newIntervalId = setInterval(async () => {
				dispatch({ type: GET_INSTANCE_RUNNING_STATUS, payload: { instanceId: instanceId, projectType: "phaseQuantification" } });
			}, 5000);

			setIntervalId(newIntervalId);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [instanceId]);

  useEffect(() => {
    if (pipeline) return;

    dispatch({ type: GET_METHOD_DATA, payload: {type: "phaseQuantification"} })
    dispatch({ type: GET_LAMBDA_CACHE, payload: {callerType: "phaseQuantification"} })
  }, [dispatch, pipeline])

  useEffect(() => {
    if (!phaseQuantification.dataViewFile || pipeline) return;

    dispatch({ type: GET_PHASE_QUANTIFICATION_CALLBACK_FITTED })
  }, [dispatch, phaseQuantification.dataViewFile, pipeline])

  useEffect(() => {
    if (pipeline) return;

    dispatch({ type: GET_PHASE_QUANTIFICATION_CALLBACK_PARAMETERS })
  }, [dispatch, pipeline, dataActiveFiles])

  useEffect(() => {
		if ((!instrumentalFile && !instrumentalFileTemplate)) return;

		dispatch({ type: GET_INSTRUMENTAL_FILE_WAVELENGTH, payload: { type: "phaseQuantification" } });
	}, [dispatch, instrumentalFile, instrumentalFileTemplate]);

  useEffect(() => {
    if (pipeline) return;

    if((cifFiles.length === 0 && cifTemplateFiles.length === 0) || (!instrumentalFile && !instrumentalTemplateFile) || !dataViewFile) return;

    dispatch({ type: GET_PHASES, payload: { type: "phaseQuantification"} });
	}, [dispatch, cifFiles, cifTemplateFiles, instrumentalFile, instrumentalTemplateFile, wavelength, dataFiles, dataViewFile, pipeline, cifFilesABC]);

  useEffect(() => {
    if (lastFile === dataViewFile && histogram.instrumental.x.length !== 0) return;
    if (!pipeline) return;

    if((cifFiles.length === 0 && cifTemplateFiles.length === 0) || (!instrumentalFile && !instrumentalTemplateFile) || !dataViewFile) return;

    setLastFile(dataViewFile)
  
    dispatch({ type: GET_PHASES, payload: { type: "pipelinePhaseQuantification"} });
	}, [dispatch, cifFiles, cifTemplateFiles, instrumentalFile, instrumentalTemplateFile, wavelength, dataFiles, dataViewFile, lastFile, histogram.instrumental.x.length, pipeline]);

  useEffect(() => {
    let filesMapping = []
    let headers = []
    let parametersHeaders = []

    for (const file of phaseQuantificationDataFiles) {
      filesMapping.push({ fileName: file })
    }

    filesMapping = filesMapping.sort((a, b) => compareKeysV2(a.fileName, b.fileName))

    for (const key in debyeWellerParameters) {
      const debyeWeller = debyeWellerParameters[key]
      const index = filesMapping.findIndex((file) => file.fileName === key)
      if (index === -1) continue;

      for (const key in debyeWeller) {
        if (key === "std_dev") continue;
        
        filesMapping[index][`${key}: vol frac`] = numberShortener(debyeWeller[key]);
        filesMapping[index][`${key}: uncertainty`] = numberShortener(debyeWeller["std_dev"]);

        if (!headers.includes(`${key}: vol frac`)) headers.push(`${key}: vol frac`)
        if (!headers.includes(`${key}: uncertainty`)) headers.push(`${key}: uncertainty`)
      }
    }

    for (const key in phaseQuantification.fitting) {
      const fitting = phaseQuantification.fitting[key]
      const index = filesMapping.findIndex((file) => file.fileName === key)
      if (index === -1) continue;

      let newItems = {}
      for (const key in fitting) {
        for (const innerKey in fitting[key]) {
          newItems[`${key}: ${innerKey}`] = numberShortener(fitting[key][innerKey])
        }
      }

      const sortedKeys = Object.keys(newItems).sort((a, b) => {
        const matchA = a.match(/^(\D+)(\d+):/);
        const matchB = b.match(/^(\D+)(\d+):/);
        if (matchA && matchB) {
            const letterCompare = matchA[1].localeCompare(matchB[1]);
            if (letterCompare !== 0) return letterCompare;
            return parseInt(matchA[2], 10) - parseInt(matchB[2], 10);
        }
        return 0;
      });

      const sortedNewItems = {};
      for (const sortedKey of sortedKeys) {
        sortedNewItems[sortedKey] = newItems[sortedKey];
        if (!parametersHeaders.includes(sortedKey)) parametersHeaders.push(sortedKey)
      }

      filesMapping[index] = { ...filesMapping[index], ...sortedNewItems };
    }

    let sortedKeysParams = parametersHeaders.sort((a, b) => {
      const matchA = a.match(/^(\D+)(\d+):/);
      const matchB = b.match(/^(\D+)(\d+):/);
      if (matchA && matchB) {
          const letterCompare = matchA[1].localeCompare(matchB[1]);
          if (letterCompare !== 0) return letterCompare;
          return parseInt(matchA[2], 10) - parseInt(matchB[2], 10);
      }
      return 0;
    });

    setFilesInformation(filesMapping)
    setHeadersFileInformation([...headers, ...sortedKeysParams])
  }, [phaseQuantificationDataFiles, dispatch, phaseQuantification.fitting, debyeWellerParameters])

  useEffect(() => {
    if (phaseQuantificationDataFiles.length > 0 && !phaseQuantificationDataViewFile) {
      const dataFiles = phaseQuantificationDataFiles.sort((a, b) => compareKeysV2(a, b))
      let selectedDataFile = dataFiles[0]

      dispatch({ type: SET_PHASE_QUANTIFICATION_DATA_VIEW_FILE, payload: selectedDataFile });
    }
  }, [phaseQuantificationDataFiles, dispatch, phaseQuantificationDataViewFile])

  return (
    <div className={"flex h-full gap-4 flex-1 xl:flex-col flex-grow"} style={{ width: "calc(100vw - 30px)"}}>
      {instanceId && !pipeline &&
        <ProgressBar
          progress={progress}
          message={statusMessage}
          title={"Running Peak Fitting Analysis..."}
        />
      }
      <div className={"flex flex-col bg-primary-800 rounded-md w-2/6"}>
        <div className={"flex flex-col border-b border-primary-900 border-solid overflow-y-auto"}>
          <PhaseQuantificationInput dispatch={dispatch} pipeline={pipeline} />
        </div>
      </div>
      <div className={"flex flex-col flex-1 bg-primary-800 rounded-md w-2/3"}>
        <PhaseQuantificationGraph dispatch={dispatch} />
        <AnalysisFilesTable
          data={filesInformation}
          dataViewFile={phaseQuantificationDataViewFile}
          setDataViewFile={(file) => dispatch({type: SET_PHASE_QUANTIFICATION_DATA_VIEW_FILE, payload: file })}
          setDataActiveFiles={(files) => dispatch({type: SET_PHASE_QUANTIFICATION_DATA_ACTIVE_FILES, payload: files})}
          dataActiveFiles={dataActiveFiles}
          headersOrdered={headersFileInformation}
          pipeline={pipeline}
        />
      </div>
    </div>
  );
};

export default PhaseQuantificationAnalysis;
