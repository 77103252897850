import { useState, useEffect } from "react";
import ProjectSettings from "./project-settings/project-settings";
import { connect, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { GET_DOWNLOAD_CHECKER } from "../redux/project/projectActions";
import { GlobalState } from "../store/models";
import AnalysisBridge from "./analysis-settings/analysis-bridge";
import Report from "./analysis-settings/old/report";
import ReportScreen from "./report-screen/report-screen";
import { selectAnalysisType } from "../redux/project/projectSelectors";
import { selectDownloadInstanceId } from "../redux/general/generalSelectors";
import ManageFiles from "./manage-files/manage-files";

const AnalysisStart = ({dispatch}: {dispatch: Dispatch }) => {
  const analysisType = useSelector(selectAnalysisType);
  const instanceId = useSelector(selectDownloadInstanceId);

	const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>(undefined);
  const [activeTab, setActiveTab] = useState<"project_settings" | "manage_files" | "analysis_settings" | "report">("project_settings");

  useEffect(() => {
		if (intervalId !== undefined) {
			clearInterval(intervalId);
		}

		if (instanceId !== "") {
			const newIntervalId = setInterval(async () => {
				dispatch({ type: GET_DOWNLOAD_CHECKER, payload: { instanceId: instanceId } });
			}, 5000);

			setIntervalId(newIntervalId);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [instanceId]);

  const allAnalysis = ["dislocation_density", "peak_fitting", "phase_quantification", "le_bail", "azimuthal_integration", "instrumental_file_creation", "data_calibration", "rietveld"]
  const reportScreenAnalysis = ["peak_fitting", "phase_quantification", "le_bail", "dislocation_density"]

  const tabs = (activeTab: string) => {
    switch (activeTab) {
      case "project_settings":
        return <ProjectSettings dispatch={dispatch} />;
      case "manage_files":
        return <ManageFiles dispatch={dispatch} type={analysisType} />;
      case "analysis_settings":
        return <AnalysisBridge type={analysisType} dispatch={dispatch} />;
      case "report":
        return !allAnalysis.includes(analysisType) ? <Report dispatch={dispatch}  /> : <ReportScreen dispatch={dispatch} />;
      default:
        return <ProjectSettings dispatch={dispatch} />;
    }
  }

  return (
    <div className={"flex flex-grow flex-col w-full p-2 px-4 gap-2 flex-1 md:px-2" } style={{ height: "65%"}}>
      <div className={`flex justify-between gap-2 sm:flex-col z-10`}>
        <div
          className={`${activeTab === "project_settings" ? "bg-primary-600" : "bg-primary-800"} flex-1 text-white text-sm text-center font-bold p-4  rounded-md cursor-pointer`}
          onClick={() => setActiveTab("project_settings")}
        >
          1. Project settings
        </div>
        <div
          className={`${activeTab === "manage_files" ? "bg-primary-600" : "bg-primary-800"} ${!analysisType ? "text-primary-500" : "text-white cursor-pointer"} flex-1 text-sm text-center font-bold p-4  rounded-md`}
          onClick={() => analysisType ? setActiveTab("manage_files") : () => {}}
        >
          2. Upload Data
        </div>
        <div
          className={`${activeTab === "analysis_settings" ? "bg-primary-600" : "bg-primary-800"} ${!analysisType ? "text-primary-500" : "text-white cursor-pointer"} flex-1 text-sm text-center font-bold p-4  rounded-md`}
          onClick={() => analysisType && analysisType ? setActiveTab("analysis_settings") : () => {}}
        >
          3. Analysis settings
        </div>
        <div
          className={`${activeTab === "report" ? "bg-primary-600" : "bg-primary-800"} ${!(analysisType && reportScreenAnalysis.includes(analysisType)) ? "text-primary-500" : "text-white cursor-pointer"} flex-1 text-sm text-center font-bold p-4 rounded-md`}
          onClick={() => analysisType && analysisType && reportScreenAnalysis.includes(analysisType) ? setActiveTab("report") : () => {}}
        >
          4. Report
        </div>
      </div>
      {tabs(activeTab)}
    </div>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  projectReducer: state.projectReducer,
});

export default connect(mapStateToProps)(AnalysisStart);
