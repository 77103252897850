import { Dispatch } from "redux";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { compareKeys } from "../../helpers/general";
import { getName } from "../../helpers/name";
import { selectCurrentProjectFiles, selectReportScreen } from "../../redux/project/projectSelectors";
import ReportScreenGraph from "./report-screen-graph";
import AnalysisTable from "../analysis-settings/shared/analysis-table";
import { GET_PROJECT_FILES, GET_REPORT_SCREEN_ANALYSIS_TYPES, SET_REPORT_SCREEN_DATA_VIEW_FILES } from "../../redux/project/projectActions";
import ReportScreenInput from "./report-screen-input";

const ReportScreen = ({ dispatch }: {dispatch: Dispatch}) => {
  const reportScreen = useSelector(selectReportScreen)
  const currentProjectFiles = useSelector(selectCurrentProjectFiles);

  const [filesInformation, setFilesInformation] = useState<any>([]);
  const [graphType, setGraphType] = useState("Line" as "Line" | "Heatmap");

  useEffect(() => {
    dispatch({ type: GET_REPORT_SCREEN_ANALYSIS_TYPES });
    dispatch({ type: GET_PROJECT_FILES });
  }, [dispatch]);

  useEffect(() => {
    let files = currentProjectFiles.filter((file) => file.fileRef.endsWith(".dat"));

    setFilesInformation(files.map((file) => ({ ...file, fileKey: getName(file.fileRef || file.fileRef) })).sort((a, b) => compareKeys(a.fileKey, b.fileKey)))
  }, [currentProjectFiles]);

  return (
    <div className={"flex w-full gap-4 flex-1 xl:flex-col flex-grow"} style={{ height: "80%" }}>
      <div className={"flex flex-col bg-primary-800 rounded-md w-2/6"}>
        <div className={"flex flex-col border-b border-primary-900 border-solid overflow-y-auto h-full"}>
          <ReportScreenInput dispatch={dispatch} graphType={graphType} />
        </div>
      </div>
      <div className={"flex flex-col flex-1 bg-primary-800 rounded-md w-2/3"}>
        <ReportScreenGraph dispatch={dispatch} graphType={graphType} setGraphType={(graphType) => setGraphType(graphType)} />
        <AnalysisTable
          dispatch={dispatch}
          files={filesInformation}
          fileSetter={(files) => {
            dispatch({ type: SET_REPORT_SCREEN_DATA_VIEW_FILES, payload: files });
          }}
          filesDataPoints={reportScreen.dataViewFiles}
        />
        </div>
    </div>
  );
};

export default ReportScreen;
