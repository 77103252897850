import { Dispatch } from "redux";
import { useSelector } from "react-redux";
import { selectProgress, selectProgressMessage, selectProgressTitle } from "../../../redux/general/generalSelectors";
import AzimuthalIntegrationInput from "./azimuthal-integration-input";
import AzimuthalIntegrationGraph from "./azimuthal-integration-graph";
import AzimuthalIntegrationTable from "./azimuthal-integration-table";
import ProgressBarOnDemand from "../shared/progress-bar-on-demand";
import { useEffect, useState } from "react";
import { GET_METHOD_DATA, GET_ON_DEMAND_PROGRESS, GET_ON_DEMAND_STATUS, START_ON_DEMAND } from "../../../redux/project/projectActions";
import { selectAzimuthalIntegrationDataViewFile, selectAzimuthalIntegrationImageOptions, selectAzimuthalIntegrationPartitions } from "../../../redux/azimuthalIntegration/azimuthalIntegrationSelectors";
import { GET_AZIMUTHAL_INTEGRATION_CALLBACK_IMAGES, GET_AZIMUTHAL_INTEGRATION_IMAGE, SET_AZIMUTHAL_INTEGRATION_DATA_VIEW_FILE } from "../../../redux/azimuthalIntegration/azimuthalIntegrationActions";
import { selectOnDemandStatus } from "../../../redux/project/projectSelectors";

const AzimuthalIntegrationAnalysis = ({ dispatch, pipeline=false }: {dispatch: Dispatch, pipeline?: boolean }) => {
  const progress = useSelector(selectProgress)
  const statusMessage = useSelector(selectProgressMessage)
  const statusTitle = useSelector(selectProgressTitle)
  const dataViewFile = useSelector(selectAzimuthalIntegrationDataViewFile)
  const imageOptions = useSelector(selectAzimuthalIntegrationImageOptions)
  const onDemandStatus = useSelector(selectOnDemandStatus)
  const partitions = useSelector(selectAzimuthalIntegrationPartitions)

	const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>(undefined);
  const [intervalIdStatus, setIntervalIdStatus] = useState<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    if (pipeline) return;

		dispatch({ type: START_ON_DEMAND, payload: { analysisType: "azimuthalIntegration" }});
	  dispatch({ type: GET_METHOD_DATA, payload: { type: "azimuthalIntegration" }});
    dispatch({ type: GET_AZIMUTHAL_INTEGRATION_CALLBACK_IMAGES })

	}, [dispatch, pipeline]);

  useEffect(() => {
    if (pipeline) return;

    if (intervalIdStatus !== undefined) clearInterval(intervalIdStatus);

    const newIntervalId = setInterval(() => {
      if (onDemandStatus === "RUNNING") dispatch({ type: GET_ON_DEMAND_PROGRESS, payload: { analysisType: "azimuthalIntegration" }});
    }, 5000);

    setIntervalIdStatus(newIntervalId);

    return () => clearInterval(newIntervalId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pipeline, onDemandStatus]);

  useEffect(() => {
    if (pipeline) return;

    if (intervalId !== undefined) clearInterval(intervalId);

    const intervalTime = onDemandStatus === "RUNNING" ? 15000 : 5000;

    const newIntervalId = setInterval(async () => {
      dispatch({ type: GET_ON_DEMAND_STATUS, payload: { analysisType: "azimuthalIntegration" }});
    }, intervalTime);

    setIntervalId(newIntervalId);

    return () => clearInterval(newIntervalId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pipeline, onDemandStatus]);

  useEffect(() => {
    if (!dataViewFile) return

    dispatch({ type: GET_AZIMUTHAL_INTEGRATION_IMAGE })
  }, [dataViewFile, dispatch, imageOptions])

  useEffect(() => {
    if (dataViewFile) return

    for (let rowIndex = 0; rowIndex < partitions.reduce((maxLength, part) => Math.max(maxLength, part.dataFiles.length), 0); rowIndex++) {
      const firstNonEmptyFile = partitions.find((part) => part.dataFiles[rowIndex] && part.dataFiles[rowIndex] !== "");

      if (firstNonEmptyFile && firstNonEmptyFile.dataFiles[rowIndex]) {
        dispatch({ type: SET_AZIMUTHAL_INTEGRATION_DATA_VIEW_FILE, payload: firstNonEmptyFile.dataFiles[rowIndex] });
        break; // Stop searching after finding the first valid file
      }
    }
  }, [dataViewFile, partitions, dispatch]);

  return (
    <div className={"flex w-full h-full gap-4 flex-1 xl:flex-col flex-grow"}>
      {!pipeline &&
        <ProgressBarOnDemand
          progress={progress}
          message={statusMessage}
          title={statusTitle}
          computingInstance={onDemandStatus}
        />
      }
      <div className={"flex flex-col bg-primary-800 rounded-md w-2/6"}>
        <div className={"flex flex-col border-b border-primary-900 border-solid overflow-y-auto"}>
          <AzimuthalIntegrationInput dispatch={dispatch} pipeline={pipeline} />
        </div>
      </div>
      <div className={"flex flex-col flex-1 bg-primary-800 rounded-md w-2/3"}>
        <AzimuthalIntegrationGraph dispatch={dispatch} pipeline={pipeline} />
        <AzimuthalIntegrationTable dispatch={dispatch} />
      </div>
    </div>
  );
};

export default AzimuthalIntegrationAnalysis;
