import { useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { selectPipelineStatus } from '../../../redux/project/projectSelectors';
import { START_PIPELINE, STOP_PIPELINE } from '../../../redux/project/projectActions';
import { selectOperationInProgressDict, selectPipelineProgress } from '../../../redux/general/generalSelectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const PipelineStatus = ({ dispatch, pipelineType }: { dispatch: Dispatch, pipelineType: string }) => {
  const pipelineStatus = useSelector(selectPipelineStatus);
  const pipelineProgress = useSelector(selectPipelineProgress);
  const operationInProgressDict = useSelector(selectOperationInProgressDict);

  const pipelineStatusText = () => {
    if (pipelineStatus === "NO_INSTANCES") {
      return <span className="text-red-500 font-bold">The Pipeline is currently not running</span>;
    } else if (pipelineStatus === "STARTING") {
      return <span className="text-yellow-500 font-bold">The Pipeline is currently starting...</span>;
    } else {
      return <span className="text-green-500 font-bold">The Pipeline is currently running</span>;
    }
  }

  return (
    <div className="bg-primary-600 rounded-md w-full h-full relative">
      <h1 className="text-white text-6xl mb-4 flex items-center justify-center p-4">Continuous Automated Analysis Pipeline</h1>
      <div className="flex items-center justify-center p-4 h-full" style={{ marginTop: "-150px" }}>
        <div className="flex flex-col items-center mx-12">
          <span className="text-white text-2xl">Azimuthal Integration</span>
          <span className="text-white text-8xl">{pipelineProgress.azimuthalIntegration}</span>
        </div>
        <div className="flex flex-col items-center mx-12">
          <span className="text-white text-2xl">Phase Quantification</span>
          <span className="text-white text-8xl">{pipelineProgress.phaseQuantification}</span>
        </div>
        <div className="flex flex-col items-center mx-12">
          <span className="text-white text-2xl">Processed Data</span>
          <span className="text-white text-8xl">{pipelineProgress.processed}</span>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center mt-6" style={{ marginTop: "-150px" }}>
        <span className="text-white text-2xl">Current Pipeline Status:</span>
        <span className="text-white text-xl mb-4">{pipelineStatusText()}</span>
        <div className="flex mt-8">
          <button
            className="bg-green-500 text-white py-2 px-4 rounded mr-4"
            onClick={() => dispatch({ type: START_PIPELINE, payload: { pipelineType } })}
          >
            {operationInProgressDict.includes("START_PIPELINE") ? (
              <>
                <FontAwesomeIcon icon={faSpinner} spin style={{ marginRight: "5px" }} />
                Loading...
              </>
            ) : (
              "Start Pipeline"
            )}
          </button>
          <button
            className="bg-red-500 text-white py-2 px-4 rounded"
            onClick={() => dispatch({ type: STOP_PIPELINE, payload: { pipelineType } })}
          >
            {operationInProgressDict.includes("STOP_PIPELINE") ? (
              <>
                <FontAwesomeIcon icon={faSpinner} spin style={{ marginRight: "5px" }} />
                Loading...
              </>
            ) : (
              "Stop Pipeline"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PipelineStatus;
