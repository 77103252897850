import Loader from "react-loader-spinner";
import Plot from "react-plotly.js";
import { useSelector } from "react-redux";
import { getInInlineOperationInProgress, getInlineOperationInProgress } from "../../../redux/general/generalSelectors";
import { useEffect, useState } from "react";
import { Dispatch } from "redux";
import toast from "react-hot-toast";
import { SET_DISLOCATION_DENSITY_L0_PICKER_RANGE, SET_DISLOCATION_DENSITY_PARTITIONS, SET_DISLOCATION_DENSITY_RHO_PICKER_RANGE } from "../../../redux/dislocationDensity/dislocationDensityActions";
import { selectDislocationDensityCifFiles, selectDislocationDensityCifTemplateFiles, selectDislocationDensityDataFiles, selectDislocationDensityDataViewFile, selectDislocationDensityHistogram, selectDislocationDensityInstrumentalFile, selectDislocationDensityInstrumentalTemplateFile, selectDislocationDensityK2CDK, selectDislocationDensityK2CLNA, selectDislocationDensityL0Analysis, selectDislocationDensityL0PickerData, selectDislocationDensityL0_selectionActive, selectDislocationDensityPartitionActiveIndex, selectDislocationDensityPartitionFitting, selectDislocationDensityPartitionSelectingPeaks, selectDislocationDensityPartitions, selectDislocationDensityRHO_selectionActive, selectDislocationDensityRhoAnalysis, selectDislocationDensityRhoPickerData, selectDislocationDensityWH, selectDislocationDensityWavelength } from "../../../redux/dislocationDensity/dislocationDensitySelectors";
import { GET_PHASES } from "../../../redux/project/projectActions";
import { DropDownOptions } from "../../../data/models";
import DropDown from "../shared/drop-down";
import { getDislocationDensityGraph, getDislocationDensityGraphLabels } from "./dislocation-density-helpers";
import { getPartitionHistogramGraph } from "../../shared/graph-utils";

const DislocationDensityGraph = ({dispatch }: { dispatch: Dispatch }) => {
  const inLineOperationInProgress = useSelector(getInlineOperationInProgress)
  const inInlineOperationInProgress = useSelector(getInInlineOperationInProgress)
  const cifFiles = useSelector(selectDislocationDensityCifFiles);
  const cifTemplateFiles = useSelector(selectDislocationDensityCifTemplateFiles);
  const instrumentalFile = useSelector(selectDislocationDensityInstrumentalFile);
  const instrumentalTemplateFile = useSelector(selectDislocationDensityInstrumentalTemplateFile);
  const wavelength = useSelector(selectDislocationDensityWavelength);
  const dataFiles = useSelector(selectDislocationDensityDataFiles);
  const dataViewFile = useSelector(selectDislocationDensityDataViewFile);
  const selectedPartitionIndex = useSelector(selectDislocationDensityPartitionActiveIndex);
  const selectPeaks = useSelector(selectDislocationDensityPartitionSelectingPeaks);
  const partitions = useSelector(selectDislocationDensityPartitions);
  const histogram = useSelector(selectDislocationDensityHistogram);
  const partitionFitting = useSelector(selectDislocationDensityPartitionFitting);
  const wh = useSelector(selectDislocationDensityWH);
  const rhoSelectionActive = useSelector(selectDislocationDensityRHO_selectionActive);
	const l0SelectionActive = useSelector(selectDislocationDensityL0_selectionActive);
  const k2_cdk = useSelector(selectDislocationDensityK2CDK);
  const k2c_lna = useSelector(selectDislocationDensityK2CLNA);
  const l0Picker = useSelector(selectDislocationDensityL0PickerData);
  const rhoPicker = useSelector(selectDislocationDensityRhoPickerData);
  const l0Analysis = useSelector(selectDislocationDensityL0Analysis);
  const rhoAnalysis = useSelector(selectDislocationDensityRhoAnalysis);
  const cifFilesABC = useSelector(selectDislocationDensityCifFiles);

  const [activeTab, setActiveTab] = useState("histogram")
  const [chartFitting, setChartFitting] = useState<{ [key: string]: any[] }>({});
  const [chartShapes, setChartShapes] = useState<any>([]);
  const [reload, setReload] = useState(false);
  const [maxCoordinate, setMaxCoordinate] = useState<any>({
    xLower: undefined,
    xHigher: undefined,
    yMax: undefined,
    yMin: undefined,
  });
  const [visibleDiagrams, setVisibleDiagrams] = useState({
    id: "diagrams",
    label: "Diagrams",
    options: [],
  } as DropDownOptions)

  useEffect(() => {
    if (selectPeaks) {
      const rangeLowerX = partitions[selectedPartitionIndex].rangeLower.x;
      const rangeHigherX = partitions[selectedPartitionIndex].rangeHigher.x;
      const range = rangeHigherX - rangeLowerX;
      const xLower = rangeLowerX - (range * 0.1);
      const xHigher = rangeHigherX + (range * 0.1);

      // get the max and lower y values in the range
      let yMax = 0;
      let yMin = 0;

      if (histogram.instrumental.x && histogram.instrumental.y) {
        const indexLower = histogram.instrumental.x.indexOf(rangeLowerX);
        const indexHigher = histogram.instrumental.x.indexOf(rangeHigherX);

        yMax = Math.max(...histogram.instrumental.y.slice(indexLower, indexHigher));
        yMin = Math.min(...histogram.instrumental.y.slice(indexLower, indexHigher));

        yMin -= (yMax - yMin) * 0.05;
        yMax += (yMax - yMin) * 0.05;
      }

      setMaxCoordinate({
        xLower,
        xHigher,
        yMax,
        yMin,
      });
    } else {
      setMaxCoordinate({
        xLower: undefined,
        xHigher: undefined,
        yMax: undefined,
        yMin: undefined,
      });
    }
  }, [selectPeaks, partitions, selectedPartitionIndex, reload, histogram.instrumental.x, histogram.instrumental.y]);

  const handleClick = (event: any) => {
    if (event.points.length > 0) {
      const data = event.points[0];

      if (selectPeaks) {
        if (!partitions[selectedPartitionIndex].rangeLower.x || !partitions[selectedPartitionIndex].rangeHigher.x) {
          toast.error("Please select a range first")
          return;
        }

        if (data.x < partitions[selectedPartitionIndex].rangeLower || data.x > partitions[selectedPartitionIndex].rangeHigher) {
          toast.error("Please select a point within the range")
          return;
        }

        partitions[selectedPartitionIndex].peaks.push({
          x: data.x,
          y: data.y,
          function: "",
          includeInAnalysis: false,
          constraint: {
            centerMin: undefined,
            centerMax: undefined,
            sigmaMin: undefined,
            sigmaMax: undefined,
            heightMin: undefined,
            heightMax: undefined,
          }
        })

        dispatch({ type: SET_DISLOCATION_DENSITY_PARTITIONS, payload: partitions });

        toast.success("Selected a Peak")
      } else if (rhoSelectionActive) {
        dispatch({ type: SET_DISLOCATION_DENSITY_RHO_PICKER_RANGE, payload: parseFloat(data.x.toFixed(4)) });

        toast.success("Updated Rho Range")
      } else if (l0SelectionActive) {
        dispatch({ type: SET_DISLOCATION_DENSITY_L0_PICKER_RANGE, payload: parseFloat(data.x.toFixed(4)) });

        toast.success("Updated L0 Range")
      } else {
        const range = partitions[selectedPartitionIndex].rangeHistory
        range[1] = range[0];
        range[0] = data.x;

        partitions[selectedPartitionIndex] = {
          ...partitions[selectedPartitionIndex],
          rangeHistory: range,
          rangeLower: range[0] <= range[1] ? {
            x: range[0],
            y: 0
          } : {
            x: range[1],
            y: 0
          },
          rangeHigher: range[0] > range[1] ? {
            x: range[0],
            y: 0
          } : {
            x: range[1],
            y: 0
          }
        }
        
        toast.success("Updated Partition Range")

        dispatch({ type: SET_DISLOCATION_DENSITY_PARTITIONS, payload: partitions });
      }
    }

    setReload(!reload);
  };

  useEffect(() => {
    const currentChartShapes = {}

    const partitionHistogramGraph = getPartitionHistogramGraph(histogram, partitions, selectedPartitionIndex, partitionFitting, dataViewFile)
    const dislocationDensityGraph = getDislocationDensityGraph(wh, k2_cdk, k2c_lna, l0Picker, rhoPicker, l0Analysis, rhoAnalysis)

    currentChartShapes["histogram"] = [...partitionHistogramGraph.histogramData, ...partitionHistogramGraph.traces];

    setChartFitting(
      { ...currentChartShapes, ...dislocationDensityGraph, ...partitionHistogramGraph.currentChartShapes }
    );

    setChartShapes(
      [...partitionHistogramGraph.chartShapesCurrent]
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataViewFile, histogram, partitionFitting, partitions, reload, selectedPartitionIndex, wh, k2_cdk, k2c_lna, l0Picker, rhoPicker, l0Analysis, rhoAnalysis]);

	useEffect(() => {
    dispatch({ type: GET_PHASES, payload: { type: "dislocationDensity"} });
	}, [dispatch, cifFiles, cifTemplateFiles, instrumentalFile, instrumentalTemplateFile, wavelength, dataFiles, dataViewFile, cifFilesABC]);

  useEffect(() => {
    let dropdownOptions = {
      id: "diagrams",
      label: "Diagrams",
      options: [
        {
          id: "histogram",
          label: "Histogram",
          values: [
            { label: "Histogram", value: "histogram" },
          ],
        },
      ],
    } as DropDownOptions

    let partitionFittingRes = partitionFitting && Object.keys(partitionFitting).length ? partitionFitting[dataViewFile] : undefined;

    if (partitionFittingRes) {
      dropdownOptions.options.push({
        id: "partitions",
        label: "Partitions",
        values: Object.keys(partitionFittingRes).filter(value => value !== "histogram").map(value => {
          return { label: `Partition ${parseInt(value) + 1}`, value: value }
        })
      })
    }

    if (wh?.x?.length) {
      dropdownOptions.options.push({
        id: "wh",
        label: "Williamson-Hall",
        values: [
          { label: "Williamson-Hall", value: "wh" },
        ],
      })
    }

    if (k2_cdk.length) {
      dropdownOptions.options.push({
        id: "k2cdk",
        label: "Modified Williamson-Hall",
        values: [
          { label: "K^2C vs ΔK", value: "k2cdk" },
          { label: "K^2C vs Ln(A)", value: "k2clna" },
        ],
      })
    }

    if (l0Picker.x.length && l0Picker.y.length) {
      const values = [{ label: "Subgrain Size Picker", value: "l0" }];

      if (l0Analysis.x.length && l0Analysis.y.length) {
        values.push({ label: "Subgrain Size Fit", value: "l0Analysis" });
      }

      dropdownOptions.options.push({
        id: "subgrainSize",
        label: "Subgrain Size Estimation",
        values: values,
      })
    }

    if (rhoPicker.x.length && rhoPicker.y.length) {
      const values = [{ label: "Density Picker", value: "rho" }];

      if (rhoAnalysis.x.length && rhoAnalysis.y.length) {
        values.push({ label: "Density Fit", value: "rhoAnalysis" });
      }

      dropdownOptions.options.push({
        id: "Density Estimation",
        label: "Density Estimation",
        values: values,
      })
    }

    setVisibleDiagrams(dropdownOptions)
  }, [chartFitting, wh, k2_cdk, k2c_lna, partitionFitting, dataViewFile, l0Picker, rhoPicker, l0Analysis, rhoAnalysis]);

  useEffect(() => {
    if (l0SelectionActive) {
      setActiveTab("l0");
    }
  }, [l0SelectionActive])

  useEffect(() => {
    if (rhoSelectionActive) {
      setActiveTab("rho");
    }
  }, [rhoSelectionActive])

  useEffect(() => {
    if (selectPeaks) {
      setActiveTab("histogram");
    }
  }, [selectPeaks])

  if (!inLineOperationInProgress && (chartFitting && Object.keys(chartFitting).length !== 0)) {
    return (
      <div className="relative flex flex-col justify-center items-center">
        {(inInlineOperationInProgress || inInlineOperationInProgress) &&
          <div className={"absolute top-2 right-2"}>
            <Loader type="Oval" color="#68d391" height={25} width={25} />
          </div>
        }
        <div className={"flex justify-center items-center w-96 z-10"}>
          <DropDown
            DropDownOptions={visibleDiagrams}
            title={"Select Diagram"}
            setSelected={(value: string) => setActiveTab(value)}
            selected={activeTab}
            multiple={false}
            padding={"p-2"}
            closeOnClick={true}
          />
        </div>
        <div className={"flex justify-around w-full"}>
          <Plot
            data={
              chartFitting[activeTab] ? chartFitting[activeTab] : []
            }
            layout={{
              autosize: true,
              paper_bgcolor: "rgba(0,0,0,0)",
              plot_bgcolor: "rgba(0,0,0,0)",
              legend: {
                orientation: "v",
                font: {
                  family: "sans-serif",
                  size: 12,
                  color: "white",
                },
              },
              xaxis: getDislocationDensityGraphLabels(maxCoordinate, activeTab, "x"),
              yaxis: getDislocationDensityGraphLabels(maxCoordinate, activeTab, "y"),
              modebar: {
                orientation: "h",
                bgcolor: "#434446",
                color: "white",
              },
              shapes: activeTab === "histogram" ? chartShapes : [],
            }}
            config={{
              modebarbuttonstoremove: ["lasso2d"],
            }}
            useResizeHandler={true}
            style={{ width: "100%", height: "500px", marginTop: "-40px", marginBottom: "-10px" }}
            onClick={handleClick}
          />
        </div>
      </div>
    );
  } else if (inLineOperationInProgress) {
    return (
      <div className="flex justify-center h-52 items-center">
        <Loader type="Oval" color="#68d391" height={50} width={50} />
      </div>
    );
  } else {
    return <></>;
  }
};

export default DislocationDensityGraph;
