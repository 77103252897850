import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import toast from "react-hot-toast";
import { GET_DOWNLOAD, GET_INSTRUMENTAL_FILE_WAVELENGTH } from "../../../redux/project/projectActions";
import { selectDislocationDensityCH00, selectDislocationDensityCifFiles, selectDislocationDensityCifFilesABC, selectDislocationDensityCifTemplateFiles, selectDislocationDensityDataActiveFiles, selectDislocationDensityDataFiles, selectDislocationDensityInstrumentalFile, selectDislocationDensityInstrumentalFileWaveLength, selectDislocationDensityInstrumentalTemplateFile, selectDislocationDensityK2CLNA, selectDislocationDensityL0, selectDislocationDensityL0_selectionActive, selectDislocationDensityLN, selectDislocationDensityPartitionActiveIndex, selectDislocationDensityPartitionSelectingPeaks, selectDislocationDensityPartitions, selectDislocationDensityRHO, selectDislocationDensityRHO_selectionActive, selectDislocationDensityRhoAnalysis, selectDislocationDensityRhoPickerData, selectDislocationDensityTTH1, selectDislocationDensityTTH2, selectDislocationDensityWH, selectDislocationDensityWavelength } from "../../../redux/dislocationDensity/dislocationDensitySelectors";
import { FileSelection } from "../shared/file-selection/file-selection";
import { GET_DISLOCATION_DENSITY_DENSITY, GET_DISLOCATION_DENSITY_FITTING, GET_DISLOCATION_DENSITY_MWH, GET_DISLOCATION_DENSITY_SUBGRAIN_SIZE, SET_DISLOCATION_DENSITY_ACTIVE_INDEX, SET_DISLOCATION_DENSITY_CH00, SET_DISLOCATION_DENSITY_CIF_FILES, SET_DISLOCATION_DENSITY_CIF_FILES_ABC, SET_DISLOCATION_DENSITY_CIF_TEMPLATE_FILES, SET_DISLOCATION_DENSITY_DATA_FILES, SET_DISLOCATION_DENSITY_INSTRUMENTAL_FILE, SET_DISLOCATION_DENSITY_INSTRUMENTAL_TEMPLATE_FILE, SET_DISLOCATION_DENSITY_L0, SET_DISLOCATION_DENSITY_L0_PICKER_SELECTION, SET_DISLOCATION_DENSITY_LN, SET_DISLOCATION_DENSITY_PARTITIONS, SET_DISLOCATION_DENSITY_RHO, SET_DISLOCATION_DENSITY_RHO_PICKER_SELECTION, SET_DISLOCATION_DENSITY_SELECTING_PEAKS, SET_DISLOCATION_DENSITY_TTH1, SET_DISLOCATION_DENSITY_TTH2, SET_DISLOCATION_DENSITY_WAVELENGTH } from "../../../redux/dislocationDensity/dislocationDensityActions";
import { cifFilesDirectories } from "../../../data/cif_files";
import { instrumentalFilesDirectories } from "../../../data/instrumental_files";
import WavelengthInput from "../shared/wavelength";
import { useEffect } from "react";
import PeakPicking from "../shared/peak-picking";
import DropDown from "../shared/drop-down";
import { CH00 } from "../../../data/dislocation_density";
import { CifFileABC } from "../shared/cifFileABC";


const DislocationDensityInput = ({ dispatch  }: { dispatch: Dispatch }) => {
	const dataFiles = useSelector(selectDislocationDensityDataFiles);
	const dataActiveFiles = useSelector(selectDislocationDensityDataActiveFiles);
	const cifFiles = useSelector(selectDislocationDensityCifFiles);
	const cifTemplateFiles = useSelector(selectDislocationDensityCifTemplateFiles);
	const instrumentalFile = useSelector(selectDislocationDensityInstrumentalFile);
	const instrumentalFileTemplate = useSelector(selectDislocationDensityInstrumentalTemplateFile);
	const fileWaveLength = useSelector(selectDislocationDensityInstrumentalFileWaveLength);
	const wavelength = useSelector(selectDislocationDensityWavelength);
	const partitions = useSelector(selectDislocationDensityPartitions)
	const selectedPartition = useSelector(selectDislocationDensityPartitionActiveIndex)
	const selectingPeaks = useSelector(selectDislocationDensityPartitionSelectingPeaks)
	const selectCH00 = useSelector(selectDislocationDensityCH00);
	const tth1 = useSelector(selectDislocationDensityTTH1);
	const tth2 = useSelector(selectDislocationDensityTTH2);
	const numLValues = useSelector(selectDislocationDensityLN);
	const rhoSelectionActive = useSelector(selectDislocationDensityRHO_selectionActive);
	const l0SelectionActive = useSelector(selectDislocationDensityL0_selectionActive);
	const l0 = useSelector(selectDislocationDensityL0);
	const rho = useSelector(selectDislocationDensityRHO);
	const wh = useSelector(selectDislocationDensityWH);
	const mwhData = useSelector(selectDislocationDensityK2CLNA);
  const rhoPickerData = useSelector(selectDislocationDensityRhoPickerData);
  const rhoAnalysis = useSelector(selectDislocationDensityRhoAnalysis);
	const cifFilesABC = useSelector(selectDislocationDensityCifFilesABC);

	useEffect(() => {
		if (!instrumentalFile && !instrumentalFileTemplate) return;

		dispatch({ type: GET_INSTRUMENTAL_FILE_WAVELENGTH, payload: { type: "dislocationDensity" } });
	}, [dispatch, instrumentalFile, instrumentalFileTemplate]);

	return (
		<div className={"flex flex-col flex-1 bg-primary-800 rounded-md"}>
			<h1 className={"text-white mb-2 text-lg font-bold"}>Dislocation Density</h1>
			<div className={"p-2 flex flex-col bg-primary-600 mb-2 rounded-md w-full"}>
				<h1 className={"font-bold text-white text-base mb-2"}>
					Select Files
				</h1>
				<FileSelection
					dispatch={dispatch}
					selected={dataFiles}
					setSelected={files => dispatch({ type: SET_DISLOCATION_DENSITY_DATA_FILES, payload: files })}
					title="Select Data File(s)"
					extensions={[".dat"]}
					defaultExtension={".dat"}
					multiple={true}
				/>
				<br />
				<FileSelection
					dispatch={dispatch}
					selected={cifFiles}
					selectedTemplates={cifTemplateFiles}
					setSelected={files => dispatch({ type: SET_DISLOCATION_DENSITY_CIF_FILES, payload: files })}
					setSelectedTemplates={files => dispatch({ type: SET_DISLOCATION_DENSITY_CIF_TEMPLATE_FILES, payload: files })}
					title="Select Cif File(s)"
					extensions={[".cif"]}
					defaultExtension={".cif"}
					multiple={false}
					templates={cifFilesDirectories}
				/>
				<br />
				<FileSelection
					dispatch={dispatch}
					selected={instrumentalFile}
					selectedTemplates={instrumentalFileTemplate}
					setSelected={file => dispatch({ type: SET_DISLOCATION_DENSITY_INSTRUMENTAL_FILE, payload: file })}
					setSelectedTemplates={file => dispatch({ type: SET_DISLOCATION_DENSITY_INSTRUMENTAL_TEMPLATE_FILE, payload: file })}
					title="Select Instrumental File"
					extensions={[".instprm"]}
					defaultExtension=".instprm"
					multiple={false}
					templates={instrumentalFilesDirectories}
				/>
				<br />
			</div>
			<div className={"p-2 flex flex-col bg-primary-600 mb-2 rounded-md w-full"}>
				<h1 className={"font-bold text-white text-base mb-2"}>
					Inputs
				</h1>
				<WavelengthInput
					fileWaveLength={fileWaveLength}
					wavelength={wavelength}
					setWavelength={(wavelength: number) => dispatch({ type: SET_DISLOCATION_DENSITY_WAVELENGTH, payload: wavelength })}
				/>
				<CifFileABC
					dispatch={dispatch}
					cifFilesABC={cifFilesABC}
					cifFiles={cifFiles}
					cifTemplateFiles={cifTemplateFiles}
					analysisMethod={"dislocationDensity"}
					setABCValues={(abcValues) => dispatch({ type: SET_DISLOCATION_DENSITY_CIF_FILES_ABC, payload: abcValues })}
				/>
			</div>
			<PeakPicking
				setPartitions={(partitions) => dispatch({ type: SET_DISLOCATION_DENSITY_PARTITIONS, payload: partitions })}
				partitions={partitions}
				setActiveIndex={(index) => dispatch({ type: SET_DISLOCATION_DENSITY_ACTIVE_INDEX, payload: index })}
				selectedPartition={selectedPartition}
				setSelectingPeaks={(value) => dispatch({ type: SET_DISLOCATION_DENSITY_SELECTING_PEAKS, payload: value })}
				selectPeaks={selectingPeaks}
			/>
			<button
				className={"cursor-pointer text-white font-bold bg-primary-600 m-4 p-4 rounded"}
				onClick={() => {
					if (!cifFiles.length && !cifTemplateFiles.length) {
						toast.error("Please add at least one cif file");
						return;
					}

					if (!instrumentalFile && !instrumentalFileTemplate) {
						toast.error("Please add a instrumental file");
						return;
					}

					if (!dataActiveFiles.length) {
						toast.error("Please add at least one data file");
						return;
					}

					dispatch({ type: GET_DISLOCATION_DENSITY_FITTING })
				}}
			>
				Save and calculate
			</button>
			<div className="relative w-full">
				{!(wh?.x?.length) && (
					<div className="absolute inset-0 bg-primary-900 bg-opacity-80 cursor-not-allowed z-50"></div>
				)}
				<div className={`flex flex-col flex-1 bg-primary-800 rounded-md`}>
					<div className={"p-2 flex flex-col bg-primary-600 mb-2 rounded-md w-full"}>
						<h1 className={"font-bold text-white text-base mb-2"}>
							Modified Williamson Hall
						</h1>
						<div className={"flex items-center flex-col mb-2 mt-3 text-center"}>
							<DropDown
								DropDownOptions={CH00}
								setSelected={(value) => dispatch({ type: SET_DISLOCATION_DENSITY_CH00, payload: value })}
								selected={selectCH00}
								multiple={false}
								title={"Select CH00 Type"}
							/>
						</div>
						<div className={"flex flex-row items-center justify-center mt-1"}>
							<div className={"flex flex-col w-1/2 ml-5 mr-5"}>
								<label className={"text-white text-center"}>
									Peak Profile Start [2θ]
								</label>
								<input
									className={`${false ? "border-red-500" : "border-gray-500"}
										bg-primary-700 p-2 rounded-md border border-solid text-white w-full`}
									type={"number"}
									step="0.0001"
									onChange={(e) => {
										if (typeof e.target.value === "string") {
											dispatch({ type: SET_DISLOCATION_DENSITY_TTH1, payload: parseFloat(e.target.value) });
										} else {
											dispatch({ type: SET_DISLOCATION_DENSITY_TTH1, payload: parseFloat(e.target.value) });
										}
									}}
									value={tth1 ? tth1 : ""}
								/>
							</div>
						</div>
						<div className={"flex flex-row items-center justify-center mt-1"}>
							<div className={"flex flex-col w-1/2 ml-5 mr-5"}>
								<label className={"text-white text-center"}>
									Peak Profile End [2θ]
								</label>
								<input
									className={`${false ? "border-red-500" : "border-gray-500"}
										bg-primary-700 p-2 rounded-md border border-solid text-white w-full`}
									type={"number"}
									step="0.0001"
									onChange={(e) => {
										if (typeof e.target.value === "string") {
											dispatch({ type: SET_DISLOCATION_DENSITY_TTH2, payload: parseFloat(e.target.value) });
										} else {
											dispatch({ type: SET_DISLOCATION_DENSITY_TTH2, payload: parseFloat(e.target.value) });
										}
									}}
									value={tth2 ? tth2 : ""}
								/>
							</div>
						</div>
						<div className={"flex flex-row items-center justify-center mt-1"}>
							<div className={"flex flex-col w-1/2 ml-5 mr-5"}>
								<label className={"text-white text-center"}>
									Number of Fourier Variables
								</label>
								<input
									className={`${false ? "border-red-500" : "border-gray-500"}
										bg-primary-700 p-2 rounded-md border border-solid text-white w-full`}
									type={"number"}
									step="1"
									onChange={(e) => {
										if (typeof e.target.value === "string") {
											dispatch({ type: SET_DISLOCATION_DENSITY_LN, payload: parseFloat(e.target.value) });
										} else {
											dispatch({ type: SET_DISLOCATION_DENSITY_LN, payload: parseInt(e.target.value) });
										}
									}}
									value={numLValues ? numLValues : ""}
								/>
							</div>
						</div>
					</div>
					<button
						className={"w-11/12 cursor-pointer text-white font-bold bg-primary-600 m-4 p-4 rounded"}
						style={{ marginLeft: "20px"}}
						onClick={() => {
							dispatch({ type: GET_DISLOCATION_DENSITY_MWH })
						}}
					>
						Save and calculate
					</button>
				</div>
			</div>
			<div className="relative">
				{(!(wh?.x?.length) || !(mwhData.length)) && (
					<div className="absolute inset-0 bg-primary-900 bg-opacity-80 cursor-not-allowed z-50"></div>
				)}
				<div className={`flex flex-col flex-1 bg-primary-800 rounded-md ${(false && false)  ? "px-2" : ""}`}>
					<div className={"p-2 flex flex-col bg-primary-600 mb-2 rounded-md w-full"}>
						<h1 className={"font-bold text-white text-base mb-2"}>
							Subgrain Size Estimation
						</h1>
						<div className={"flex flex-row items-center justify-center mt-5"}>
							<div className={"flex flex-col items-center justify-center w-1/2 ml-5 mr-5"}>
								<label className={"text-white"}>
									Select Points
								</label>
								<button
									className={`cursor-pointer text-white font-bold bg-primary-500 p-2 rounded-md w-full ${l0SelectionActive ? "border-b-2 border-green-600" : ""}`}
									onClick={() => {
										dispatch({ type: SET_DISLOCATION_DENSITY_L0_PICKER_SELECTION, payload: !l0SelectionActive })
									}}
								>
									{l0SelectionActive ? "Stop Selecting Points" : "Start Selecting Points"}
								</button>
							</div>
						</div>
						<div className={"flex flex-row items-center justify-center mt-5"}>
							<div className={"flex flex-col items-center justify-center w-1/2 ml-5 mr-5"}>
								<label className={"text-white"}>
									Lower Point
								</label>
								<input
									className={`${false ? "border-red-500" : "border-gray-500"}
										bg-primary-700 p-2 rounded-md border border-solid text-white w-full`}
									type={"number"}
									step="0.001"
									value={
										l0[0] ? l0[0] : ""
									}
									onChange={(e) => {
										dispatch({ type: SET_DISLOCATION_DENSITY_L0, payload: e.target.value });
									}}
								/>
							</div>
							<div className={"flex flex-col items-center justify-center w-1/2 ml-5 mr-5"}>
								<label className={"text-white"}>
									Upper Point
								</label>
								<input
									className={`${false ? "border-red-500" : "border-gray-500"}
										bg-primary-700 p-2 rounded-md border border-solid text-white w-full`}
									type={"number"}
									step="0.01"
									value={
										l0[1] ? l0[1] : ""
									}
									onChange={(e) => {
										dispatch({ type: SET_DISLOCATION_DENSITY_L0, payload: e.target.value });
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				<button
					className={"cursor-pointer w-11/12 text-white font-bold bg-primary-600 m-4 p-4 rounded"}
					style={{ marginLeft: "20px"}}
					onClick={() => {
						dispatch({ type: GET_DISLOCATION_DENSITY_SUBGRAIN_SIZE })
					}}
				>
					Save and calculate
				</button>
			</div>
			<div className="relative">
				{(!(wh?.x?.length) || !(mwhData.length) || !(rhoPickerData?.x?.length)) && (
					<div className="absolute inset-0 bg-primary-900 bg-opacity-80 cursor-not-allowed z-50"></div>
				)}
				<div className={`flex flex-col flex-1 bg-primary-800 rounded-md`}>
					<div className={"p-2 flex flex-col bg-primary-600 rounded-md w-full"}>
						<h1 className={"font-bold text-white text-base mb-2"}>
							Dislocation Density: Modified Warren-Averbach
						</h1>
						<div className={"flex flex-row items-center justify-center mt-5"}>
							<div className={"flex flex-col items-center justify-center w-1/2 ml-5 mr-5"}>
								<label className={"text-white"}>
									Select Points
								</label>
								<button
									className={`cursor-pointer text-white font-bold bg-primary-500 p-2 rounded-md w-full ${rhoSelectionActive ? "border-b-2 border-green-600" : ""}`}
									onClick={() => {
										dispatch({ type: SET_DISLOCATION_DENSITY_RHO_PICKER_SELECTION, payload: !rhoSelectionActive })
									}}
								>
									{rhoSelectionActive ? "Stop Selecting Points" : "Start Selecting Points"}
								</button>
							</div>
						</div>
						<div className={"flex flex-row items-center justify-center mt-5"}>
							<div className={"flex flex-col items-center justify-center w-1/2 ml-5 mr-5"}>
								<label className={"text-white"}>
									Lower Point
								</label>
								<input
									className={`${false ? "border-red-500" : "border-gray-500"}
										bg-primary-700 p-2 rounded-md border border-solid text-white w-full`}
									type={"number"}
									step="0.001"
									value={
										rho[0] ? rho[0] : ""
									}
									onChange={(e) => {
										dispatch({ type: SET_DISLOCATION_DENSITY_RHO, payload: e.target.value });
									}}
								/>
							</div>
							<div className={"flex flex-col items-center justify-center w-1/2 ml-5 mr-5"}>
								<label className={"text-white"}>
									Upper Point
								</label>
								<input
									className={`${false ? "border-red-500" : "border-gray-500"}
										bg-primary-700 p-2 rounded-md border border-solid text-white w-full`}
									type={"number"}
									step="0.01"
									value={
										rho[1] ? rho[1] : ""
									}
									onChange={(e) => {
										dispatch({ type: SET_DISLOCATION_DENSITY_RHO, payload: e.target.value });
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				<button
					className={"cursor-pointer w-11/12 text-white font-bold bg-primary-600 m-4 p-4 rounded mt-6"}
					style={{ marginLeft: "20px"}}
					onClick={() => {
						dispatch({ type: GET_DISLOCATION_DENSITY_DENSITY })
					}}
				>
					Save and calculate
				</button>
				<div className="relative">
					<button
						className={`cursor-pointer w-11/12 ${rhoAnalysis.x.length ? "text-white bg-primary-600" : "text-gray-600 bg-primary-700 cursor-not-allowed"} font-bold m-4 p-4 rounded-md`}
						style={{ marginLeft: "20px"}}
						onClick={() => {
							dispatch({ type: GET_DOWNLOAD, payload: { type: "dislocationDensity" } })
						}}
					>
						Download Data
					</button>
				</div>
			</div>
		</div>
	);
};

export default DislocationDensityInput;
