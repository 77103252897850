import { Dispatch } from "redux";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import PeakFittingInput from "./peak-fitting-input";
import { compareKeysV2 } from "../../../helpers/general";
import { selectInstanceId, selectProgress, selectProgressMessage } from "../../../redux/general/generalSelectors";
import ProgressBar from "../shared/progress-bar";
import AnalysisFilesTable from "../shared/analysis-files-table";
import { GET_PEAK_FITTING_CALLBACK, SET_PEAK_FITTING_DATA_ACTIVE_FILES, SET_PEAK_FITTING_DATA_VIEW_FILE } from "../../../redux/peakFitting/peakFittingActions";
import { selectPeakFittingDataFiles, selectPeakFitting, selectPeakFittingDataViewFile, selectPeakFittingDataActiveFiles } from "../../../redux/peakFitting/peakFittingSelectors";
import PeakFittingGraph from "./peak-fitting-graph";
import { GET_INSTANCE_RUNNING_STATUS, GET_LAMBDA_CACHE, GET_METHOD_DATA } from "../../../redux/project/projectActions";
import { numberShortener } from "../../../helpers/name";

const PeakFittingAnalysis = ({ dispatch }: {dispatch: Dispatch}) => {
  const peakFittingDataFiles = useSelector(selectPeakFittingDataFiles);
  const peakFitting = useSelector(selectPeakFitting);
  const peakFittingDataViewFile = useSelector(selectPeakFittingDataViewFile);
  const instanceId = useSelector(selectInstanceId)
  const progress = useSelector(selectProgress)
  const statusMessage = useSelector(selectProgressMessage)
  const dataActiveFiles = useSelector(selectPeakFittingDataActiveFiles)

  const [filesInformation, setFilesInformation] = useState<any>([]);
	const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
		if (intervalId !== undefined) clearInterval(intervalId);

		if (instanceId !== "") {
			const newIntervalId = setInterval(async () => {
				dispatch({ type: GET_INSTANCE_RUNNING_STATUS, payload: { instanceId: instanceId, projectType: "peakFitting" } });
			}, 5000);

			setIntervalId(newIntervalId);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [instanceId]);

  useEffect(() => {
    dispatch({ type: GET_METHOD_DATA, payload: {type: "peakFitting"} })
    dispatch({ type: GET_LAMBDA_CACHE, payload: {callerType: "peakFitting"} })
  }, [dispatch])

  useEffect(() => {
    if (!peakFitting.dataViewFile) return;

    dispatch({ type: GET_PEAK_FITTING_CALLBACK })
  }, [dispatch, peakFitting.dataViewFile])

  useEffect(() => {
    let filesMapping = []

    for (const file of peakFittingDataFiles) {
      filesMapping.push({ fileName: file })
    }

    filesMapping = filesMapping.sort((a, b) => compareKeysV2(a.fileName, b.fileName))

    for (const key in peakFitting.fitting) {
      const fitting = peakFitting.fitting[key]
      const index = filesMapping.findIndex((file) => file.fileName === key)
      if (index !== -1) {
        let newItems = {}
        for (const key in fitting) {
          for (const innerKey in fitting[key]) {
            newItems[`${key}: ${innerKey}`] = numberShortener(fitting[key][innerKey])
          }
        }

        const sortedKeys = Object.keys(newItems).sort((a, b) => {
          const matchA = a.match(/^(\D+)(\d+)$/);
          const matchB = b.match(/^(\D+)(\d+)$/);
          if (matchA && matchB) {
            const letterCompare = matchA[1].localeCompare(matchB[1]);
            if (letterCompare !== 0) return letterCompare;
            return parseInt(matchA[2], 10) - parseInt(matchB[2], 10);
          }
          return 0;
        });

        const sortedNewItems = {};
        for (const sortedKey of sortedKeys) {
          sortedNewItems[sortedKey] = newItems[sortedKey];
        }

        filesMapping[index] = { ...filesMapping[index], ...sortedNewItems };
      }
    }

    setFilesInformation(filesMapping)
  }, [peakFittingDataFiles, dispatch, peakFitting.fitting])

  useEffect(() => {
    if (peakFittingDataFiles.length > 0 && !peakFittingDataViewFile) {
      const dataFiles = peakFittingDataFiles.sort((a, b) => compareKeysV2(a, b))
      dispatch({ type: SET_PEAK_FITTING_DATA_VIEW_FILE, payload: dataFiles[0] });
    }
  }, [peakFittingDataFiles, dispatch, peakFittingDataViewFile])

  return (
    <div className={"flex w-full gap-4 flex-1 xl:flex-col flex-grow"}>
      {instanceId &&
        <ProgressBar
          progress={progress}
          message={statusMessage}
          title={"Running Peak Fitting Analysis..."}
        />
      }
      <div className={"flex flex-col bg-primary-800 h-full rounded-md w-2/6"}>
        <div className={"p-2 flex flex-col border-b border-primary-900 border-solid overflow-y-auto"}>
          <PeakFittingInput dispatch={dispatch} />
        </div>
      </div>
      <div className={"flex flex-col flex-1 bg-primary-800 rounded-md w-2/3"}>
          <PeakFittingGraph dispatch={dispatch} />
          <AnalysisFilesTable
            data={filesInformation}
            dataViewFile={peakFittingDataViewFile}
            setDataViewFile={(file) => dispatch({type: SET_PEAK_FITTING_DATA_VIEW_FILE, payload: file })}
            setDataActiveFiles={(files) => dispatch({type: SET_PEAK_FITTING_DATA_ACTIVE_FILES, payload: files})}
            dataActiveFiles={dataActiveFiles}
          />
        </div>
    </div>
  );
};

export default PeakFittingAnalysis;
