import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { InstrumentalFileCreationModel } from "../../models/analysis.instrumentalFileCreation.model";
import { selectInstrumentalFileCreation } from "./instrumentalFileCreationSelectors";
import { selectCurrentProject, selectCurrentProjectFiles, selectUserToken } from "../project/projectSelectors";
import { ProjectFileModel } from "../../models/project.model";
import { SET_INLINE_OPERATION_IN_PROGRESS, SET_INSTANCE_ID, SET_IN_INLINE_OPERATION_IN_PROGRESS, SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, SET_PROGRESS, SET_PROGRESS_MESSAGE } from "../general/generalActions";
import { getInstrumentalFileCreation, getInstrumentalFileCreationCallback, getInstrumentalFileCreationHistogram } from "./instrumentalFileCreationHelpers";
import { GET_INSTRUMENTAL_FILE_CREATION, GET_INSTRUMENTAL_FILE_CREATION_CALLBACK, GET_INSTRUMENTAL_FILE_CREATION_HISTOGRAM, SET_INSTRUMENTAL_FILE_CREATION_ANALYSIS, SET_INSTRUMENTAL_FILE_CREATION_HISTOGRAM, SET_INSTRUMENTAL_FILE_CREATION_RANGE_INITIAL, SET_INSTRUMENTAL_FILE_CREATION_REFINEMENT_RANGE_LOWER, SET_INSTRUMENTAL_FILE_CREATION_REFINEMENT_RANGE_UPPER } from "./instrumentalFileCreationActions";
import toast from "react-hot-toast";
import { waitFiveSeconds } from "../../helpers/redux";

function* get_instrumental_file_creation_histogram() {
  const RETRY = 1;
  try {
    const instrumentalFileCreation: InstrumentalFileCreationModel = yield select(selectInstrumentalFileCreation);
    const projectFiles: ProjectFileModel[] = yield select(selectCurrentProjectFiles);

    if (((!instrumentalFileCreation.cifFile || (instrumentalFileCreation.cifFile && !instrumentalFileCreation.cifFile)) && !instrumentalFileCreation.cifTemplateFile) ||
      (!instrumentalFileCreation.instrumentalTemplateFile) || !instrumentalFileCreation.dataViewFile) {
      return;
    }

    if (!instrumentalFileCreation.histogram?.instrumental?.x?.length) {
      yield put({ type: SET_INLINE_OPERATION_IN_PROGRESS, payload: true });
    } else {
      yield put({ type: SET_IN_INLINE_OPERATION_IN_PROGRESS, payload: true });
    }

    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);

    const getHistogramWithRetry = function* () {
      let retryCount = RETRY;
      while (retryCount >= 0) {
        try {
          const dataFile = projectFiles.filter((x) => x.fileRef.includes(".dat"))[0];
          return yield call(getInstrumentalFileCreationHistogram,
            token,
            currentProject.userId,
            currentProject.projectId,
            instrumentalFileCreation.cifFile,
            instrumentalFileCreation.cifTemplateFile,
            instrumentalFileCreation.instrumentalTemplateFile,
            instrumentalFileCreation.dataViewFile ? instrumentalFileCreation.dataViewFile : dataFile.fileRef,
            instrumentalFileCreation.wavelength
          );
        } catch (error) {
          console.error(error)
          retryCount--;
        }
      }
      return null;
    };

    const res = yield call(getHistogramWithRetry);

    if (!res) {
      toast.error("Failed to load Histogram check inputs");
      return;
    }

    yield put({ type: SET_INSTRUMENTAL_FILE_CREATION_HISTOGRAM, payload: res.data });

    if (!instrumentalFileCreation.refinementRange.lower) {
      yield put({ type: SET_INSTRUMENTAL_FILE_CREATION_REFINEMENT_RANGE_LOWER, payload: Math.min(...res.data.instrumental.x) });
    }
    if (!instrumentalFileCreation.refinementRange.upper) {
      yield put({ type: SET_INSTRUMENTAL_FILE_CREATION_REFINEMENT_RANGE_UPPER, payload: Math.max(...res.data.instrumental.x) });
    }
    yield put({ type: SET_INSTRUMENTAL_FILE_CREATION_RANGE_INITIAL, payload: [Math.min(...res.data.instrumental.x), Math.max(...res.data.instrumental.x)] });

  } catch (error) {
    toast.error("Failed to load Histogram check inputs");
    console.error(error)
  } finally {
    yield put({ type: SET_IN_INLINE_OPERATION_IN_PROGRESS, payload: false });
    yield put({ type: SET_INLINE_OPERATION_IN_PROGRESS, payload: false });
  }
}

function* get_instrumental_file_creation() {
  try {
    const instrumentalFileCreation: InstrumentalFileCreationModel = yield select(selectInstrumentalFileCreation);    

    toast.promise(waitFiveSeconds(), {
      loading: "Starting Refinement",
      success: "Refinement Running",
      error: "Refinement Running",
    })

    yield put({ type: SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, payload: true });

    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);

    const res = yield getInstrumentalFileCreation(
      token,
      currentProject.userId,
      currentProject.projectId,
      instrumentalFileCreation.cifFile,
      instrumentalFileCreation.cifTemplateFile,
      instrumentalFileCreation.instrumentalTemplateFile,
      instrumentalFileCreation.dataFiles,
      instrumentalFileCreation.dataActiveFiles,
      instrumentalFileCreation.wavelength,
      instrumentalFileCreation.crystalliteSize,
      instrumentalFileCreation.microstrain,
      instrumentalFileCreation.refinementRange.lower,
      instrumentalFileCreation.refinementRange.upper,
    );

    yield put({ type: SET_INSTANCE_ID, payload: res.data.instanceId });
  } catch (error) {
    console.error(error)
    toast.error("Failed to Call API endpoint, check inputs")
  } finally {
    yield put({ type: SET_IN_INLINE_OPERATION_IN_PROGRESS, payload: false });
  }
}

function* get_instrumental_file_creation_callback() {
  try {
    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);
    const instrumentalFileCreation: InstrumentalFileCreationModel = yield select(selectInstrumentalFileCreation);    

    const res = yield getInstrumentalFileCreationCallback(
      token,
      currentProject.projectId,
      instrumentalFileCreation.dataViewFile
    )

    yield put({ type: SET_INSTRUMENTAL_FILE_CREATION_ANALYSIS, payload: res.data });
  } catch (error) {
    console.error(error)
    toast.error("Failed to load data")
  } finally {
    yield put({ type: SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, payload: false });

    yield put({ type: SET_INSTANCE_ID, payload: "" })
    yield put({ type: SET_PROGRESS, payload: 0 })
    yield put({ type: SET_PROGRESS_MESSAGE, payload: "Starting Instance" })
  }
}

export default function* instrumentalFileCreationSaga() {
  yield all([
    takeLatest(GET_INSTRUMENTAL_FILE_CREATION_HISTOGRAM, get_instrumental_file_creation_histogram),
    takeLatest(GET_INSTRUMENTAL_FILE_CREATION, get_instrumental_file_creation),
    takeLatest(GET_INSTRUMENTAL_FILE_CREATION_CALLBACK, get_instrumental_file_creation_callback),
  ]);
}
