import { PeakFittingModel } from "../../models/analysis.peakFitting.model";
import { SET_PEAK_FITTING_DATA_FILES, SET_PEAK_FITTING_CIF_FILES, SET_PEAK_FITTING_INSTRUMENTAL_FILE, SET_PEAK_FITTING_WAVELENGTH, SET_PEAK_FITTING_CIF_TEMPLATE_FILES, SET_PEAK_FITTING_INSTRUMENTAL_TEMPLATE_FILE, SET_PEAK_FITTING, SET_PEAK_FITTING_INSTRUMENTAL_WAVELENGTH, SET_PEAK_FITTING_DATA_VIEW_FILE, SET_PEAK_FITTING_RESET, SET_PEAK_FITTING_DATA_ACTIVE_FILES, SET_PEAK_FITTING_PARTITIONS, SET_PEAK_FITTING_ACTIVE_INDEX, SET_PEAK_FITTING_SELECTING_PEAKS, SET_PEAK_FITTING_HISTOGRAM, SET_PEAK_FITTING_PARAMETERS, SET_PEAK_FITTING_FITTING, SET_PEAK_FITTING_CIF_FILES_ABC } from "./peakFittingActions";

const initialState: PeakFittingModel = {
  dataFiles: [],
  dataActiveFiles: [],
  dataViewFile: "",
  cifFiles: [],
  cifFilesABC: {},
  instrumentalFile: "",
  wavelength: 0,
  templateCifFiles: [],
  templateInstrumentalFile: "",
  instrumentalFileWaveLength: 0,
  partitions: [{
    name: "1",
    rangeHistory: [0, 0],
    rangeLower: {
      x: 0,
      y: 0
    },
    rangeHigher: {
      x: 0,
      y: 0
    },
    peaks: [],
  }],
  partition: {
    activeIndex: 0,
    selectingPeaks: false,
  },
  movingPeaks: false,
  fitting: {},
  histogram: {
    instrumental: {
      x: [],
      y: []
    },
    phase: []
  },
  partitionFitting: {}
};

export default function peakFittingReducer(state = initialState, { type, payload }: { type: string; payload: any }): PeakFittingModel {
  switch (type) {
    case SET_PEAK_FITTING_DATA_FILES:
      return {
        ...state,
        dataFiles: payload,
      }
    case SET_PEAK_FITTING_CIF_FILES:
      return {
        ...state,
        cifFiles: payload,
      }
    case SET_PEAK_FITTING_INSTRUMENTAL_FILE:
      return {
        ...state,
        instrumentalFile: payload,
      }
    case SET_PEAK_FITTING_WAVELENGTH:
      return {
        ...state,
        wavelength: payload,
      }
    case SET_PEAK_FITTING_CIF_TEMPLATE_FILES:
      return {
        ...state,
        templateCifFiles: payload,
      }
    case SET_PEAK_FITTING_INSTRUMENTAL_TEMPLATE_FILE:
      return {
        ...state,
        templateInstrumentalFile: payload,
      }
    case SET_PEAK_FITTING:
      return {
        ...payload
      }
    case SET_PEAK_FITTING_INSTRUMENTAL_WAVELENGTH:
      return {
        ...state,
        instrumentalFileWaveLength: payload,
      }
    case SET_PEAK_FITTING_DATA_VIEW_FILE:
      return {
        ...state,
        dataViewFile: payload,
      }
    case SET_PEAK_FITTING_RESET:
      return {
        ...initialState
      }
    case SET_PEAK_FITTING_DATA_ACTIVE_FILES:
      return {
        ...state,
        dataActiveFiles: payload
      }
    case SET_PEAK_FITTING_PARTITIONS:
      return {
        ...state,
        partitions: payload
      }
    case SET_PEAK_FITTING_ACTIVE_INDEX:
      return {
        ...state,
        partition: {
          ...state.partition,
          activeIndex: payload
        }
      }
    case SET_PEAK_FITTING_SELECTING_PEAKS:
      return {
        ...state,
        partition: {
          ...state.partition,
          selectingPeaks: payload
        }
      }
    case SET_PEAK_FITTING_HISTOGRAM:
      return {
        ...state,
        histogram: payload
      }
    case SET_PEAK_FITTING_PARAMETERS:
      return {
        ...state,
        fitting: {
          ...payload
        }
      }
    case SET_PEAK_FITTING_FITTING:
      return {
        ...state,
        partitionFitting: {
          [payload.file]: payload.data
        }
      }
    case SET_PEAK_FITTING_CIF_FILES_ABC:
      return {
        ...state,
        cifFilesABC: {
          ...payload
        }
      }
    default:
      return state;
  }
}
