import { all, put, select, takeLatest } from "redux-saga/effects";
import { LeBailModel } from "../../models/analysis.leBail.model";
import { SET_INLINE_OPERATION_IN_PROGRESS, SET_INSTANCE_ID, SET_IN_INLINE_OPERATION_IN_PROGRESS, SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, SET_PROGRESS, SET_PROGRESS_MESSAGE } from "../general/generalActions";
import { SET_LE_BAIL_HISTOGRAM, SET_LE_BAIL_RANGE_LOWER, SET_LE_BAIL_RANGE_HIGHER, SET_LE_BAIL_RANGE_INITIAL, GET_LE_BAIL_HISTOGRAM, GET_LE_BAIL_REFINED_HISTOGRAM, GET_LE_BAIL_REFINE_HISTOGRAM_CALLBACK, SET_LE_BAIL_REFINED_HISTOGRAM } from "./leBailActions";
import toast from "react-hot-toast";
import { waitFiveSeconds } from "../../helpers/redux";
import { getLeBailHistogram, getLeBailRefinedHistogram, getLeBailRefinedHistogramCallback, preProcessObject } from "./leBailHelpers";
import { selectLeBail } from "./leBailSelectors";
import { selectCurrentProject, selectCurrentProjectFiles, selectUserToken } from "../project/projectSelectors";

function* get_le_bail_histogram() {
  let leBail: LeBailModel = yield select(selectLeBail);
  const token = yield select(selectUserToken);
  const currentProject = yield select(selectCurrentProject);

  let retry = 2;
  while (retry > 0) {
    try {

      if((!leBail.cifFiles.length && !leBail.cifTemplateFiles.length) ||
        (!leBail.instrumentalFile && !leBail.instrumentalTemplateFile) ||
        !leBail.dataViewFile
        ) {
        return;
      }

      if (!leBail.histogram?.instrumental?.x?.length)
        yield put({ type: SET_INLINE_OPERATION_IN_PROGRESS, payload: true });
      else
       yield put({ type: SET_IN_INLINE_OPERATION_IN_PROGRESS, payload: true })

      leBail = preProcessObject(leBail);

      const res = yield getLeBailHistogram(
        token,
        {
          ...leBail,
          userId: currentProject.userId,
          projectId: currentProject.projectId,
        }
      );

      yield put({ type: SET_LE_BAIL_HISTOGRAM, payload: res.data });
      
      if (!leBail.rangeLower) {
        yield put({ type: SET_LE_BAIL_RANGE_LOWER, payload: Math.min(...res.data.instrumental.x) });
      }
      if (!leBail.rangeHigher) {
        yield put({ type: SET_LE_BAIL_RANGE_HIGHER, payload: Math.max(...res.data.instrumental.x) });
      }
      yield put({ type: SET_LE_BAIL_RANGE_INITIAL, payload: [Math.min(...res.data.instrumental.x), Math.max(...res.data.instrumental.x)] });

      retry = 0;
    } catch (error) {
      retry--;
      if (retry === 0) {
        toast.error("Failed to load Histogram check inputs")
      }
    } finally {
      yield put({ type: SET_IN_INLINE_OPERATION_IN_PROGRESS, payload: false })
      yield put({ type: SET_INLINE_OPERATION_IN_PROGRESS, payload: false });
    }
  }
}

function* get_le_bail_refined_histogram_callback() {
  const token = yield select(selectUserToken);
  const currentProject = yield select(selectCurrentProject);
  const currentProjectFiles = yield select(selectCurrentProjectFiles);
  let leBail = yield select(selectLeBail);

  try {
    if (!leBail.dataViewFile) return
    
    leBail = preProcessObject(leBail);

    const res = yield getLeBailRefinedHistogramCallback(
      token, {
        ...leBail,
        userId: currentProject.userId,
        projectId: currentProject.projectId,
        projectFiles: currentProjectFiles.map((x) => x.fileRef),
      }
    );

    yield put({ type: SET_LE_BAIL_REFINED_HISTOGRAM, payload: res.data });
  } catch (error) {
    console.error(error)
    toast.error("Failed to load data")
  } finally {
    yield put({ type: SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, payload: false });

    yield put({ type: SET_INSTANCE_ID, payload: "" })
    yield put({ type: SET_PROGRESS, payload: 0 })
    yield put({ type: SET_PROGRESS_MESSAGE, payload: "Starting Instance" })
  }
}

function* get_le_bail_refined_histogram() {
  let leBail: LeBailModel = yield select(selectLeBail);    
  const token = yield select(selectUserToken);
  const currentProject = yield select(selectCurrentProject);

  try {

    toast.promise(waitFiveSeconds(), {
      loading: "Starting Refinement",
      success: "Refinement Running",
      error: "Refinement Running",
    })

    yield put({ type: SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, payload: true });

    leBail = preProcessObject(leBail);
    
    const res = yield getLeBailRefinedHistogram(
      token,
      {
        ...leBail,
        userId: currentProject.userId,
        projectId: currentProject.projectId,
      }
    );

    yield put({ type: SET_INSTANCE_ID, payload: res.data.instanceId });
  } catch (error) {
    console.error(error)
    toast.error("Failed to load Refined Histogram check inputs")
  } finally {
    yield put({ type: SET_IN_INLINE_OPERATION_IN_PROGRESS, payload: false });
  }
}

export default function* leBailSaga() {
  yield all([
    takeLatest(GET_LE_BAIL_HISTOGRAM, get_le_bail_histogram),
    takeLatest(GET_LE_BAIL_REFINED_HISTOGRAM, get_le_bail_refined_histogram),
    takeLatest(GET_LE_BAIL_REFINE_HISTOGRAM_CALLBACK, get_le_bail_refined_histogram_callback),
  ]);
}
