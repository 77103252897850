import { Dispatch } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faSquareCheck } from "@fortawesome/free-solid-svg-icons";
import { ProjectFileModel } from "../../../models/project.model";
import { useEffect, useState } from "react";
import { getName } from "../../../helpers/name";

export default function AnalysisTable({files, dispatch, fileSetter, filesDataPoints}: {files: ProjectFileModel[]; dispatch: Dispatch; fileSetter: (files: ProjectFileModel[]) => void; filesDataPoints: ProjectFileModel[] }) {
  const [fileHeaders, setFileHeaders] = useState([] as string[])
  
  const handleFileClick = (fileItem: ProjectFileModel) => {
    if (filesDataPoints.some((file) => file.fileRef === fileItem.fileRef)) {
      fileSetter(
        filesDataPoints.filter(
          (file) => file.fileRef !== fileItem.fileRef
        )
      );
    } else {
      fileSetter([...filesDataPoints, fileItem]);
    }
  };

  useEffect(()=>{
    const uniqueColumnHeadings = new Set<string>();
    const ignoredKeys = ['projectId', 'projectID', 'userId', 'updatedAt', 'fileKey', 'createdAt', 'bucket', 'fileRef', 'fullFileKey', 'uploadURL', 'leBailRefineHistogram'];

    if (!files) return;

    files.forEach(file => {
      Object.keys(file)
        .filter(key => !ignoredKeys.includes(key))
        .forEach(key => uniqueColumnHeadings.add(key));
    });

    setFileHeaders(Array.from(uniqueColumnHeadings))
  }, [files])

  return (
    <div className={"w-full overflow-x-auto flex-1"}>
      <table className={"table-auto text-xs w-full files-table overflow-x-auto border-collapse border-spacing-x-1"}>
        <thead>
          <tr
            className={
              "flex-2 p-2 overflow-hidden whitespace-nowrap overflow-ellipsis text-white text-center border-b border-gray-300"
            }
          >
            <th className={"cursor-pointer py-2 pt-4 pl-4 pr-4"}
              onClick={() => {
                if (filesDataPoints.length === files.length) {
                  fileSetter([]);
                } else {
                  fileSetter(files);
                }
              }}
            >
              {filesDataPoints.length === files.length ? (
                <FontAwesomeIcon
                  icon={faSquareCheck}
                  size="lg"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faSquare}
                  size="lg"
                />
              )}
            </th>
            <th className={"px-6 py-2"}>Data title</th>
            <th className={"px-6 py-2"}>Date added</th>
              {fileHeaders.map(key => (
                <th className="px-6 py-2" key={key}>{key}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {files.map((file, index) => {
              return (
                <tr
                  key={file.fileRef + index}
                  className={`${
                    filesDataPoints.some((fileItem) => file.fileRef === fileItem.fileRef)
                      ? "text-white"
                      : "text-primary-500"
                  } flex-1 p-2 overflow-hidden whitespace-nowrap overflow-ellipsis cursor-pointer text-center`}
                >
                  <td className={"px-6 py-1"} key={`${file.fileRef}-icon`} onClick={() => handleFileClick(file)} >
                    {filesDataPoints.some((fileItem) => fileItem.fileRef === file.fileRef) ? (
                      <FontAwesomeIcon icon={faSquareCheck} size="lg" />
                    ) : (
                      <FontAwesomeIcon icon={faSquare} size="lg" />
                    )}
                  </td>
                  <td className={"px-6 py-1"} key={`${file.fileRef}-fileName`}>
                    {getName(file.fileRef || file.fileRef)}
                  </td>
                  <td className={"px-6 py-1"} key={`${file.fileRef}-fileDate`}>
                    {new Date(
                      parseInt((file as any).createdAt)
                    ).toLocaleDateString()}
                  </td>
                  {fileHeaders.map(header => (
                    <td key={`${file.fileRef}-filedata-${header}`} className="px-4">
                      {file[header] || ''}
                    </td>
                  ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
