import Loader from "react-loader-spinner";
import Plot from "react-plotly.js";
import { useSelector } from "react-redux";
import { getInInInlineOperationInProgress, getInInlineOperationInProgress, getInlineOperationInProgress } from "../../../redux/general/generalSelectors";
import { Dispatch } from "redux";
import { useEffect, useState } from "react";
import { colorHistogram } from "../../../data/colors";
import { getName } from "../../../helpers/name";
import toast from "react-hot-toast";
import { selectLeBailDataFiles, selectLeBailDataViewFile, selectLeBailHistogram, selectLeBailRefinedHistogram } from "../../../redux/leBail/leBailSelectors";
import { GET_LE_BAIL_REFINE_HISTOGRAM_CALLBACK, SET_LE_BAIL_RANGE } from "../../../redux/leBail/leBailActions";


const LeBailGraph = ({ dispatch }: {dispatch: Dispatch}) => {
  const inLineOperationInProgress = useSelector(getInlineOperationInProgress);
  const inInlineOperationInProgress = useSelector(getInInlineOperationInProgress);
  const inInInlineOperationInProgress = useSelector(getInInInlineOperationInProgress);
	const dataFiles = useSelector(selectLeBailDataFiles);
	const dataViewFile = useSelector(selectLeBailDataViewFile);
	const refinedHistogram = useSelector(selectLeBailRefinedHistogram);
  const histogram = useSelector(selectLeBailHistogram);
  
  const [chartData, setChartData] = useState<any>([]);
  const [chartShapes, setChartShapes] = useState<any>([]);

  const handleClick = (event: any) => {
    if (event.points.length > 0) {
      dispatch({ type: SET_LE_BAIL_RANGE, payload: event.points[0].x });
      toast.success("Updated Range")
    }
  };

  useEffect(() => {
    let histogramData = []
    let refinedHistogramData = []
    let dataViewFileModify = dataViewFile.split("/").pop();

    if (histogram && histogram.instrumental) {
      histogramData = [
        {
          x: histogram.instrumental.x,
          y: histogram.instrumental.y,
          type: "linear",
          name: "Data",
          mode: "lines",
          line: {
            color: "#ffffff",
            width: 2,
          },
        },
        ...histogram.phase.map((d, index) => ({
          x: [histogram.instrumental.x[0]],
          y: [histogram.instrumental.y[0]],
          type: "linear",
          name: getName(d.name),
          mode: "lines",
          line: {
            color: colorHistogram[index],
            width: 2,
            dash: "dash",
          },
        })),
      ];

      setChartShapes(histogram.phase.flatMap((d, index) => (
        d.data.map((data) => {
          if (Array.isArray(data.x)) {
            return ({
              type: "line",
              x0: data.x[0],
              y0: 0,
              x1: data.x[0],
              y1: data.y_max * 1.2,
              opacity: 1,
              line: {
                dash: "dashdot",
                color: colorHistogram[index],
                width: 3,
              },
            })
          } else {
            return ({
              type: "line",
              x0: data.x,
              y0: 0,
              x1: data.x,
              y1: data.y_max * 1.2,
              opacity: 0.8,
              line: {
                dash: "dash",
                color: colorHistogram[index],
                width: 1.5,
              },
            })
          }
        })
      )));
    }

    if (dataFiles.length && refinedHistogram && refinedHistogram.calculations) {
      const index = refinedHistogram.calculations.findIndex((d) => d.name === dataViewFileModify);

      if (index !== -1 && refinedHistogram.calculations[index].y) {
        refinedHistogramData = [
          {
            x: refinedHistogram.calculations[index].x,
            y: refinedHistogram.calculations[index].y,
            name: "Calculations",
            type: "linear",
            mode: "lines",
            line: {
              color: "orange",
              width: 2,
            },
          },
          {
            x: refinedHistogram.background[index].x,
            y: refinedHistogram.background[index].y,
            name: "Background",
            type: "linear",
            mode: "lines",
            line: {
              color: "green",
              width: 2,
            },
          },
          {
            x: refinedHistogram.residual[index].x,
            y: refinedHistogram.residual[index].y,
            name: "Residual",
            type: "linear",
            mode: "lines",
            line: {
              color: "purple",
              width: 2,
            },
          },
        ];
      }
    }

    setChartData(
      [...histogramData, ...refinedHistogramData]
    );
  }, [dataFiles.length, dataViewFile, histogram, refinedHistogram]);

  useEffect(() => {
    dispatch({ type: GET_LE_BAIL_REFINE_HISTOGRAM_CALLBACK })
  }, [dispatch, dataViewFile]);

  if (!inLineOperationInProgress && chartData && chartData.length !== 0) {
    return (
      <div className="relative">
        {(inInlineOperationInProgress || inInInlineOperationInProgress) &&
          <div className={"absolute top-2 right-2"}>
            <Loader type="Oval" color="#68d391" height={25} width={25} />
          </div>
        }
        <div className={"flex"}>
        </div>
        <div className={"flex justify-around"}>
          <Plot
            data={chartData}
            layout={{
              autosize: true,
              paper_bgcolor: "rgba(0,0,0,0)",
              plot_bgcolor: "rgba(0,0,0,0)",
              legend: {
                orientation: "v",
                font: {
                  family: "sans-serif",
                  size: 12,
                  color: "white",
                },
              },
              xaxis: {
                color: "white",
                gridcolor: "grey",
                title: {
                  text: "2θ",
                  font: {
                    family: "sans-serif",
                    size: 16,
                    color: "white",
                  },
                },
              },
              yaxis: {
                color: "white",
                gridcolor: "grey",
                title: {
                  text: "intensity",
                  font: {
                    family: "sans-serif",
                    size: 16,
                    color: "white",
                  },
                },
              },
              modebar: {
                orientation: "h",
                bgcolor: "#434446",
                color: "white",
              },
              shapes: chartShapes,
            }}
            config={{
              modebarbuttonstoremove: ["lasso2d"],
            }}
            useResizeHandler={true}
            style={{ width: "100%", height: "500px", marginTop: "-12px", marginBottom: "-15px" }}
            onClick={handleClick}
          />
        </div>
      </div>
    );
  } else if (inLineOperationInProgress) {
    return (
      <div className="flex justify-center h-52 items-center">
        <Loader type="Oval" color="#68d391" height={50} width={50} />
      </div>
    );
  } else {
    return <></>;
  }
};

export default LeBailGraph;
