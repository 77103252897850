import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import Plot from "react-plotly.js";
import { useEffect, useState } from "react";
import { selectReportScreen } from "../../redux/project/projectSelectors";
import DropDown from "../analysis-settings/shared/drop-down";
import { ReportScreenColorMaps } from "../../models/report-screen.model";
import { SET_REPORT_SCREEN_HEATMAP_COLOR_MAP } from "../../redux/project/projectActions";
import { getHeatMapData, getLineChartData } from "./report-screen-analysis-graph/report-screen-individual-graph";
import { getLabelsForValues } from "../../helpers/name";


const ReportScreenGraph = ({ dispatch, graphType, setGraphType }: {dispatch: Dispatch, graphType: string, setGraphType: any}) => {
  const report = useSelector(selectReportScreen)

  const [plotData, setPlotData] = useState<any[]>([]);

  useEffect(() => {
    let newPlotData = [];
    if (graphType === "Line") {
      newPlotData = getLineChartData(report.selectedAnalysisType, report);
    } else {
      newPlotData = getHeatMapData(report.selectedAnalysisType, report);
    }
    setPlotData(newPlotData);
}, [graphType, report]);

  return (
    <>
      <div className={"flex justify-center items-center z-10 relative"}>
        <div className={"absolute left-0"}>
          <div className="tools ml-2 text-xs rounded">
            <DropDown
              DropDownOptions={ReportScreenColorMaps}
              title={report.heatMap.colorMap ? `Color Map: ${getLabelsForValues(ReportScreenColorMaps, report.heatMap.colorMap)}` : "Color Map"}
              setSelected={(value: string) => dispatch({ type: SET_REPORT_SCREEN_HEATMAP_COLOR_MAP, payload: value })}
              selected={report.heatMap.colorMap}
              multiple={false}
              padding={"p-2"}
              height="350px"
              width="w-48"
              closeOnClick={true}
            />
            </div>
        </div>
        <div className={"flex flex-row justify-center items-center z-10"}>
          <button
            className={`${graphType === "Line" ? "bg-primary-700" : "bg-primary-600"} w-24  pt-1 pb-1 pr-2 pl-2 rounded-l-md text-white cursor-pointer`}
            onClick={() => setGraphType("Line")}
          >
            Line Chart
          </button>
          <button
            className={`${graphType === "Heatmap" ? "bg-primary-700" : "bg-primary-600"} w-24  pt-1 pb-1 pr-2 pl-2 rounded-r-md text-white cursor-pointer`}
            onClick={() => setGraphType("Heatmap")}
          >
            Heat Map
          </button>
        </div>
      </div>
      <div className={"flex justify-around"}>
        <Plot
          data={plotData}
          layout={{
            autosize: true,
            paper_bgcolor: "rgba(0,0,0,0)",
            plot_bgcolor: "rgba(0,0,0,0)",
            legend: {
              orientation: "v",
              font: {
                family: "Arial, sans-serif",
                size: 14,
                color: "#EDEDED",
              },
              x: 1, // this will place the legend outside the plot
              y: 0.5,
            },
            xaxis: {
              color: "#EDEDED",
              gridcolor: "#5A5A5A",
              title: {
                text: graphType === "Line" ? "Data Files" : "X Axis",
                font: {
                  family: "Arial, sans-serif",
                  size: 18,
                  color: "#EDEDED",
                },
              },
            },
            yaxis: {
              color: "#EDEDED",
              gridcolor: "#5A5A5A",
              title: {
                text: graphType === "Line" ? "Values" : "Y Axis",
                font: {
                  family: "Arial, sans-serif",
                  size: 18,
                  color: "#EDEDED",
                },
              },
            },
            modebar: {
              orientation: "h",
              bgcolor: "#333335",
              color: "#EDEDED",
              font: {
                family: "Arial, sans-serif",
                size: 14,
                color: "#EDEDED",
              }
            },
            hovermode: "closest",
          }}
          config={{
            modebarbuttonstoremove: ["lasso2d"],
            displaylogo: false,
            responsive: true,
          }}
          useResizeHandler={true}
          style={{ width: "100%", height: "500px", marginTop: "-20px" }}
        />
      </div>
    </>
  )
};

export default ReportScreenGraph;
