import { getName } from "../../../helpers/name";
import { ReportScreenModel } from "../../../models/report-screen.model";

export const getLeBailLineData = (report: ReportScreenModel) => {
  const newPlotData: {
    x: string[];
    y: number[];
    mode: string;
    name: string;
  }[] = [];

  report.leBailReport.cifFiles.lineChart.forEach((cifFile) => {
    report.leBailReport.yAxis.lineChart.forEach((yAxisValue) => {
      const xValues: string[] = [];
      const yValues: number[] = [];

      report.dataViewFiles.forEach((dataViewFile) => {
        const dataFileRef = dataViewFile.fileRef;
        if (report.leBailReport.analysis[dataFileRef]) {
          const analysisData = report.leBailReport.analysis[dataFileRef][cifFile];
          if (analysisData) {
            xValues.push(getName(dataFileRef));
            yValues.push(analysisData[yAxisValue as keyof typeof analysisData]);
          }
        }
      });

      newPlotData.push({
        x: xValues,
        y: yValues,
        mode: 'lines',
        name: `${getName(cifFile)} - ${getName(yAxisValue)}`,
      });
    });
  });

  return newPlotData;
}


export const getLeBailHeatMapData = (report: ReportScreenModel) => {
  const newPlotData = [];

  const heatMapWidth = report.heatMap.x;
  const heatMapHeight = report.heatMap.y;

  if (!heatMapWidth || !heatMapHeight) {
    return [];
  }

  const z: number[][] = new Array(heatMapHeight).fill(null).map(() => new Array(heatMapWidth).fill(undefined));
  const hoverText: string[][] = new Array(heatMapHeight).fill(null).map(() => new Array(heatMapWidth).fill(''));

  const cifFile = report.leBailReport.cifFiles.heatMap;
  const yAxisValue = report.leBailReport.yAxis.heatMap

  const maxItems = heatMapWidth * heatMapHeight;

  report.dataViewFiles.slice(0, maxItems).forEach((dataViewFile, xIndex) => {
    const dataFileRef = dataViewFile.fileRef;
    if (report.leBailReport.analysis[dataFileRef]) {
      const analysisData = report.leBailReport.analysis[dataFileRef][cifFile];
      if (analysisData) {
        const value = analysisData[yAxisValue as keyof typeof analysisData];

        const x = xIndex % heatMapWidth;
        const y = Math.floor(xIndex / heatMapWidth);
        const newY = (heatMapHeight - 1) - y;

        let reversedX = x;

        if (report.swipeMode.swipe) {
          if (report.swipeMode.swipeRowEven) {
            if (newY % 2 === 0) {
              reversedX = (heatMapWidth - 1) - x;
            }
          } else {
            if (newY % 2 !== 0) {
              reversedX = (heatMapWidth - 1) - x;
            }
          }
        }

        z[newY][reversedX] = value;

        hoverText[newY][reversedX] = getName(dataFileRef);
      }
    }
  });

  newPlotData.push({
    z: z,
    type: 'heatmap',
    text: hoverText,
    hoverinfo: 'z+text',
    colorbar: {
      tickfont: {
        color: 'white'
      }
    },
    colorscale: report.heatMap.colorMap
  });

  return newPlotData;
}