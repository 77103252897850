import { getName, truncate } from "../../../helpers/name";
import { LabelsMappingPeakFitting, ReportScreenModel } from "../../../models/report-screen.model";


export const getDislocationDensityLineData = (report: ReportScreenModel) => {
  const newPlotData = [];

  report.dislocationDensityReport.yAxis.lineChart.forEach((chartTypeSelected) => {
    const xValues: string[] = [];
    const yValues = [];

    report.dataViewFiles.forEach((dataViewFile) => {
      let parts = dataViewFile.fileRef.split('/');
      let dataFileRef = parts[parts.length - 1].split('.')[0];

      if (report.dislocationDensityReport.whParams[dataFileRef]) {
        const analysisData = report.dislocationDensityReport.whParams[dataFileRef];
        Object.entries(analysisData).forEach(([param, paramValue]) => {
          if (chartTypeSelected === param) {
            xValues.push(getName(dataFileRef));
            yValues.push(paramValue.nominal_value);
          }
        });
        if (chartTypeSelected === "rhoWH") {
          xValues.push(getName(dataFileRef));
          yValues.push(report.dislocationDensityReport.whParams[dataFileRef].rho.nominal_value);
        }
      }

      if (report.dislocationDensityReport.mwhParams[dataFileRef]) {
        const analysisData = report.dislocationDensityReport.mwhParams[dataFileRef] ;
        Object.entries(analysisData).forEach(([param, paramValue]) => {
          if (chartTypeSelected === param) {
            xValues.push(getName(dataFileRef));
            yValues.push(paramValue.nominal_value);
          }
        });
        if (chartTypeSelected === "rhoMWH") {
          xValues.push(getName(dataFileRef));
          yValues.push(report.dislocationDensityReport.whParams[dataFileRef].rho.nominal_value);
        }
      }
    });

    newPlotData.push({
      x: xValues,
      y: yValues,
      mode: 'lines',
      name: truncate(chartTypeSelected, 20),
    });
  });

  report.dislocationDensityReport.yAxis.lineChart.forEach((chartTypeSelected) => {
    const xValues: string[] = [];
    const yValues = [];

    report.dataViewFiles.forEach((dataViewFile) => {
      const dataFileRef = dataViewFile.fileRef;
      if (report.dislocationDensityReport.analysis[dataFileRef]) {
        const analysisData = report.dislocationDensityReport.analysis[dataFileRef];
        Object.entries(analysisData).forEach(([peak, peakValues]) => {
          Object.entries(peakValues).forEach(([key, value]) => {
            const chartType = `${peak}_${key}`;
            if (!key.endsWith('_std_dev') && chartTypeSelected === chartType) {
              if (LabelsMappingPeakFitting[key]) {
                xValues.push(getName(dataFileRef));
                yValues.push(value);
              }
            }
          });
        });
      }
    });

    newPlotData.push({
      x: xValues,
      y: yValues,
      mode: 'lines',
      name: truncate(chartTypeSelected, 20),
    });
  });

  return newPlotData;
}


export const getDislocationDensityHeatMapData = (report: ReportScreenModel) => {
  const newPlotData = [];

  const heatMapWidth = report.heatMap.x;
  const heatMapHeight = report.heatMap.y;

  if (!heatMapWidth || !heatMapHeight) return [];

  const z: number[][] | string[][] = new Array(heatMapHeight).fill(null).map(() => new Array(heatMapWidth).fill(undefined));
  const hoverText: string[][] = new Array(heatMapHeight).fill(null).map(() => new Array(heatMapWidth).fill(''));
  const maxItems = heatMapWidth * heatMapHeight; 

  report.dataViewFiles.slice(0, maxItems).forEach((dataViewFile, xIndex) => {
    const dataFileRef = dataViewFile.fileRef;
    if (report.dislocationDensityReport.analysis[dataFileRef]) {
      const analysisData = report.dislocationDensityReport.analysis[dataFileRef];
      Object.entries(analysisData).forEach(([peak, peakValues]) => {
        Object.entries(peakValues).forEach(([key, value]) => {
          const chartType = `${peak}_${key}`;
          if (key.endsWith('_std_dev') || report.dislocationDensityReport.yAxis.heatMap !== chartType) return;
          const x = xIndex % heatMapWidth;
          const y = Math.floor(xIndex / heatMapWidth);
          const newY = (heatMapHeight - 1) - y;
          
          let reversedX = x;

          if (report.swipeMode.swipe) {
            if (report.swipeMode.swipeRowEven) {
              if (newY % 2 === 0) {
                reversedX = (heatMapWidth - 1) - x;
              }
            } else {
              if (newY % 2 !== 0) {
                reversedX = (heatMapWidth - 1) - x;
              }
            }
          }

          z[newY][reversedX] = value;
          hoverText[newY][reversedX] = getName(dataFileRef);
        })
      })
    }
  });

  report.dataViewFiles.slice(0, maxItems).forEach((dataViewFile, xIndex) => {
    let parts = dataViewFile.fileRef.split('/');
    let dataFileRef = parts[parts.length - 1].split('.')[0];
    if (report.dislocationDensityReport.whParams[dataFileRef]) {
      const analysisData = report.dislocationDensityReport.whParams[dataFileRef];
      Object.entries(analysisData).forEach(([param, paramValue]) => {
        if (report.dislocationDensityReport.yAxis.heatMap !== param && !(report.dislocationDensityReport.yAxis.heatMap === "rhoWH" && param === "rho")) return;

        const x = xIndex % heatMapWidth;
        const y = Math.floor(xIndex / heatMapWidth);
        const newY = (heatMapHeight - 1) - y;
        
        let reversedX = x;

        if (report.swipeMode.swipe) {
          if (report.swipeMode.swipeRowEven) {
            if (newY % 2 === 0) {
              reversedX = (heatMapWidth - 1) - x;
            }
          } else {
            if (newY % 2 !== 0) {
              reversedX = (heatMapWidth - 1) - x;
            }
          }
        }

        z[newY][reversedX] = paramValue.nominal_value;
        hoverText[newY][reversedX] = getName(dataFileRef);
      })
    }
  });

  report.dataViewFiles.slice(0, maxItems).forEach((dataViewFile, xIndex) => {
    let parts = dataViewFile.fileRef.split('/');
    let dataFileRef = parts[parts.length - 1].split('.')[0];
    if (report.dislocationDensityReport.mwhParams[dataFileRef]) {
      const analysisData = report.dislocationDensityReport.mwhParams[dataFileRef];
      Object.entries(analysisData).forEach(([param, paramValue]) => {
        if (report.dislocationDensityReport.yAxis.heatMap !== param && !(report.dislocationDensityReport.yAxis.heatMap === "rhoMWH" && param === "rho")) return;

        const x = xIndex % heatMapWidth;
        const y = Math.floor(xIndex / heatMapWidth);
        const newY = (heatMapHeight - 1) - y;
        
        let reversedX = x;

        if (report.swipeMode.swipe) {
          if (report.swipeMode.swipeRowEven) {
            if (newY % 2 === 0) {
              reversedX = (heatMapWidth - 1) - x;
            }
          } else {
            if (newY % 2 !== 0) {
              reversedX = (heatMapWidth - 1) - x;
            }
          }
        }

        z[newY][reversedX] = paramValue.nominal_value;
        hoverText[newY][reversedX] = getName(dataFileRef);
      })
    }
  });

  newPlotData.push({
    z: z,
    type: 'heatmap',
    text: hoverText,
    hoverinfo: 'z+text',
    colorbar: {
      tickfont: {
        color: 'white'
      }
    },
    colorscale: report.heatMap.colorMap
  });

  return newPlotData;
}