import { Dispatch } from "redux";
import LeBailGraph from "./le-bail-graph";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import LeBailInput from "./le-bail-input";
import { numberShortener } from "../../../helpers/name";
import AnalysisFilesTable from "../shared/analysis-files-table";
import { compareKeys } from "../../../helpers/general";
import { SET_LE_BAIL_DATA_ACTIVE_FILES, SET_LE_BAIL_DATA_VIEW_FILE } from "../../../redux/leBail/leBailActions";
import { selectLeBailDataActiveFiles, selectLeBailDataFiles, selectLeBailDataViewFile, selectLeBailRefinedHistogram } from "../../../redux/leBail/leBailSelectors";
import ProgressBar from "../shared/progress-bar";
import { selectInstanceId, selectProgress, selectProgressMessage } from "../../../redux/general/generalSelectors";
import { FormatName } from "../../shared/utils";
import { GET_LAMBDA_CACHE, GET_METHOD_DATA } from "../../../redux/project/projectActions";

const LeBailAnalysis = ({ dispatch }: {dispatch: Dispatch}) => {
  const leBailRefineHistogram = useSelector(selectLeBailRefinedHistogram);
  const leBailDataFiles = useSelector(selectLeBailDataFiles)
  const leBailDataViewFile = useSelector(selectLeBailDataViewFile)
  const instanceId = useSelector(selectInstanceId)
  const progress = useSelector(selectProgress)
  const statusMessage = useSelector(selectProgressMessage)
  const dataActiveFiles = useSelector(selectLeBailDataActiveFiles)
  
  const [filesInformation, setFilesInformation] = useState<any>([]);

  useEffect(() => {
    let filesMapping = []

    for (const file of leBailDataFiles) {
      filesMapping.push({ fileName: file })
    }

    filesMapping = filesMapping.sort((a, b) => compareKeys(a.fileName, b.fileName))
    if (filesMapping.length > 0 && !leBailDataViewFile) dispatch({ type: SET_LE_BAIL_DATA_VIEW_FILE, payload: filesMapping[0].fileName });

    const leBailRefinedPhases = leBailRefineHistogram ? leBailRefineHistogram.phases : [];

    if (leBailRefinedPhases) {
      for (const fileObject of filesMapping) {
        for (const phase of leBailRefinedPhases) {
          if (phase.name !== fileObject.fileName) continue
          for (const phase_d of phase.data) {
            let cifName = FormatName(phase_d.cifName, false);

            fileObject[`${cifName} LengthA`] = numberShortener(phase_d.lengthA);
            fileObject[`${cifName} LengthB`] = numberShortener(phase_d.lengthB);
            fileObject[`${cifName} LengthC`] = numberShortener(phase_d.lengthC);
          }
        }
      }
    }

    setFilesInformation(filesMapping)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leBailDataFiles, dispatch, leBailRefineHistogram])

  useEffect(() => {
		dispatch({ type: GET_METHOD_DATA, payload: {type: "leBail"} });
    dispatch({ type: GET_LAMBDA_CACHE, payload: {callerType: "leBail"} })
	}, [dispatch]);

  return (
    <div className={"flex w-full gap-4 flex-1 xl:flex-col flex-grow"}>
      {instanceId &&
        <ProgressBar
          progress={progress}
          message={statusMessage}
          title={"Running Refinement"}
        />
      }
      <div className={"flex flex-col bg-primary-800 rounded-md w-2/6 xl:w-full overflow-y-auto resize-x hover:resize min-w-min"}>
        <div className={"p-2 flex flex-col border-b border-primary-900 border-solid"}>
          <LeBailInput dispatch={dispatch} />
        </div>
      </div>
      <div className={"flex flex-col flex-1 bg-primary-800 rounded-md w-2/3"}>
        <LeBailGraph dispatch={dispatch} />
        <AnalysisFilesTable
          data={filesInformation}
          dataViewFile={leBailDataViewFile}
          setDataViewFile={(file) => dispatch({type: SET_LE_BAIL_DATA_VIEW_FILE, payload: file})}
          setDataActiveFiles={(files) => dispatch({type: SET_LE_BAIL_DATA_ACTIVE_FILES, payload: files})}
          dataActiveFiles={dataActiveFiles}
        />
      </div>
    </div>
  );
};

export default LeBailAnalysis;
