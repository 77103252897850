import { Dispatch } from "redux"
import { useEffect } from "react";
import { GET_CIF_FILE_ABC_VALUE } from "../../../redux/project/projectActions";
import { FormatName } from "../../shared/utils";

interface Props {
  dispatch: Dispatch;
  cifFilesABC: {
    [cifFile: string]: {
      a: number;
      b: number;
      c: number;
      allTheSame: boolean;
    };
  };
  cifFiles: string[] | string;
  cifTemplateFiles: string[] | string;
  analysisMethod: string;
  setABCValues: (abcValues: { [cifFile: string]: { a: number, b: number, c: number, allTheSame: boolean } }) => void;
}


export const CifFileABC = ({ dispatch, cifFilesABC, cifFiles, cifTemplateFiles, analysisMethod, setABCValues }: Props) => {
  useEffect(() => {
    const normalizedCifFiles = Array.isArray(cifFiles) ? cifFiles : cifFiles ? [cifFiles] : [];
    const normalizedCifTemplateFiles = Array.isArray(cifTemplateFiles) ? cifTemplateFiles : cifTemplateFiles ? [cifTemplateFiles] : [];
    
    const newCifFilesABC = { ...cifFilesABC };
    const combinedCif = [...normalizedCifFiles, ...normalizedCifTemplateFiles];
    let newValues = false;

    combinedCif.forEach((cifFile) => {
      if (!newCifFilesABC[cifFile]) {
        dispatch({ type: GET_CIF_FILE_ABC_VALUE, payload: { cifFile: cifFile, type: analysisMethod } });
        newValues = true;
      }
    });
    
    Object.keys(newCifFilesABC).forEach((cifFile) => {
      if (!combinedCif.includes(cifFile)) {
        delete newCifFilesABC[cifFile];
        newValues = true;
      }
    });

    if (newValues) setABCValues(newCifFilesABC);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cifFiles, cifTemplateFiles, dispatch]);

  const handleInputChange = (cifFile: string, key: 'a' | 'b' | 'c', value: number) => {
    setABCValues({ ...cifFilesABC, [cifFile]: { ...cifFilesABC[cifFile], [key]: value }});
  };

  return (
    <>
      <h1 className={"font-bold text-white text-base mt-4"}>Cif Files ABC Values</h1>
      {Object.entries(cifFilesABC).map(([cifFile, values]) => (
        <div
          key={cifFile}
          className={"p-2 flex flex-col bg-primary-600 mb-2 rounded-md mx-1 text-white"}
        >
          <h2 className={"text-white text-base"}>{FormatName(cifFile, false).length ? FormatName(cifFile, false) : cifFile}</h2>
          <div className={"flex flex-col items-left"}>
            {values.allTheSame ? (
              <div className={"p-2 flex flex-col w-1/3 items-center"}>
                <p>All values (a, b, c) are the same</p>
                <input
                  className={
                    "bg-primary-700 p-2 rounded-md border border-solid border-gray-500 text-white w-full"
                  }
                  type={"number"}
                  step="0.01"
                  onChange={(event) =>
                    handleInputChange(cifFile, "a", parseFloat(event.target.value))
                  }
                  value={values.a}
                />
              </div>
            ) : (
              <div className={"flex flex-row justify-between w-full"}>
                {["a", "b", "c"].map((key) => (
                  <div className={"flex flex-col w-1/3 items-center mx-2"} key={key}>
                    <p>{key.toUpperCase()}</p>
                    <input
                      className={
                        "bg-primary-700 p-2 rounded-md border border-solid border-gray-500 text-white w-full"
                      }
                      type={"number"}
                      step="0.01"
                      onChange={(event) =>
                        handleInputChange(
                          cifFile,
                          key as "a" | "b" | "c",
                          parseFloat(event.target.value)
                        )
                      }
                      value={values[key as "a" | "b" | "c"]}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      ))}
    </>
  );
};