// SETTERS
export const SET_LE_BAIL_DATA_FILES = "SET_LE_BAIL_DATA_FILES";
export const SET_LE_BAIL_DATA_ACTIVE_FILES = "SET_LE_BAIL_DATA_ACTIVE_FILES";
export const SET_LE_BAIL_DATA_VIEW_FILE = "SET_LE_BAIL_DATA_VIEW_FILE"

export const SET_LE_BAIL_CIF_FILES = "SET_LE_BAIL_CIF_FILES";
export const SET_LE_BAIL_CIF_TEMPLATE_FILES = "SET_LE_BAIL_CIF_TEMPLATE_FILES";
export const SET_LE_BAIL_CIF_FILES_ABC = "SET_LE_BAIL_CIF_FILES_ABC";

export const SET_LE_BAIL_INSTRUMENTAL_FILE = "SET_LE_BAIL_INSTRUMENTAL_FILE";
export const SET_LE_BAIL_INSTRUMENTAL_TEMPLATE_FILE = "SET_LE_BAIL_INSTRUMENTAL_TEMPLATE_FILE";

export const SET_LE_BAIL_INSTRUMENTAL_WAVELENGTH = "SET_LE_BAIL_INSTRUMENTAL_WAVELENGTH";
export const SET_LE_BAIL_WAVELENGTH = "SET_LE_BAIL_WAVELENGTH";

export const SET_LE_BAIL_RANGE = "SET_LE_BAIL_RANGE";
export const SET_LE_BAIL_RANGE_LOWER = "SET_LE_BAIL_RANGE_LOWER"
export const SET_LE_BAIL_RANGE_HIGHER = "SET_LE_BAIL_RANGE_HIGHER"
export const SET_LE_BAIL_RANGE_INITIAL = "SET_LE_BAIL_RANGE_INITIAL"

export const SET_LE_BAIL_HISTOGRAM = "SET_LE_BAIL_HISTOGRAM";

export const SET_LE_BAIL_REFINED_HISTOGRAM = "SET_LE_BAIL_REFINED_HISTOGRAM";

export const SET_LE_BAIL = "SET_LE_BAIL"
export const SET_LE_BAIL_RESET = "SET_LE_BAIL_RESET"

export const SET_LE_BAIL_CYCLES = "SET_LE_BAIL_CYCLES"

// GETTERS
export const GET_LE_BAIL_REFINED_HISTOGRAM = "GET_LE_BAIL_REFINED_HISTOGRAM";
export const GET_LE_BAIL_REFINE_HISTOGRAM_CALLBACK = "GET_LE_BAIL_REFINE_HISTOGRAM_CALLBACK"
export const GET_LE_BAIL_HISTOGRAM = "GET_LE_BAIL_HISTOGRAM";
