import { LeBailModel } from "../../models/analysis.leBail.model";
import { SET_LE_BAIL_DATA_FILES, SET_LE_BAIL_CIF_FILES, SET_LE_BAIL_INSTRUMENTAL_FILE, SET_LE_BAIL_WAVELENGTH, SET_LE_BAIL_RANGE, SET_LE_BAIL_RANGE_LOWER, SET_LE_BAIL_RANGE_HIGHER, SET_LE_BAIL_HISTOGRAM, SET_LE_BAIL_INSTRUMENTAL_TEMPLATE_FILE, SET_LE_BAIL_CIF_TEMPLATE_FILES, SET_LE_BAIL_REFINED_HISTOGRAM, SET_LE_BAIL, SET_LE_BAIL_CYCLES, SET_LE_BAIL_DATA_VIEW_FILE, SET_LE_BAIL_RANGE_INITIAL, SET_LE_BAIL_RESET, SET_LE_BAIL_INSTRUMENTAL_WAVELENGTH, SET_LE_BAIL_DATA_ACTIVE_FILES, SET_LE_BAIL_CIF_FILES_ABC } from "./leBailActions";

const initialState: LeBailModel = {
  dataFiles: [],
  dataActiveFiles: [],
  cifFiles: [],
  cifFilesABC: {},
  instrumentalFile: "",
  wavelength: 0,
  rangeHistory: [0, 0],
  rangeLower: 0,
  rangeHigher: 0,
  histogram: {
    instrumental: {
      x: [],
      y: []
    },
    phase: []
  },
  refinedHistogram: {
    phases: [],
    background: [],
    calculations: [],
    residual: [],
  },
  cifTemplateFiles: [],
  instrumentalTemplateFile: "",
  instrumentalFileWaveLength: 0,
  dataViewFile: "",
  cycles: 5,
  rangeInitial: [0, 0],
};

export default function leBailReducer(state = initialState, { type, payload }: { type: string; payload: any }): LeBailModel {
  switch (type) {
    case SET_LE_BAIL_DATA_FILES: {
      return {
        ...state,
        dataFiles: payload,
      }
    }
    case SET_LE_BAIL_CIF_FILES: {
      return {
        ...state,
        cifFiles: payload,
      }
    }
    case SET_LE_BAIL_INSTRUMENTAL_FILE: {
      return {
        ...state,
        instrumentalFile: payload,
      }
    }
    case SET_LE_BAIL_WAVELENGTH: {
      return {
        ...state,
        wavelength: payload,
      }
    }
    case SET_LE_BAIL_RANGE: {
      const range = state.rangeHistory;
      range[1] = range[0];
      range[0] = payload;

      return {
        ...state,
        rangeHistory: range,
        rangeLower: range[0] <= range[1] ? range[0] : range[1],
        rangeHigher: range[0] > range[1] ? range[0] : range[1],
      }
    }
    case SET_LE_BAIL_RANGE_LOWER: {
      return {
        ...state,
        rangeLower: payload
      }
    }
    case SET_LE_BAIL_RANGE_HIGHER: {
      return {
        ...state,
        rangeHigher: payload
      }
    }
    case SET_LE_BAIL_HISTOGRAM: {
      return {
        ...state,
        histogram: payload,
      }
    }
    case SET_LE_BAIL_INSTRUMENTAL_TEMPLATE_FILE: {
      return {
        ...state,
        instrumentalTemplateFile: payload,
      }
    }
    case SET_LE_BAIL_CIF_TEMPLATE_FILES: {
      return {
        ...state,
        cifTemplateFiles: payload,
      }
    }
    case SET_LE_BAIL_REFINED_HISTOGRAM: {
      return {
        ...state,
        refinedHistogram: payload,
      }
    }
    case SET_LE_BAIL: {
      return {
        ...payload,
      }
    }
    case SET_LE_BAIL_DATA_VIEW_FILE:
      return {
        ...state,
        dataViewFile: payload,
      }
    case SET_LE_BAIL_CYCLES:
      return {
        ...state,
        cycles: payload,
      }
    case SET_LE_BAIL_RANGE_INITIAL:
      return {
        ...state,
        rangeInitial: payload,
      }
    case SET_LE_BAIL_RESET:
      return {
        ...initialState,
      }
    case SET_LE_BAIL_INSTRUMENTAL_WAVELENGTH:
      return {
        ...state,
        instrumentalFileWaveLength: payload
      }
    case SET_LE_BAIL_DATA_ACTIVE_FILES:
      return {
        ...state,
        dataActiveFiles: payload,
      }
    case SET_LE_BAIL_CIF_FILES_ABC:
      return {
        ...state,
        cifFilesABC: {
          ...payload
        },
      }
    default:
      return state;
  }
}
