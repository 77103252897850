import { ReportScreenModel } from "../../../models/report-screen.model";
import { getDislocationDensityHeatMapData, getDislocationDensityLineData } from "./report-screen-dislocation-density-graph";
import { getLeBailLineData, getLeBailHeatMapData } from "./report-screen-le-bail-graph";
import { getPeakFittingHeatMapData, getPeakFittingLineData } from "./report-screen-peak-fitting-graph";
import { getPhaseQuantificationLineData, getPhaseQuantificationHeatMapData } from "./report-screen-phase-quantification-graph";


export const getLineChartData = (reportType: string, report: ReportScreenModel) => {
  switch (reportType) {
    case "leBail":
      return getLeBailLineData(report);
    case "phaseQuantification":
      return getPhaseQuantificationLineData(report);
    case "dislocationDensity":
      return getDislocationDensityLineData(report);
    case "peakFitting":
      return getPeakFittingLineData(report);
    default:
      return [];
  }
}

export const getHeatMapData = (reportType: string, report: ReportScreenModel) => {
  switch (reportType) {
    case "leBail":
      return getLeBailHeatMapData(report);
    case "phaseQuantification":
      return getPhaseQuantificationHeatMapData(report);
    case "dislocationDensity":
      return getDislocationDensityHeatMapData(report);
    case "peakFitting":
      return getPeakFittingHeatMapData(report);
    default:
      return [];
  }
}
