import Loader from "react-loader-spinner";
import Plot from "react-plotly.js";
import { useSelector } from "react-redux";
import { getInInlineOperationInProgress, getInlineOperationInProgress } from "../../../redux/general/generalSelectors";
import { useEffect, useState } from "react";
import { Dispatch } from "redux";
import toast from "react-hot-toast";
import { colorHistogram, colorsGraph, colorsGraphMix } from "../../../data/colors";
import { getName } from "../../../helpers/name";
import { selectPhaseQuantificationDataViewFile, selectPhaseQuantificationPartitionActiveIndex, selectPhaseQuantificationPartitionSelectingPeaks, selectPhaseQuantificationPartitions, selectPhaseQuantificationHistogram, selectPhaseQuantificationPartitionFitting } from "../../../redux/phaseQuantification/phaseQuantificationSelectors";
import { SET_PHASE_QUANTIFICATION_PARTITIONS } from "../../../redux/phaseQuantification/phaseQuantificationActions";

const PhaseQuantificationGraph = ({dispatch }: { dispatch: Dispatch }) => {
  const inLineOperationInProgress = useSelector(getInlineOperationInProgress)
  const inInlineOperationInProgress = useSelector(getInInlineOperationInProgress)
  const dataViewFile = useSelector(selectPhaseQuantificationDataViewFile);
  const selectedPartitionIndex = useSelector(selectPhaseQuantificationPartitionActiveIndex);
  const selectPeaks = useSelector(selectPhaseQuantificationPartitionSelectingPeaks);
  const partitions = useSelector(selectPhaseQuantificationPartitions);
  const histogram = useSelector(selectPhaseQuantificationHistogram);
  const partitionFitting = useSelector(selectPhaseQuantificationPartitionFitting);

  const [activeTab, setActiveTab] = useState("histogram")
  const [chartFitting, setChartFitting] = useState<{ [key: string]: any[] }>({});
  const [chartShapes, setChartShapes] = useState<any>([]);
  const [reload, setReload] = useState(false);
  const [maxCoordinate, setMaxCoordinate] = useState<any>({
    xLower: undefined,
    xHigher: undefined,
    yMax: undefined,
    yMin: undefined,
  });

  useEffect(() => {
    if (selectPeaks) {
      const rangeLowerX = partitions[selectedPartitionIndex].rangeLower.x;
      const rangeHigherX = partitions[selectedPartitionIndex].rangeHigher.x;
      const range = rangeHigherX - rangeLowerX;
      const xLower = rangeLowerX - (range * 0.1);
      const xHigher = rangeHigherX + (range * 0.1);

      // get the max and lower y values in the range
      let yMax = 0;
      let yMin = 0;

      if (histogram.instrumental.x && histogram.instrumental.y) {
        const indexLower = histogram.instrumental.x.indexOf(rangeLowerX);
        const indexHigher = histogram.instrumental.x.indexOf(rangeHigherX);

        yMax = Math.max(...histogram.instrumental.y.slice(indexLower, indexHigher));
        yMin = Math.min(...histogram.instrumental.y.slice(indexLower, indexHigher));

        yMin -= (yMax - yMin) * 0.05;
        yMax += (yMax - yMin) * 0.05;
      }

      setMaxCoordinate({
        xLower,
        xHigher,
        yMax,
        yMin,
      });
    } else {
      setMaxCoordinate({
        xLower: undefined,
        xHigher: undefined,
        yMax: undefined,
        yMin: undefined,
      });
    }
  }, [selectPeaks, partitions, selectedPartitionIndex, reload, histogram.instrumental.x, histogram.instrumental.y]);

  const handleClick = (event: any) => {
    if (event.points.length > 0) {
      const data = event.points[0];

      if (selectPeaks) {
        if (!partitions[selectedPartitionIndex].rangeLower.x || !partitions[selectedPartitionIndex].rangeHigher.x) {
          toast.error("Please select a range first")
          return;
        }

        if (data.x < partitions[selectedPartitionIndex].rangeLower || data.x > partitions[selectedPartitionIndex].rangeHigher) {
          toast.error("Please select a point within the range")
          return;
        }

        partitions[selectedPartitionIndex].peaks.push({
          x: data.x,
          y: data.y,
          function: "",
          includeInAnalysis: false,
          constraint: {
            centerMin: undefined,
            centerMax: undefined,
            sigmaMin: undefined,
            sigmaMax: undefined,
            heightMin: undefined,
            heightMax: undefined,
          }
        })

        toast.success("Selected a Peak")
      } else {
        const range = partitions[selectedPartitionIndex].rangeHistory
        range[1] = range[0];
        range[0] = data.x;

        partitions[selectedPartitionIndex] = {
          ...partitions[selectedPartitionIndex],
          rangeHistory: range,
          rangeLower: range[0] <= range[1] ? {
            x: range[0],
            y: 0
          } : {
            x: range[1],
            y: 0
          },
          rangeHigher: range[0] > range[1] ? {
            x: range[0],
            y: 0
          } : {
            x: range[1],
            y: 0
          }
        }
        
        toast.success("Updated Partition Range")
      }

    dispatch({ type: SET_PHASE_QUANTIFICATION_PARTITIONS, payload: partitions });
    }

    setReload(!reload);
  };

  useEffect(() => {
    let histogramData = []
    let chartShapesCurrent = []
    let traces = [];

    if (histogram.instrumental.x && histogram.instrumental.y) {
      let new_x, new_y;

      // Gets data within the selected range
      if (partitions[selectedPartitionIndex].rangeLower && partitions[selectedPartitionIndex].rangeLower.x && partitions[selectedPartitionIndex].rangeHigher && partitions[selectedPartitionIndex].rangeHigher.x) {
        new_x = histogram.instrumental.x.filter((x) => x >= partitions[selectedPartitionIndex].rangeLower.x && x <= partitions[selectedPartitionIndex].rangeHigher.x);
        new_y = histogram.instrumental.y.slice(histogram.instrumental.x.indexOf(new_x[0]), histogram.instrumental.x.indexOf(new_x[new_x.length - 1]) + 1);
      } else {
        new_x = histogram.instrumental.x;
        new_y = histogram.instrumental.y;
      }

      histogramData = [
        {
          x: histogram.instrumental.x,
          y: histogram.instrumental.y,
          type: "linear",
          name: "Data",
          mode: "lines",
          line: {
            color: "rgb(180, 180, 180)",
            width: 1,
          },
        },
        {
          x: new_x,
          y: new_y,
          type: "linear",
          name: "Data",
          mode: "lines",
          line: {
            color: colorsGraph[0],
            width: 2,
          },
        },
        ...histogram.phase.map((d, index) => ({
          x: [histogram.instrumental.x[0]],
          y: [histogram.instrumental.y[0]],
          type: "linear",
          name: getName(d.name),
          mode: "lines",
          line: {
            color: colorHistogram[index],
            width: 2,
            dash: "dash",
          },
        })),
      ];

      chartShapesCurrent = histogram.phase.flatMap((d, index) => (
        d.data.map((data) => {
          if (Array.isArray(data.x)) {
            return ({
              type: "line",
              x0: data.x[0],
              y0: 0,
              x1: data.x[0],
              y1: data.y_max * 1.2,
              opacity: 1,
              line: {
                dash: "dashdot",
                color: colorHistogram[index],
                width: 3,
              },
            })
          } else {
            return ({
              type: "line",
              x0: data.x,
              y0: 0,
              x1: data.x,
              y1: data.y_max * 1.2,
              opacity: 0.8,
              line: {
                dash: "dash",
                color: colorHistogram[index],
                width: 1.5,
              },
            })
          }
        })
      ));

      partitions.forEach((partition, index) => {
        const trace = {
          x: [],
          y: [],
          mode: 'markers',
          marker: {
            color: colorHistogram[index],
            size: 10,
          },
          name: `Partition ${partition.name} Peak`,
        };

        partition.peaks.forEach((position) => {
          trace.x.push(position.x);
          trace.y.push(position.y);
        });

        traces.push(trace)
      });
    }


    let partitionFittingRes = partitionFitting && Object.keys(partitionFitting).length ? partitionFitting[dataViewFile] : undefined;

    let currentChartShapes = {}

    if (partitionFittingRes) {
      for (const key in partitionFittingRes) {
        let colorIndex = 0;

        const currentPartitionFitting = partitionFittingRes[key];

        const data = [
          {
            x: currentPartitionFitting.originalData.x,
            y: currentPartitionFitting.originalData.y,
            type: "linear",
            name: "Data",
            mode: "lines",
            line: {
              color: colorsGraphMix[colorIndex],
              width: 2,
              dash: 'dot',
            },
          },
          {
            x: currentPartitionFitting.bestFit.x,
            y: currentPartitionFitting.bestFit.y,
            type: "linear",
            name: "Best Fit",
            mode: "lines",
            line: {
              color: colorsGraphMix[colorIndex + 1],
              width: 2,
            },
          },
          {
            x: currentPartitionFitting.background.x,
            y: currentPartitionFitting.background.y,
            type: "linear",
            name: "Background",
            mode: "lines",
            line: {
              color: colorsGraphMix[colorIndex + 2],
              width: 2,
            },
          },
        ];

        colorIndex += 3

        if (currentPartitionFitting.components.gaussian.length) {
          currentPartitionFitting.components.gaussian.forEach((gaussian) => {
            data.push({
              x: gaussian.x,
              y: gaussian.y,
              type: "linear",
              mode: "lines",
              line: {
                color: colorsGraphMix[colorIndex],
                width: 2,
              },
              name: "Gaussian",
            })
          })
          colorIndex++;
        }

        if (currentPartitionFitting.components.lorentzian.length) {
          currentPartitionFitting.components.lorentzian.forEach(lorentzian => {
            data.push({
              x: lorentzian.x,
              y: lorentzian.y,
              type: "linear",
              name: "Lorentzian",
              mode: "lines",
              line: {
                color: colorsGraphMix[colorIndex],
                width: 1,
              },
            })
          })
          colorIndex++;
        }
        if (currentPartitionFitting.components.pseudoVoigt.length) {
          currentPartitionFitting.components.pseudoVoigt.forEach(pseudoVoigt => {
            data.push({
              x: pseudoVoigt.x,
              y: pseudoVoigt.y,
              type: "linear",
              name: "Pseudo Voigt",
              mode: "lines",
              line: {
                color: colorsGraphMix[colorIndex],
                width: 1,
              },
            })
          })
          colorIndex++;
        }
        if (currentPartitionFitting.components.pearson7.length) {
          currentPartitionFitting.components.pearson7.forEach(pearson7 => {
            data.push({
              x: pearson7.x,
              y: pearson7.y,
              type: "linear",
              name: "Pearson 7",
              mode: "lines",
              line: {
                color: colorsGraphMix[colorIndex],
                width: 1,
              },
            })
          })
          colorIndex++;
        }

        currentChartShapes[key] = data;
      }
    }

    currentChartShapes["histogram"] = [...histogramData, ...traces];

    setChartFitting(
      { ...currentChartShapes }
    );

    setChartShapes(
      [...chartShapesCurrent]
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataViewFile, histogram, partitionFitting, partitions, reload, selectedPartitionIndex]);

  if (!inLineOperationInProgress && (chartFitting && Object.keys(chartFitting).length !== 0)) {
    return (
      <div className="relative flex flex-col justify-center items-center">
        {(inInlineOperationInProgress || inInlineOperationInProgress) &&
          <div className={"absolute top-2 right-2"}>
            <Loader type="Oval" color="#68d391" height={25} width={25} />
          </div>
        }
        <div className={"flex justify-center items-center z-10"}>
          <div className={"flex flex-row justify-center items-center z-10"}>
            <button
              className={`${activeTab === "histogram" ? "bg-primary-700" : "bg-primary-600"} w-24  pt-1 pb-1 pr-2 pl-2 ${Object.keys(chartFitting).length !== 1 ? "rounded-l-md" : "rounded-md"} text-white cursor-pointer`}
              onClick={() => setActiveTab("histogram")}
            >
              Histogram
            </button>
            {chartFitting &&
              Object.keys(chartFitting).map((value, index) => {
                if (value === "histogram") return null;

                return (
                  <button
                  key={index}
                  className={`w-24 ${activeTab === value ? "bg-primary-700" : "bg-primary-600"} pt-1 pb-1 pr-2 pl-2 text-white cursor-pointer ${
                    index === Object.keys(chartFitting).length - 2 ? 'rounded-r-md' : ''
                  }`}
                  onClick={() => setActiveTab(value)}
                >
                  Partition {parseInt(value) + 1}
                </button>
                )
              })
            }
          </div>
        </div>
        <div className={"flex justify-around w-full"}>
          <Plot
            data={
              chartFitting[activeTab] ? chartFitting[activeTab] : []
            }
            layout={{
              autosize: true,
              paper_bgcolor: "rgba(0,0,0,0)",
              plot_bgcolor: "rgba(0,0,0,0)",
              legend: {
                orientation: "v",
                font: {
                  family: "sans-serif",
                  size: 12,
                  color: "white",
                },
              },
              xaxis: {
                color: "white",
                gridcolor: "grey",
                title: {
                  text: "2θ",
                  font: {
                    family: "sans-serif",
                    size: 16,
                    color: "white",
                  },
                },
                range: activeTab === "histogram" ? [maxCoordinate.xLower, maxCoordinate.xHigher] : undefined,
              },
              yaxis: {
                color: "white",
                gridcolor: "grey",
                title: {
                  text: "intensity",
                  font: {
                    family: "sans-serif",
                    size: 16,
                    color: "white",
                  },
                },
                range: activeTab === "histogram" ? [maxCoordinate.yMin, maxCoordinate.yMax] : undefined,
              },
              modebar: {
                orientation: "h",
                bgcolor: "#434446",
                color: "white",
              },
              shapes: activeTab === "histogram" ? chartShapes : [],
            }}
            config={{
              modebarbuttonstoremove: ["lasso2d"],
            }}
            useResizeHandler={true}
            style={{ width: "100%", height: "500px", marginTop: "-40px", marginBottom: "-10px" }}
            onClick={handleClick}
          />
        </div>
      </div>
    );
  } else if (inLineOperationInProgress) {
    return (
      <div className="flex justify-center h-52 items-center">
        <Loader type="Oval" color="#68d391" height={50} width={50} />
      </div>
    );
  } else {
    return <></>;
  }
};

export default PhaseQuantificationGraph;
