import { Dispatch } from "redux";
import LegacySystem from "./old/legacy-system";
import LeBailAnalysis from "./le-bail/le-bail";
import AzimuthalIntegrationAnalysis from "./azimuthal-integration/azimuthal-integration";
import InstrumentalFileCreationAnalysis from "./instrumental-file-creation/instrumental-file-creation";
import DataCalibration from "./data-calibration/data-calibration";
import RietveldAnalysis from "./rietveld/rietveld";
import { GET_PROJECT_FILES } from "../../redux/project/projectActions";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCurrentProjectFiles } from "../../redux/project/projectSelectors";
import PeakFittingAnalysis from "./peak-fitting/peak-fitting";
import PhaseQuantificationAnalysis from "./phase-quantification/phase-quantification";
import DislocationDensityAnalysis from "./dislocation-density/dislocation-density";
import Pipeline from "./pipeline/pipeline";

const AnalysisBridge = ({type, dispatch}: {type: string; dispatch: Dispatch;}) => {
  const currentProjectFiles = useSelector(selectCurrentProjectFiles)
  const getAnalysisModel = (lambda_name: string) => {
    switch (lambda_name) {
      case "le_bail":
        return <LeBailAnalysis dispatch={dispatch} />;
      case "phase_quantification":
        return <PhaseQuantificationAnalysis dispatch={dispatch} />;
      case "dislocation_density":
        return <DislocationDensityAnalysis dispatch={dispatch} />;
      case "azimuthal_integration":
        return <AzimuthalIntegrationAnalysis dispatch={dispatch} />;
      case "instrumental_file_creation":
        return <InstrumentalFileCreationAnalysis dispatch={dispatch} />;
      case "data_calibration":
        return <DataCalibration dispatch={dispatch} />;
      case "rietveld":
        return <RietveldAnalysis dispatch={dispatch} />;
      case "peak_fitting":
        return <PeakFittingAnalysis dispatch={dispatch} />;
      case "azimuthal_integration_phase_quantification":
        return <Pipeline dispatch={dispatch} pipelineType="azimuthalIntegration-phaseQuantification" />;
      case "azimuthal_integration_stress_analysis":
        return <Pipeline dispatch={dispatch} pipelineType="azimuthalIntegration-stressAnalysis" />;
      default:
        return <LegacySystem dispatch={dispatch} type={lambda_name} />;
    }
  }

  useEffect(() => {
    if (currentProjectFiles.length === 0) dispatch({ type: GET_PROJECT_FILES });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div className={"flex w-full gap-4 flex-1 xl:flex-col"} style={{ height: "65%" }}>
      {getAnalysisModel(type)}
    </div>
  );
};

export default AnalysisBridge;
