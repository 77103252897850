import { Dispatch } from "redux";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { compareKeysV2 } from "../../../helpers/general";
import { selectInstanceId, selectProgress, selectProgressMessage } from "../../../redux/general/generalSelectors";
import ProgressBar from "../shared/progress-bar";
import AnalysisFilesTable from "../shared/analysis-files-table";
import { selectDislocationDensityDataFiles, selectDislocationDensityFitting, selectDislocationDensityDataViewFile, selectDislocationDensityDataActiveFiles, selectDislocationDensityMwhParams, selectDislocationDensityWhParams } from "../../../redux/dislocationDensity/dislocationDensitySelectors";
import { GET_INSTANCE_RUNNING_STATUS, GET_LAMBDA_CACHE, GET_METHOD_DATA } from "../../../redux/project/projectActions";
import { GET_DISLOCATION_DENSITY_CALLBACK, SET_DISLOCATION_DENSITY_DATA_ACTIVE_FILES, SET_DISLOCATION_DENSITY_DATA_VIEW_FILE } from "../../../redux/dislocationDensity/dislocationDensityActions";
import DislocationDensityGraph from "./dislocation-density-graph";
import DislocationDensityInput from "./dislocation-density-input";
import { dislocationDensityFileInformation } from "./dislocation-density-helpers";

const DislocationDensityAnalysis = ({ dispatch }: {dispatch: Dispatch}) => {
  const dislocationDensityDataFiles = useSelector(selectDislocationDensityDataFiles);
  const dislocationDensityDataViewFile = useSelector(selectDislocationDensityDataViewFile);
  const instanceId = useSelector(selectInstanceId)
  const progress = useSelector(selectProgress)
  const statusMessage = useSelector(selectProgressMessage)
  const dataActiveFiles = useSelector(selectDislocationDensityDataActiveFiles)
  const mwhParams = useSelector(selectDislocationDensityMwhParams);
  const whParams = useSelector(selectDislocationDensityWhParams);
  const fitting = useSelector(selectDislocationDensityFitting);

  const [filesInformation, setFilesInformation] = useState<any>([]);
	const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>(undefined);
  const [headersFileInformation, setHeadersFileInformation] = useState<any>([]);

  useEffect(() => {
		if (intervalId !== undefined) clearInterval(intervalId);

		if (instanceId !== "") {
			const newIntervalId = setInterval(async () => {
				dispatch({ type: GET_INSTANCE_RUNNING_STATUS, payload: { instanceId: instanceId, projectType: "dislocationDensity" } });
			}, 5000);

			setIntervalId(newIntervalId);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [instanceId]);

  useEffect(() => {
    dispatch({ type: GET_METHOD_DATA, payload: {type: "dislocationDensity"} })
    dispatch({ type: GET_LAMBDA_CACHE, payload: {callerType: "dislocationDensity"} })
  }, [dispatch])

  useEffect(() => {
    if (!dislocationDensityDataViewFile) return;

    dispatch({ type: GET_DISLOCATION_DENSITY_CALLBACK })
  }, [dispatch, dislocationDensityDataViewFile])

  useEffect(() => {
    const fileInformation = dislocationDensityFileInformation(dislocationDensityDataFiles, whParams, mwhParams, fitting)

    setHeadersFileInformation(fileInformation.headers)
    setFilesInformation(fileInformation.filesMapping)
  }, [dislocationDensityDataFiles, dispatch, fitting, whParams, mwhParams])

  useEffect(() => {
    if (dislocationDensityDataFiles.length > 0 && !dislocationDensityDataViewFile) {
      const dataFiles = dislocationDensityDataFiles.sort((a, b) => compareKeysV2(a, b))
      dispatch({ type: SET_DISLOCATION_DENSITY_DATA_VIEW_FILE, payload: dataFiles[0] });
    }
  }, [dislocationDensityDataFiles, dispatch, dislocationDensityDataViewFile])

  return (
    <div className={"flex w-full gap-4 flex-1 xl:flex-col flex-grow"}>
      {instanceId &&
        <ProgressBar
          progress={progress}
          message={statusMessage}
          title={"Running Dislocation Density..."}
        />
      }
      <div className={"flex flex-col bg-primary-800 h-full rounded-md w-2/6"}>
        <div className={"p-2 flex flex-col border-b border-primary-900 border-solid overflow-y-auto"}>
          <DislocationDensityInput dispatch={dispatch} />
        </div>
      </div>
      <div className={"flex flex-col flex-1 bg-primary-800 rounded-md w-2/3"}>
          <DislocationDensityGraph dispatch={dispatch} />
          <AnalysisFilesTable
            data={filesInformation}
            dataViewFile={dislocationDensityDataViewFile}
            setDataViewFile={(file) => dispatch({type: SET_DISLOCATION_DENSITY_DATA_VIEW_FILE, payload: file })}
            setDataActiveFiles={(files) => dispatch({type: SET_DISLOCATION_DENSITY_DATA_ACTIVE_FILES, payload: files})}
            dataActiveFiles={dataActiveFiles}
            headersOrdered={headersFileInformation}
          />
        </div>
    </div>
  );
};

export default DislocationDensityAnalysis;
