import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import projectReducer from "../redux/project/projectReducer";
import generalReducer from "../redux/general/generalReducer";
import leBailReducer from "../redux/leBail/leBailReducer";
import phaseQuantificationReducer from "../redux/phaseQuantification/phaseQuantificationReducer";
import dislocationDensityReducer from "../redux/dislocationDensity/dislocationDensityReducer";
import azimuthalIntegrationReducer from "../redux/azimuthalIntegration/azimuthalIntegrationReducer";
import instrumentalFileCreationReducer from "../redux/instrumentalFileCreation/instrumentalFileCreationReducer";
import dataCalibrationReducer from "../redux/dataCalibration/dataCalibrationReducer";
import rietveldReducer from "../redux/rietveld/rietveldReducer";
import peakFittingReducer from "../redux/peakFitting/peakFittingReducer";

const createRootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    projectReducer,
    generalReducer,
    leBailReducer,
    phaseQuantificationReducer,
    dislocationDensityReducer,
    azimuthalIntegrationReducer,
    instrumentalFileCreationReducer,
    dataCalibrationReducer,
    rietveldReducer,
    peakFittingReducer,
  });

export default createRootReducer;
