import { Dispatch } from "redux";
import RietveldGraph from "./rietveld-graph";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import RietveldInput from "./rietveld-input";
import AnalysisFilesTable from "../shared/analysis-files-table";
import ProgressBar from "../shared/progress-bar";
import { selectInstanceId, selectProgress, selectProgressMessage } from "../../../redux/general/generalSelectors";
import { GET_RIETVELD_REFINE_HISTOGRAM_CALLBACK, SET_RIETVELD_DATA_ACTIVE_FILES, SET_RIETVELD_DATA_VIEW_FILE, SET_RIETVELD_MUSTRAINS } from "../../../redux/rietveld/rietveldActions";
import { selectRietveldCifFiles, selectRietveldCifTemplateFiles, selectRietveldDataActiveFiles, selectRietveldDataFiles, selectRietveldDataViewFile, selectRietveldInstrumentalFile, selectRietveldInstrumentalFileWaveLength, selectRietveldInstrumentalTemplateFile, selectRietveldMustrains, selectRietveldParameters, selectRietveldRangeHigher, selectRietveldRangeInitial, selectRietveldRangeLower, selectRietveldWavelength } from "../../../redux/rietveld/rietveldSelectors";
import { numberShortener } from "../../../helpers/name";
import { compareKeys } from "../../../helpers/general";
import { GET_INSTRUMENTAL_FILE_WAVELENGTH, GET_INSTANCE_RUNNING_STATUS, GET_METHOD_DATA, GET_PHASES } from "../../../redux/project/projectActions";

const RietveldAnalysis = ({ dispatch }: {dispatch: Dispatch}) => {
  const rietveldDataFiles = useSelector(selectRietveldDataFiles)
  const rietveldDataViewFile = useSelector(selectRietveldDataViewFile)
  const progress = useSelector(selectProgress)
  const statusMessage = useSelector(selectProgressMessage)
  const parameters = useSelector(selectRietveldParameters)
  const dataActiveFiles = useSelector(selectRietveldDataActiveFiles)
  const cifTemplateFiles = useSelector(selectRietveldCifTemplateFiles);
	const instrumentalTemplateFile = useSelector(selectRietveldInstrumentalTemplateFile);
	const wavelength = useSelector(selectRietveldWavelength);
	const instanceId = useSelector(selectInstanceId);
	const dataFiles = useSelector(selectRietveldDataFiles);
	const cifFiles = useSelector(selectRietveldCifFiles);
	const instrumentalFile = useSelector(selectRietveldInstrumentalFile);
	const dataViewFile = useSelector(selectRietveldDataViewFile);
  
  const [filesInformation, setFilesInformation] = useState<any>([]);
	const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
		dispatch({ type: GET_INSTRUMENTAL_FILE_WAVELENGTH, payload: { type: "rietveld" } });
	}, [dispatch, instrumentalFile, instrumentalTemplateFile]);

  useEffect(() => {
    dispatch({ type: GET_RIETVELD_REFINE_HISTOGRAM_CALLBACK })
  }, [dispatch, dataViewFile]);

	useEffect(() => {
    let mustrains = {}

    cifFiles.forEach((cifFile) => {
      mustrains[cifFile] = 0
    })

    cifTemplateFiles.forEach((cifFile) => {
      mustrains[cifFile] = 0
    })

    dispatch({ type: SET_RIETVELD_MUSTRAINS, payload: mustrains })

	}, [dispatch, cifFiles, cifTemplateFiles]);

  useEffect(() => {
		if (intervalId !== undefined) {
			clearInterval(intervalId);
		}

		if (instanceId !== "") {
			const newIntervalId = setInterval(async () => {
			dispatch({ type: GET_INSTANCE_RUNNING_STATUS, payload: { instanceId: instanceId, projectType: "rietveld" } });
			}, 5000);

			setIntervalId(newIntervalId);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [instanceId]);

  useEffect(() => {
		dispatch({ type: GET_METHOD_DATA, payload: {type: "rietveld"} });
	}, [dispatch]);

	useEffect(() => {
		dispatch({ type: GET_PHASES, payload: { type: "peakFitting"} });
	}, [dispatch, cifFiles, cifTemplateFiles, instrumentalFile, instrumentalTemplateFile, wavelength, dataFiles, dataViewFile]);

  useEffect(() => {
    let filesMapping = []

    for (const file of rietveldDataFiles) {
      filesMapping.push({ fileName: file })
    }

    filesMapping = filesMapping.sort((a, b) => compareKeys(a.fileName, b.fileName))
    if (filesMapping.length > 0 && !rietveldDataViewFile) dispatch({ type: SET_RIETVELD_DATA_VIEW_FILE, payload: filesMapping[0].fileName });

    if (parameters) {
      console.log(parameters)
      let copyParameters = {}

      for (let key in parameters) {
        let fileName = key.split('/')[1];
        let matchingObject = filesMapping.find(obj => obj.fileName.includes(fileName));
      
        if (matchingObject) {
          copyParameters[matchingObject.fileName] = parameters[key];
        }
      }
      console.log(copyParameters)

      filesMapping.forEach((file) => {
        if (file.fileName in copyParameters) {
          Object.keys(copyParameters[file.fileName]).forEach((key) => {
            if (key.includes("Mustrain")) return;
            file[key] = numberShortener(copyParameters[file.fileName][key]);
          });
        };
      });
    }

    setFilesInformation(filesMapping)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rietveldDataFiles, dispatch, parameters])

  return (
    <div className={"flex w-full gap-4 flex-1 xl:flex-col flex-grow"}>
      {instanceId &&
        <ProgressBar
          progress={progress}
          message={statusMessage}
          title={"Running Refinement"}
        />
      }
      <div className={"flex flex-col bg-primary-800 rounded-md w-2/6 xl:w-full overflow-y-auto resize-x hover:resize min-w-min"}>
        <div className={"p-2 flex flex-col border-b border-primary-900 border-solid"}>
          <RietveldInput dispatch={dispatch} />
        </div>
      </div>
      <div className={"flex flex-col flex-1 bg-primary-800 rounded-md w-2/3"}>
        <RietveldGraph dispatch={dispatch} />
        <AnalysisFilesTable
          data={filesInformation}
          dataViewFile={rietveldDataViewFile}
          setDataViewFile={(file) => dispatch({type: SET_RIETVELD_DATA_VIEW_FILE, payload: file})}
          setDataActiveFiles={(files) => dispatch({type: SET_RIETVELD_DATA_ACTIVE_FILES, payload: files})}
          dataActiveFiles={dataActiveFiles}
        />
        </div>
    </div>
  );
};

export default RietveldAnalysis;
