import { all, fork } from "redux-saga/effects";
import projectSaga from "../redux/project/projectSaga";
import leBailSaga from "../redux/leBail/leBailSaga";
import phaseQuantificationSaga from "../redux/phaseQuantification/phaseQuantificationSaga";
import dislocationDensitySaga from "../redux/dislocationDensity/dislocationDensitySaga";
import azimuthalIntegrationSaga from "../redux/azimuthalIntegration/azimuthalIntegrationSaga";
import instrumentalFileCreationSaga from "../redux/instrumentalFileCreation/instrumentalFileCreationSaga";
import dataCalibrationSaga from "../redux/dataCalibration/dataCalibrationSaga";
import rietveldSaga from "../redux/rietveld/rietveldSaga";
import peakFittingSaga from "../redux/peakFitting/peakFittingSaga";
import generalSaga from "../redux/general/generalSaga";

function* rootSaga() {
  yield all([
    fork(generalSaga),
    fork(projectSaga),
    fork(leBailSaga),
    fork(phaseQuantificationSaga),
    fork(dislocationDensitySaga),
    fork(azimuthalIntegrationSaga),
    fork(instrumentalFileCreationSaga),
    fork(dataCalibrationSaga),
    fork(rietveldSaga),
    fork(peakFittingSaga)
  ]);
}

export default rootSaga;
