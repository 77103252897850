import Loader from "react-loader-spinner";
import { useSelector } from "react-redux";
import { getInInInlineOperationInProgress, getInInlineOperationInProgress, getInlineOperationInProgress } from "../../../redux/general/generalSelectors";
import { Dispatch } from "redux";
import { MouseEvent, useEffect, useRef, useState } from 'react';
import Plot from "react-plotly.js";
import { selectDataCalibrationActiveImagePath, selectDataCalibrationAnalysis, selectDataCalibrationDataFile, selectDataCalibrationDrawingActiveIndex, selectDataCalibrationDrawingMode, selectDataCalibrationDrawings, selectDataCalibrationImageGeometry, selectDataCalibrationImageMask, selectDataCalibrationImageOptions, selectDataCalibrationRingPicking, selectDataCalibrationRingPickingMode, selectDataCalibrationRingPickingRing, selectDataCalibrationWavelength } from "../../../redux/dataCalibration/dataCalibrationSelectors";
import { Coordinate, DetectorCoordinates } from "../../../models/analysis.dataCalibration.model";
import { SET_DATA_CALIBRATION_IMAGE_OPTIONS_COLOR_MAP, SET_DATA_CALIBRATION_IMAGE_OPTIONS_LOG, APPEND_DATA_CALIBRATION_DRAWINGS, GET_DATA_CALIBRATION_IMAGE, SET_DATA_CALIBRATION_DRAWINGS, SET_DATA_CALIBRATION_DRAWINGS_OBJECT, UPDATE_DATA_CALIBRATION_DRAWINGS, SET_DATA_CALIBRATION_RING_PICKING, SET_DATA_CALIBRATION_RING_PICKING_OBJECT, GET_DATA_CALIBRATION_RING_PICKING, GET_DATA_CALIBRATION_MASKING } from "../../../redux/dataCalibration/dataCalibrationActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateRight } from "@fortawesome/free-solid-svg-icons";
import DropDown from "../shared/drop-down";
import { AzimuthalIntegrationImageColorMap } from "../../../data/azimuthal_integrations";
import { colorsGraph } from "../../../data/colors";
import { getTitle } from "../../../helpers/name";
import { DropDownOptions } from "../../../data/models";

const DataCalibrationGraph = ({ dispatch, isSidebarCollapsed, performedDelete }: { dispatch: Dispatch, isSidebarCollapsed: boolean, performedDelete: boolean }) => {
  const inLineOperationInProgress = useSelector(getInlineOperationInProgress);
  const inInlineOperationInProgress = useSelector(getInInlineOperationInProgress);
  const inInInlineOperationInProgress = useSelector(getInInInlineOperationInProgress);
  const drawingMode = useSelector(selectDataCalibrationDrawingMode);
  const drawings = useSelector(selectDataCalibrationDrawings);
  const imageOptions = useSelector(selectDataCalibrationImageOptions);
  const drawingActiveIndex = useSelector(selectDataCalibrationDrawingActiveIndex);
  const activeImagePath = useSelector(selectDataCalibrationActiveImagePath);
  const ringPickingMode = useSelector(selectDataCalibrationRingPickingMode);
  const ringPicking = useSelector(selectDataCalibrationRingPicking);
  const ringPickingRing = useSelector(selectDataCalibrationRingPickingRing);
  const maskImage = useSelector(selectDataCalibrationImageMask);
  const ringImage = useSelector(selectDataCalibrationImageGeometry);
  const analysis = useSelector(selectDataCalibrationAnalysis);
  const wavelength = useSelector(selectDataCalibrationWavelength);
  const dataFile = useSelector(selectDataCalibrationDataFile);

  const imageContainerRef = useRef<HTMLDivElement>(null);
  const mainContainerRef = useRef<HTMLDivElement>(null);
  const mainImageRef = useRef<HTMLImageElement>(null);
  const zoomBoxRef = useRef<HTMLDivElement>(null);
  const canvasRefs = useRef<{ [key: number]: HTMLCanvasElement | null }>({});
  const initialEllipseCoordRef = useRef<Coordinate | null>(null);
  const prevDrawingCoordRef = useRef<Coordinate | null>(null);
  const isDrawingRef = useRef(false);

  const [clickCount, setClickCount] = useState(0);
  const [startRectCoord, setStartRectCoord] = useState<Coordinate | null>(null);
  const [initLoad, setInitLoad] = useState(false);
	const [currentRings, setCurrentRings] = useState({});
  const [chartData, setChartData] = useState<any>([]);
  const [chartShapes, setChartShapes] = useState<any>([]);
  const [zooming, setZooming] = useState(false);
  const [activeTab, setActiveTab] = useState("detector_image")
  const [visibleDiagrams, setVisibleDiagrams] = useState({
    id: "diagrams",
    label: "Diagrams",
    options: [],
  } as DropDownOptions)
  const [reloadPeakPickingCanvas, setReloadPeakPickingCanvas] = useState(false)

  useEffect(() => {
    let dropdownOptions = {
      id: "diagrams",
      label: "Diagrams",
      options: [
        {
          id: "detector_image",
          label: "Detector Image(s)",
          values: [
            { label: "Detector Image", value: "detector_image" },
          ],
        },
      ],
    } as DropDownOptions

    if (maskImage) {
      dropdownOptions.options[0].values.push({ label: "Detector Mask Image", value: "detector_mask_image" })
    }

    if (ringImage) {
      dropdownOptions.options[0].values.push({ label: "Detector Ring Image", value: "detector_ring_image" })
    }

    if (analysis.x.length) {
      dropdownOptions.options.push({
        id: "azimuthal_integration",
        label: "Integration",
        values: [
          { label: "Integration Graph", value: "azimuthal_integration_graph" },
        ],
      })
    }

    setVisibleDiagrams(dropdownOptions)
  }, [maskImage, ringImage, analysis])

  useEffect(() => {
    if (analysis.x.length) {
      const analysisCopy = trimTrailingZeros(analysis)

      const analysisD = [
        {
          x: analysisCopy.x,
          y: analysisCopy.y,
          type: "linear",
          name: "Data",
          mode: "lines",
          line: {
            color: "#ffffff",
            width: 2,
          },
        },
      ];

      // the analysis.lines is an array of numbers. Each number is a vertical line at that x value. The line should span y.min to y.max
      const yMin = Math.min(...analysisCopy.y);
      const yMax = Math.max(...analysisCopy.y);

      const analysisS = analysisCopy.lines.map((line) => ({
        type: "line",
        x0: line,
        y0: yMin,
        x1: line,
        y1: yMax,
        opacity: 0.8,
        line: {
          dash: "dash",
          color: "yellow",
          width: 1.5,
        },
      }));

      setChartData(analysisD);
      setChartShapes(analysisS);
    }
  }, [analysis]);

  useEffect(() => {
    if (!maskImage) return

    setActiveTab("detector_mask_image")
  }, [maskImage])

  useEffect(() => {
    if (!ringImage) return

    setActiveTab("detector_ring_image")
  }, [ringImage])

  useEffect(() => {
    if (!analysis.x.length) return

    setActiveTab("azimuthal_integration_graph")
  }, [analysis])

  const delay = ms => new Promise(res => setTimeout(res, ms));

  useEffect(() => {
    async function delayInner() {

      await delay(3000)
      handleResetZoom()
    }

    delayInner()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, wavelength])

  useEffect(() => {
    if (!Object.keys(currentRings).length) return

    const currentRingsCall = currentRings
    setCurrentRings({})

    dispatch({ type: GET_DATA_CALIBRATION_RING_PICKING, payload: currentRingsCall });
	}, [currentRings, dispatch]);

  const handleDoubleClick = async () => {
    if (drawingMode || ringPickingMode) return;

    await adjustDrawingCoordinatesForZoomOut();

    handleResetZoom();
    setClickCount(0);
  };

  useEffect(() => {
    if (initLoad) {
      handleDoubleClick();
    }

    setInitLoad(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [performedDelete])

  useEffect(() => {
    const resetClickCount = () => setClickCount(0);
    if (clickCount === 1) {
      const timeout = setTimeout(resetClickCount, 300); // Adjust the time interval as needed
      return () => clearTimeout(timeout);
    }
  }, [clickCount]);

  let isZooming = false;
  let zoomStart: Coordinate = { x: 0, y: 0 };
  let zoomEnd: Coordinate = { x: 0, y: 0 };

  const handleMouseDown = (event: MouseEvent<HTMLDivElement>) => {
    if (drawingMode || ringPickingMode) return;

    if (imageContainerRef.current && mainImageRef.current && zoomBoxRef.current) {
      isZooming = true;
      zoomStart.x = event.clientX;
      zoomStart.y = event.clientY;

      zoomEnd.x = zoomStart.x;
      zoomEnd.y = zoomStart.y;

      zoomBoxRef.current.style.display = 'none';
      zoomBoxRef.current.style.width = `0px`;
      zoomBoxRef.current.style.height = `0px`;

      const imageContainerRect = imageContainerRef.current.getBoundingClientRect();
      zoomBoxRef.current.style.left = `${zoomStart.x - imageContainerRect.left}px`;
      zoomBoxRef.current.style.top = `${zoomStart.y - imageContainerRect.top}px`;
    }
  };

  const handleMouseMove = async (event: MouseEvent<HTMLDivElement>) => {
    if (drawingMode || ringPickingMode) return;

    if (isZooming && imageContainerRef.current && mainImageRef.current && zoomBoxRef.current) {

      if (mainImageRef.current.style.transform && mainImageRef.current.style.transform !== 'scale(1, 1) translate(0px, 0px)') {
        // await adjustDrawingCoordinatesForZoomOut();
        mainImageRef.current.style.clipPath = 'inset(0 0 0 0)';
        mainImageRef.current.style.transformOrigin = '0 0';
        mainImageRef.current.style.transform = 'scale(1, 1) translate(0px, 0px)';
      }

      const imageContainerRect = imageContainerRef.current.getBoundingClientRect();
      const lockedX = zoomStart.x - imageContainerRect.left;
      const lockedY = zoomStart.y - imageContainerRect.top;

      zoomEnd.x = event.clientX;
      zoomEnd.y = event.clientY;

      const bottomRightX = event.clientX - imageContainerRect.left;
      const bottomRightY = event.clientY - imageContainerRect.top;

      const zoomWidth = Math.abs(bottomRightX - lockedX);
      const zoomHeight = Math.abs(bottomRightY - lockedY);

      const zoomTop = Math.min(lockedY, bottomRightY);
      const zoomLeft = Math.min(lockedX, bottomRightX);

      zoomBoxRef.current.style.width = `${zoomWidth}px`;
      zoomBoxRef.current.style.height = `${zoomHeight}px`;
      zoomBoxRef.current.style.top = `${zoomTop}px`;
      zoomBoxRef.current.style.left = `${zoomLeft}px`;

      zoomBoxRef.current.style.display = 'block';
    }
  };

  const handleMouseUp = async () => {
    if (drawingMode || ringPickingMode) return;

    if (isZooming && mainImageRef.current && zoomBoxRef.current) {
      isZooming = false;
      zoomBoxRef.current.style.display = 'none';
      zoomBoxRef.current.style.width = `0px`;
      zoomBoxRef.current.style.height = `0px`;

      await adjustDrawingCoordinatesForZoomIn();
      setZooming(!zooming);

      const imageContainerRect = imageContainerRef.current.getBoundingClientRect();
      const zoomedInWidth = Math.abs(zoomEnd.x - zoomStart.x);
      const zoomedInHeight = Math.abs(zoomEnd.y - zoomStart.y);

      if (zoomedInWidth <= 0 || zoomedInHeight <= 0) {
        return;
      }

      // Calculate the maximum square dimension
      const maxDimension = Math.max(zoomedInWidth, zoomedInHeight);

      // Ensure the max dimension doesn't exceed the original image dimensions
      const adjustedMaxDimension = Math.min(maxDimension, imageContainerRect.width, imageContainerRect.height);

      // Calculate the scale factor for zooming to maintain the square aspect
      const scaleFactor = imageContainerRect.width / adjustedMaxDimension;

      // Calculate the position adjustments for zooming

      // Check if the zoomStart.x is greater than the zoomEnd.x
      let clickXOnOriginal;
      if (zoomStart.x < zoomEnd.x) {
        clickXOnOriginal = ((zoomStart.x - imageContainerRect.left) / imageContainerRect.width) * adjustedMaxDimension;
      } else {
        clickXOnOriginal = ((zoomEnd.x - imageContainerRect.left) / imageContainerRect.width) * adjustedMaxDimension;
      }

      let clickYOnOriginal;
      if (zoomStart.y < zoomEnd.y) {
        clickYOnOriginal = ((zoomStart.y - imageContainerRect.top) / imageContainerRect.height) * adjustedMaxDimension;
      } else {
        clickYOnOriginal = ((zoomEnd.y - imageContainerRect.top) / imageContainerRect.height) * adjustedMaxDimension;
      }

      // const clickYOnOriginal = ((zoomStart.y - imageContainerRect.top) / imageContainerRect.height) * adjustedMaxDimension;
      let translateValueX = -clickXOnOriginal * scaleFactor;
      let translateValueY = -clickYOnOriginal * scaleFactor;

      if (mainImageRef.current.naturalHeight > mainImageRef.current.naturalWidth) {
        translateValueY = translateValueY * (mainImageRef.current.naturalHeight / mainImageRef.current.naturalWidth);
      } else {
        translateValueX = translateValueX * (mainImageRef.current.naturalWidth / mainImageRef.current.naturalHeight);
      }

      // Set the clip-path and transform properties
      mainImageRef.current.style.clipPath = `inset(0 0 0 0)`;
      mainImageRef.current.style.transformOrigin = '0 0';
      mainImageRef.current.style.transform = `scale(${scaleFactor}, ${scaleFactor}) translate(${translateValueX}px, ${translateValueY}px)`;
    }
  };

  const handleResetZoom = async () => {
    if (mainImageRef.current) {
      // reset everything including the clip-path and transform properties scale, translate, and clip-path, zoomBoxRef height and width
      mainImageRef.current.style.clipPath = `inset(0 0 0 0)`;
      mainImageRef.current.style.transformOrigin = '0 0';
      mainImageRef.current.style.transform = `scale(1, 1) translate(0px, 0px)`;
      if (zoomBoxRef.current) {
        zoomBoxRef.current.style.width = `0px`;
        zoomBoxRef.current.style.height = `0px`;
      }

      await adjustDrawingCoordinatesForZoomOut();

      // reset zoomStart and zoomEnd
      zoomStart = { x: 0, y: 0 };
      zoomEnd = { x: 0, y: 0 };
    }
  };

  const handleImageClick = (event: MouseEvent<HTMLImageElement>) => {
    if (drawingMode) return;

    if (!ringPickingMode) return;

    const canvas = canvasRefs.current["ringPicking"];
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    const { originalX: clickXOnOriginal, originalY: clickYOnOriginal } = getCoordinatesOnOriginalImage(x, y);

    dispatch({ type: SET_DATA_CALIBRATION_RING_PICKING, payload: {
      "x": x,
      "y": y,
      "originalX": clickXOnOriginal,
      "originalY": clickYOnOriginal
    }});

    if (ringPickingMode === "group") {
      setCurrentRings({
        "x": x,
        "y": y,
        "originalX": clickXOnOriginal,
        "originalY": clickYOnOriginal,
        "ring": ringPickingRing,
        "type": ringPickingMode,
        "index": Object.keys(ringPicking).length
      })
    }

    const context = canvas.getContext("2d");
    if (!context) return;

    context.beginPath();
    context.arc(x, y, 5, 0, 5 * Math.PI);
    context.fillStyle = colorsGraph[Object.keys(ringPicking).length % colorsGraph.length];
    context.fill();
    context.closePath();
  };

  useEffect(() => {
    const canvas = canvasRefs.current["ringPickingPeaks"];
    if (!canvas) return;

    const context = canvas.getContext("2d");
    if (!context) return;

    context.clearRect(0, 0, canvas.width, canvas.height);

    const imageContainerRect = imageContainerRef.current.getBoundingClientRect();
    const zoomedInWidth = Math.abs(zoomEnd.x - zoomStart.x);
    const zoomedInHeight = Math.abs(zoomEnd.y - zoomStart.y);

    let zoomedIn = true;

    if (zoomedInWidth <= 0 || zoomedInHeight <= 0) {
      zoomedIn = false;
    }

    if (zoomedIn) {
      const coordinatesOffset = {
        zoomStartX: zoomStart.x < zoomEnd.x ? zoomStart.x - imageContainerRect.left : zoomEnd.x - imageContainerRect.left,
        zoomStartY: zoomStart.y < zoomEnd.y ? zoomStart.y - imageContainerRect.top : zoomEnd.y - imageContainerRect.top,
        zoomEndX: zoomStart.x < zoomEnd.x ? zoomEnd.x - imageContainerRect.left : zoomStart.x - imageContainerRect.left,
        zoomEndY: zoomStart.y < zoomEnd.y ? zoomEnd.y - imageContainerRect.top : zoomStart.y - imageContainerRect.top,
      };

      const maxDimension = Math.max(zoomedInWidth, zoomedInHeight);
      const adjustedMaxDimension = Math.min(maxDimension, imageContainerRect.width, imageContainerRect.height);
      const scaleFactor = imageContainerRect.width / adjustedMaxDimension;

      Object.keys(ringPicking).forEach((key, index) => {
        const ring = ringPicking[key];
        const color = colorsGraph[index % colorsGraph.length];

        ring.peaks.forEach(peak => {
          // const {x, y} = getTransformedCoordinates(peak.x, peak.y);

          const adjustedX = (peak.x - coordinatesOffset.zoomStartX) * scaleFactor;
          const adjustedY = (peak.y - coordinatesOffset.zoomStartY) * scaleFactor;

          context.beginPath();
          context.arc(adjustedX, adjustedY, 3, 0, 3 * Math.PI);
          context.fillStyle = color;
          context.fill();
          context.closePath();
        });
      });
    } else {
      Object.keys(ringPicking).forEach((key, index) => {
        const ring = ringPicking[key];
        const color = colorsGraph[index % colorsGraph.length];

        ring.peaks.forEach(peak => {
          const {x, y} = getTransformedCoordinates(peak.x, peak.y);

          context.beginPath();
          context.arc(x, y, 3, 0, 3 * Math.PI);
          context.fillStyle = color;
          context.fill();
          context.closePath();
        });
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ringPicking, zooming, reloadPeakPickingCanvas]);

  const getTransformedCoordinates = (originalX, originalY) => {
    const imageContainerRect = imageContainerRef.current.getBoundingClientRect();
    const originalImageWidth = mainImageRef.current.naturalWidth;
    const prevTransform = mainImageRef.current.style.transform;

    let scaleFactor = 1;
    let translateValueX = 0;
    let translateValueY = 0;

    const scaleMatch = prevTransform.match(/scale\((.*?)\)/);
    if (scaleMatch) {
      scaleFactor = parseFloat(scaleMatch[1]);
    }

    const translateMatch = prevTransform.match(/translate\((.*?)px, (.*?)px\)/);
    if (translateMatch) {
      translateValueX = parseFloat(translateMatch[1]);
      translateValueY = parseFloat(translateMatch[2]);
    }

    const imageScaleFactor = originalImageWidth / imageContainerRect.width;
    const scaledX = originalX / imageScaleFactor;
    const scaledY = originalY / imageScaleFactor;

    const x = (scaledX + translateValueX) * scaleFactor;
    const y = (scaledY + translateValueY) * scaleFactor;

    return { x, y };
};


  const handleCanvasMouseDown = (event: MouseEvent<HTMLCanvasElement>) => {
    if (!drawingMode || ringPickingMode) return;

    isDrawingRef.current = true;

    let index = drawingActiveIndex;

    if (drawings[drawingActiveIndex].coordinates.length !== 0 && drawingMode !== "polygon") {
      dispatch({ type: SET_DATA_CALIBRATION_DRAWINGS, payload: drawingMode});
      index++;
    }

    switch (drawingMode) {
      case "freeHand":
        handleFreeHandCanvasMouseDown(event);
        break;
      case "rectangle":
        handleRectangleCanvasMouseDown(event, index);
        break;
      case "ellipse":
        handleEllipseCanvasMouseDown(event, index);
        break;
      case "polygon":
        handlePolygonCanvasMouseDown(event);
        break;
      default:
        break;
    }
  };

  const handleRectangleCanvasMouseDown = (event: MouseEvent<HTMLCanvasElement>, index: number) => {
    const canvas = canvasRefs.current[drawingActiveIndex];
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    setStartRectCoord({ x, y });

    const { originalX, originalY } = getCoordinatesOnOriginalImage(x, y);

    dispatch({ type: UPDATE_DATA_CALIBRATION_DRAWINGS, payload: { index: index, coordinates: [{
      "label": "first",
      "coordinates": {
        "x": x,
        "y": y,
        "originalX": originalX,
        "originalY": originalY
      }
    }]}})
  };

  const handleFreeHandCanvasMouseDown = (event: MouseEvent<HTMLCanvasElement>) => {
    const canvas = canvasRefs.current[drawingActiveIndex];
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    const { originalX, originalY } = getCoordinatesOnOriginalImage(x, y);

    const newCoordinate: DetectorCoordinates = {
      x,
      y,
      originalX,
      originalY,
    };

    dispatch({ type: APPEND_DATA_CALIBRATION_DRAWINGS, payload: { label: drawings[drawingActiveIndex].coordinates.length, coordinates: newCoordinate } });

    const context = canvas.getContext("2d");

    prevDrawingCoordRef.current = { x, y };

    if (context) {
      context.beginPath();
      context.moveTo(x, y);
      context.strokeStyle = colorsGraph[drawingActiveIndex % colorsGraph.length];
      context.lineWidth = 3;
      context.lineCap = "round";
    }
  };

  const handleEllipseCanvasMouseDown = (event: MouseEvent<HTMLCanvasElement>, index: number) => {
    const canvas = canvasRefs.current[drawingActiveIndex];
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    const { originalX, originalY } = getCoordinatesOnOriginalImage(x, y);

    initialEllipseCoordRef.current = { x, y };

    dispatch({ type: UPDATE_DATA_CALIBRATION_DRAWINGS, payload: { index: index, coordinates: [{
      "label": "first",
      "coordinates": {
        "x": x,
        "y": y,
        "originalX": originalX,
        "originalY": originalY
      }
    }]}})
  };

  const handlePolygonCanvasMouseDown = (event: MouseEvent<HTMLCanvasElement>) => {
    const canvas = canvasRefs.current[drawingActiveIndex];
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    const context = canvas.getContext("2d");
    if (!context) return;

    if (drawings[drawingActiveIndex].coordinates.length) {
      const lastPoint = drawings[drawingActiveIndex].coordinates[drawings[drawingActiveIndex].coordinates.length - 1].coordinates;
      context.beginPath();
      context.moveTo(lastPoint.x, lastPoint.y);
      context.lineTo(x, y);
      context.strokeStyle = colorsGraph[drawingActiveIndex % colorsGraph.length];
      context.lineWidth = 3;
      context.stroke();
    }

    const { originalX, originalY } = getCoordinatesOnOriginalImage(x, y);

    const newCoordinate: DetectorCoordinates = {
      x,
      y,
      originalX,
      originalY,
    };

    dispatch({ type: APPEND_DATA_CALIBRATION_DRAWINGS, payload: { label: drawings[drawingActiveIndex].coordinates.length, coordinates: newCoordinate } });
  };

  const handleCanvasMouseMove = (event: MouseEvent<HTMLCanvasElement>) => {
    if (!drawingMode || ringPickingMode) return;

    if (!isDrawingRef.current) return;

    switch (drawingMode) {
      case "freeHand":
        handleFreeHandCanvasMouseMove(event);
        break;
      case "rectangle":
        handleRectangleCanvasMouseMove(event);
        break;
      case "ellipse":
        handleEllipseCanvasMouseMove(event);
        break;
      default:
        break;
    }
  };

  const handleRectangleCanvasMouseMove = (event: MouseEvent<HTMLCanvasElement>) => {
    if (!isDrawingRef.current || !startRectCoord) return;

    const canvas = canvasRefs.current[drawingActiveIndex];
    if (!canvas) return;

    const context = canvas.getContext("2d");
    if (!context) return;

    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    context.clearRect(0, 0, canvas.width, canvas.height); // Clear previous drawings
    context.beginPath();
    context.rect(startRectCoord.x, startRectCoord.y, x - startRectCoord.x, y - startRectCoord.y); // Draw rectangle from the start point to current point
    context.strokeStyle = colorsGraph[drawingActiveIndex % colorsGraph.length];
    context.lineWidth = 3;
    context.stroke();
  };

  const getCoordinatesOnOriginalImage = (x: number, y: number) => {
    const imageContainerRect = imageContainerRef.current.getBoundingClientRect();
    const originalImageWidth = mainImageRef.current.naturalWidth;
    const prevTransform = mainImageRef.current.style.transform;

    let scaleFactor = 1;
    let translateValueX = 0;
    let translateValueY = 0;

    const scaleMatch = prevTransform.match(/scale\((.*?)\)/);
    if (scaleMatch) {
      scaleFactor = parseFloat(scaleMatch[1]);
    }

    const translateMatch = prevTransform.match(/translate\((.*?)px, (.*?)px\)/);
    if (translateMatch) {
      translateValueX = parseFloat(translateMatch[1]);
      translateValueY = parseFloat(translateMatch[2]);
    }

    // Calculate the click coordinates on the original image
    const clickXOnOriginal = (x / scaleFactor) - translateValueX;
    const clickYOnOriginal = (y / scaleFactor) - translateValueY;

    // Calculate the click coordinates on the original image by
    const imageScaleFactor = originalImageWidth / imageContainerRect.width;
    const originalX = clickXOnOriginal * imageScaleFactor;
    const originalY = clickYOnOriginal * imageScaleFactor;

    return { originalX, originalY };
  };

  const handleFreeHandCanvasMouseMove = (event: MouseEvent<HTMLCanvasElement>) => {
    const canvas = canvasRefs.current[drawingActiveIndex];
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    const { originalX, originalY } = getCoordinatesOnOriginalImage(x, y);

    const newCoordinate: DetectorCoordinates = {
      x,
      y,
      originalX,
      originalY,
    };

    dispatch({ type: APPEND_DATA_CALIBRATION_DRAWINGS, payload: { label: drawings[drawingActiveIndex].coordinates.length, coordinates: newCoordinate } });

    const context = canvas.getContext("2d");

    if (!context) return;

    context.beginPath();
    context.moveTo(prevDrawingCoordRef.current?.x || x, prevDrawingCoordRef.current?.y || y);
    context.lineTo(x, y);
    context.strokeStyle = colorsGraph[drawingActiveIndex % colorsGraph.length];
    context.lineWidth = 3;
    context.lineCap = "round";
    context.stroke();

    prevDrawingCoordRef.current = { x, y };
  };

  const handleEllipseCanvasMouseMove = (event: MouseEvent<HTMLCanvasElement>) => {
    if (!drawingMode || ringPickingMode) return;

    if (!isDrawingRef.current || !initialEllipseCoordRef.current) return;

    const canvas = canvasRefs.current[drawingActiveIndex];
    if (!canvas) return;

    const context = canvas.getContext("2d");
    if (!context) return;

    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    // Calculate the width and height for the ellipse
    const width = Math.abs(x - initialEllipseCoordRef.current.x);
    const height = Math.abs(y - initialEllipseCoordRef.current.y);

    // Clear previous dynamic drawings
    context.clearRect(0, 0, canvas.width, canvas.height);

    context.beginPath();
    // Drawing the ellipse by calculating its bounding rectangle
    context.ellipse(
      (x + initialEllipseCoordRef.current.x) / 2, // centerX
      (y + initialEllipseCoordRef.current.y) / 2, // centerY
      width / 2, // radiusX
      height / 2, // radiusY
      0, // rotation (not needed for our use-case)
      0,
      2 * Math.PI
    );
    context.strokeStyle = colorsGraph[drawingActiveIndex % colorsGraph.length];
    context.lineWidth = 3;
    context.stroke();
  };

  const handleCanvasMouseUp = (event: MouseEvent<HTMLCanvasElement>) => {
    if (!drawingMode || ringPickingMode) return;

    if (!isDrawingRef.current) return;

    isDrawingRef.current = false;
    prevDrawingCoordRef.current = null;
    setStartRectCoord(null);
    initialEllipseCoordRef.current = null;

    const rect = event.currentTarget.getBoundingClientRect();
    const mouseXUp = event.clientX - rect.left;
    const mouseYUp = event.clientY - rect.top;

    const { originalX: clickXOnOriginalUp, originalY: clickYOnOriginalUp } = getCoordinatesOnOriginalImage(mouseXUp, mouseYUp);
    switch (drawingMode) {
      case "rectangle":
        dispatch({ type: APPEND_DATA_CALIBRATION_DRAWINGS, payload: {
          "label": "second",
          "coordinates": {
            "x": mouseXUp,
            "y": mouseYUp,
            "originalX": clickXOnOriginalUp,
            "originalY": clickYOnOriginalUp
          }
        }});
        break;
      case "ellipse":
        dispatch({ type: APPEND_DATA_CALIBRATION_DRAWINGS, payload: {
          "label": "second",
          "coordinates": {
            "x": mouseXUp,
            "y": mouseYUp,
            "originalX": clickXOnOriginalUp,
            "originalY": clickYOnOriginalUp
          }
        }});
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    function adjustImageContainerSize() {
      handleResetZoom()
      if (imageContainerRef.current) {
        const width = mainContainerRef.current.offsetWidth;
        const height = mainContainerRef.current.offsetHeight;

        if (mainImageRef.current.naturalHeight < mainImageRef.current.naturalWidth) {
          imageContainerRef.current.style.width = `${width * 0.9}px`;
          imageContainerRef.current.style.height = `${(width * 0.9) * (mainImageRef.current.naturalHeight / mainImageRef.current.naturalWidth)}px`;
        } else {
          imageContainerRef.current.style.height = `${height * 0.9}px`;
          imageContainerRef.current.style.width = `${(height * 0.9) * (mainImageRef.current.naturalWidth / mainImageRef.current.naturalHeight)}px`;
        }
      }
    }

    // Initial adjustment
    adjustImageContainerSize();

    // Adjust the size whenever the window is resized
    window.addEventListener('resize', adjustImageContainerSize);

    // Cleanup listener when component is unmounted
    return () => {
      window.removeEventListener('resize', adjustImageContainerSize);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleResetZoom();

    setTimeout(() => {
      if (imageContainerRef.current) {
        const width = mainContainerRef.current.offsetWidth;
        const height = mainContainerRef.current.offsetHeight;

        if (mainImageRef.current.naturalHeight < mainImageRef.current.naturalWidth) {
          imageContainerRef.current.style.width = `${width * 0.9}px`;
          imageContainerRef.current.style.height = `${(width * 0.9) * (mainImageRef.current.naturalHeight / mainImageRef.current.naturalWidth)}px`;
        } else {
          imageContainerRef.current.style.height = `${height * 0.9}px`;
          imageContainerRef.current.style.width = `${(height * 0.9) * (mainImageRef.current.naturalWidth / mainImageRef.current.naturalHeight)}px`;
        }
      }
    }, 1500);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSidebarCollapsed, mainImageRef.current?.naturalWidth, mainImageRef.current?.offsetWidth, mainImageRef.current?.offsetHeight, mainImageRef.current?.naturalHeight, activeImagePath, mainImageRef])

  const [loadInit, setLoadInit] = useState(false)

  useEffect(() => {
    dispatch({ type: GET_DATA_CALIBRATION_IMAGE });
    if (!loadInit) {
      setLoadInit(true)
      return
    }

    dispatch({ type: GET_DATA_CALIBRATION_MASKING });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, imageOptions, dataFile]);

  const adjustDrawingCoordinatesForZoomOut = async () => {
    const imageContainerRect = imageContainerRef.current.getBoundingClientRect();
    const originalImageWidth = mainImageRef.current.naturalWidth;

    const scaleFactor = originalImageWidth / imageContainerRect.width;

    const transformed = {};

    for (const key in drawings) {
      transformed[key] = {
        ...drawings[key], // This spreads all the other properties
        coordinates: drawings[key].coordinates.map(coord => ({
          ...coord, // This spreads properties like 'label'
          coordinates: {
            ...coord.coordinates, // This spreads properties like originalX and originalY
            x: coord.coordinates.originalX / scaleFactor,
            y: coord.coordinates.originalY / scaleFactor,
          }
        }))
      };
    }

    const transformedRingPicking = {};

    for (const key in ringPicking) {
      transformedRingPicking[key] = {
        ...ringPicking[key], // This spreads all the other properties
        clickedCoordinate: {
          ...ringPicking[key].clickedCoordinate, // This spreads properties like originalX and originalY
          x: ringPicking[key].clickedCoordinate.originalX / scaleFactor,
          y: ringPicking[key].clickedCoordinate.originalY / scaleFactor,
        }
      };
    }
    
    if (Object.keys(transformed).length === 0 && Object.keys(transformedRingPicking).length === 0) return;
    dispatch({ type: SET_DATA_CALIBRATION_DRAWINGS_OBJECT, payload: transformed });
    dispatch({ type: SET_DATA_CALIBRATION_RING_PICKING_OBJECT, payload: transformedRingPicking });

    Object.keys(canvasRefs.current).forEach(key => {
      const currentCanvas = canvasRefs.current[key];

      if (!currentCanvas) return;

      const context = currentCanvas.getContext("2d");
      if (!context) return;

      context.clearRect(0, 0, currentCanvas.width, currentCanvas.height);
    })

    let i = 0;

    Object.keys(transformed).forEach(key => {
      const coordinates = transformed[key].coordinates;
      const currentCanvas = canvasRefs.current[key];

      if (!currentCanvas) return;

      const context = currentCanvas.getContext("2d");
      if (!context) return;

      if (coordinates.length < 2) return;

      context.beginPath();

      switch (transformed[key].type) {
        case "freeHand":
          let prev = null;

          coordinates.forEach(coord => {
            const { x, y } = coord.coordinates;

            if (prev) {
              context.moveTo(prev.x, prev.y);
              context.lineTo(x, y);
              context.strokeStyle = colorsGraph[i % colorsGraph.length];
              context.lineWidth = 3;
              context.lineCap = "round";
              context.stroke();
            } else {
              context.arc(x, y, 3, 0, 2 * Math.PI);
              context.fillStyle = colorsGraph[i % colorsGraph.length];
              context.fill();
            }
            prev = { x, y };
          });

          break;
        case "rectangle":
          const firstCorner = coordinates[0].coordinates;
          const secondCorner = coordinates[1].coordinates;

          const width = secondCorner.x - firstCorner.x;
          const height = secondCorner.y - firstCorner.y;

          context.beginPath();
          context.rect(firstCorner.x, firstCorner.y, width, height);
          context.strokeStyle = colorsGraph[i % colorsGraph.length];
          context.lineWidth = 3;
          context.stroke();
          break;

        case "ellipse":
          const firstCornerEllipse = coordinates[0].coordinates;
          const secondCornerEllipse = coordinates[1].coordinates;

          const widthEllipse = secondCornerEllipse.x - firstCornerEllipse.x;
          const heightEllipse = secondCornerEllipse.y - firstCornerEllipse.y;

          context.beginPath();
          // Drawing the ellipse by calculating its bounding rectangle
          context.ellipse(
            (firstCornerEllipse.x + secondCornerEllipse.x) / 2, // centerX
            (firstCornerEllipse.y + secondCornerEllipse.y) / 2, // centerY
            widthEllipse / 2, // radiusX
            heightEllipse / 2, // radiusY
            0, // rotation (not needed for our use-case)
            0,
            2 * Math.PI
          );
          context.strokeStyle = colorsGraph[i % colorsGraph.length];
          context.lineWidth = 3;
          context.stroke();
          break;

        case "polygon":
          let prevPolygon = null;

          coordinates.forEach(coord => {
            const { x, y } = coord.coordinates;

            if (prevPolygon) {
              context.moveTo(prevPolygon.x, prevPolygon.y);
              context.lineTo(x, y);
              context.strokeStyle = colorsGraph[i % colorsGraph.length];
              context.lineWidth = 3;
              context.lineCap = "round";
              context.stroke();
            } else {
              context.arc(x, y, 3, 0, 2 * Math.PI);
              context.fillStyle = colorsGraph[i % colorsGraph.length];
              context.fill();
            }

            prevPolygon = { x, y };
          });
          break;
        default:
          break;
      }

      context.closePath();

      i++;
    })

    i = 0;

    Object.keys(transformedRingPicking).forEach(key => {
      const coordinates = transformedRingPicking[key].clickedCoordinate;
      const currentCanvas = canvasRefs.current["ringPicking"];

      if (!currentCanvas) return;

      const context = currentCanvas.getContext("2d");
      if (!context) return;

      context.beginPath();

      const { x, y } = coordinates;

      context.arc(x, y, 5, 0, 5 * Math.PI);
      context.fillStyle = colorsGraph[i % colorsGraph.length];
      context.fill();
      context.closePath();

      i++;
    })

    setReloadPeakPickingCanvas(!reloadPeakPickingCanvas);
  };

  const adjustDrawingCoordinatesForZoomIn = async () => {
    const imageContainerRect = imageContainerRef.current.getBoundingClientRect();
    const zoomedInWidth = Math.abs(zoomEnd.x - zoomStart.x);
    const zoomedInHeight = Math.abs(zoomEnd.y - zoomStart.y);

    if (zoomedInWidth <= 0 || zoomedInHeight <= 0) {
      return;
    }

    const coordinatesOffset = {
      zoomStartX: zoomStart.x < zoomEnd.x ? zoomStart.x - imageContainerRect.left : zoomEnd.x - imageContainerRect.left,
      zoomStartY: zoomStart.y < zoomEnd.y ? zoomStart.y - imageContainerRect.top : zoomEnd.y - imageContainerRect.top,
      zoomEndX: zoomStart.x < zoomEnd.x ? zoomEnd.x - imageContainerRect.left : zoomStart.x - imageContainerRect.left,
      zoomEndY: zoomStart.y < zoomEnd.y ? zoomEnd.y - imageContainerRect.top : zoomStart.y - imageContainerRect.top,
    };

    Object.keys(canvasRefs.current).forEach(key => {
      const currentCanvas = canvasRefs.current[key];

      if (!currentCanvas) return;

      const context = currentCanvas.getContext("2d");
      if (!context) return;

      context.clearRect(0, 0, currentCanvas.width, currentCanvas.height);
    })

    const maxDimension = Math.max(zoomedInWidth, zoomedInHeight);

    // Ensure the max dimension doesn't exceed the original image dimensions
    const adjustedMaxDimension = Math.min(maxDimension, imageContainerRect.width, imageContainerRect.height);

    // Calculate the scale factor for zooming to maintain the square aspect
    const scaleFactor = imageContainerRect.width / adjustedMaxDimension;

    let i = 0;

    Object.keys(drawings).forEach(key => {
      const coordinates = drawings[key].coordinates;
      const currentCanvas = canvasRefs.current[key];

      if (!currentCanvas) return;

      const context = currentCanvas.getContext("2d");
      if (!context) return;

      if (coordinates.length < 2) return;

      context.beginPath();

      switch (drawings[key].type) {
        case "freeHand":
          let prev = null;

          coordinates.forEach(coord => {
            const { x, y } = coord.coordinates;
            const adjustedX = (x - coordinatesOffset.zoomStartX) * scaleFactor;
            const adjustedY = (y - coordinatesOffset.zoomStartY) * scaleFactor;

            if (prev) {
              context.moveTo(prev.x, prev.y);
              context.lineTo(adjustedX, adjustedY);
              context.strokeStyle = colorsGraph[i % colorsGraph.length];
              context.lineWidth = 3;
              context.lineCap = "round";
              context.stroke();
            } else {
              context.arc(adjustedX, adjustedY, 3, 0, 2 * Math.PI);
              context.fillStyle = colorsGraph[i % colorsGraph.length];
              context.fill();
            }

            prev = { x: adjustedX, y: adjustedY };
          });
          break;
        case "rectangle":
          const firstCorner = coordinates[0].coordinates;
          const secondCorner = coordinates[1].coordinates;

          const width = secondCorner.x - firstCorner.x;
          const height = secondCorner.y - firstCorner.y;

          const adjustedFirstCornerX = (firstCorner.x - coordinatesOffset.zoomStartX) * scaleFactor;
          const adjustedFirstCornerY = (firstCorner.y - coordinatesOffset.zoomStartY) * scaleFactor;
          const adjustedWidth = width * scaleFactor;
          const adjustedHeight = height * scaleFactor;

          context.beginPath();
          context.rect(adjustedFirstCornerX, adjustedFirstCornerY, adjustedWidth, adjustedHeight);
          context.strokeStyle = colorsGraph[i % colorsGraph.length];
          context.lineWidth = 3;
          context.stroke();
          break;
        case "ellipse":
          const firstCornerEllipse = coordinates[0].coordinates;
          const secondCornerEllipse = coordinates[1].coordinates;

          const widthEllipse = secondCornerEllipse.x - firstCornerEllipse.x;
          const heightEllipse = secondCornerEllipse.y - firstCornerEllipse.y;

          const adjustedFirstCornerEllipseX = (firstCornerEllipse.x - coordinatesOffset.zoomStartX) * scaleFactor;
          const adjustedFirstCornerEllipseY = (firstCornerEllipse.y - coordinatesOffset.zoomStartY) * scaleFactor;
          const adjustedWidthEllipse = widthEllipse * scaleFactor;
          const adjustedHeightEllipse = heightEllipse * scaleFactor;

          context.beginPath();
          // Drawing the ellipse by calculating its bounding rectangle
          context.ellipse(
            (adjustedFirstCornerEllipseX + adjustedFirstCornerEllipseX + adjustedWidthEllipse) / 2, // centerX
            (adjustedFirstCornerEllipseY + adjustedFirstCornerEllipseY + adjustedHeightEllipse) / 2, // centerY
            adjustedWidthEllipse / 2, // radiusX
            adjustedHeightEllipse / 2, // radiusY
            0, // rotation (not needed for our use-case)
            0,
            2 * Math.PI
          );
          context.strokeStyle = colorsGraph[i % colorsGraph.length];
          context.lineWidth = 3;
          context.stroke();
          break;

        case "polygon":
          let prevPolygon = null;

          coordinates.forEach(coord => {
            const { x, y } = coord.coordinates;
            const adjustedX = (x - coordinatesOffset.zoomStartX) * scaleFactor;
            const adjustedY = (y - coordinatesOffset.zoomStartY) * scaleFactor;

            if (prevPolygon) {
              context.moveTo(prevPolygon.x, prevPolygon.y);
              context.lineTo(adjustedX, adjustedY);
              context.strokeStyle = colorsGraph[i % colorsGraph.length];
              context.lineWidth = 3;
              context.lineCap = "round";
              context.stroke();
            } else {
              context.arc(adjustedX, adjustedY, 3, 0, 2 * Math.PI);
              context.fillStyle = colorsGraph[i % colorsGraph.length];
              context.fill();
            }

            prevPolygon = { x: adjustedX, y: adjustedY };
          });
          break;
        default:
          break;
      }
      context.closePath();

      i++;
    })

    i = 0;

    const currentCanvas = canvasRefs.current["ringPicking"];
    if (!currentCanvas) return;

    let context = currentCanvas.getContext("2d");
    if (!context) return;

    Object.keys(ringPicking).forEach(key => {
      const { x, y } = ringPicking[key].clickedCoordinate;

      const adjustedX = (x - coordinatesOffset.zoomStartX) * scaleFactor;
      const adjustedY = (y - coordinatesOffset.zoomStartY) * scaleFactor;

      context.beginPath();
      context.arc(adjustedX, adjustedY, 5, 0, 5 * Math.PI);
      context.fillStyle = colorsGraph[i % colorsGraph.length];
      context.fill();
      context.closePath();

      i++;
    })
  };

  const getImage = () => {
    switch (activeTab) {
      case "detector_image":
        return activeImagePath;
      case "detector_mask_image":
        return maskImage;
      case "detector_ring_image":
        return ringImage;
      default:
        return null;
    }
  }

  if (inLineOperationInProgress) {
    return (
      <div className="flex justify-center h-52 items-center">
        <Loader type="Oval" color="#68d391" height={50} width={50} />
      </div>
    );
  } else {
    return (
      <div className="flex flex-col w-full h-full relative" ref={mainContainerRef}>
        {(inInInlineOperationInProgress) && (
          <div className="absolute top-2 right-2">
            <Loader type="Oval" color="#68d391" height={30} width={30} />
          </div>
        )}
        {/* Create a loader for inInLineOperatingInProgress at the center of the screen */}
        {inInlineOperationInProgress && (
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <Loader type="Oval" color="#68d391" height={50} width={50} />
          </div>
        )}
        {activeTab === "azimuthal_integration_graph" && (
          <div className={"flex justify-center items-center h-full z-10"} style={{ marginBottom: "-700px"}}>
            <Plot
              data={chartData}
              layout={{
                autosize: true,
                paper_bgcolor: "rgba(0,0,0,0)",
                plot_bgcolor: "rgba(0,0,0,0)",
                legend: {
                  orientation: "v",
                  font: {
                    family: "sans-serif",
                    size: 12,
                    color: "white",
                  },
                },
                xaxis: {
                  color: "white",
                  gridcolor: "grey",
                  range: [Math.min(...analysis.x), Math.max(...analysis.x)]
                },
                yaxis: {
                  color: "white",
                  gridcolor: "grey",
                  range: [Math.min(...analysis.y), findFirstPeak(analysis.y) * 1.5]
                },
                modebar: {
                  orientation: "h",
                  bgcolor: "#434446",
                  color: "white",
                },
                shapes: chartShapes,
              }}
              config={{
                modebarbuttonstoremove: ["lasso2d"],
              }}
              useResizeHandler={true}
              style={{ width: "100%", height: "700px" }}
            />
          </div>
        )}
        <div className={`flex w-full flex-col p-2 z-10 ${activeTab === "azimuthal_integration_graph" ? "hidden" : ""}`}>
          <div className="flex w-full flex-row">
            <button
              className={`bg-primary-500 text-white py-1 px-4 rounded-md text-xs`}
              onClick={handleResetZoom}
            >
              Reset Zoom
              <FontAwesomeIcon icon={faRotateRight} className="ml-4" />
            </button>
            <div className="tools ml-2 text-xs rounded">
              <DropDown
                DropDownOptions={AzimuthalIntegrationImageColorMap}
                title={"Color Map"}
                setSelected={(value: string) => dispatch({ type: SET_DATA_CALIBRATION_IMAGE_OPTIONS_COLOR_MAP, payload: value })}
                selected={imageOptions.colorMap}
                multiple={false}
                padding={"p-2"}
                height="450px"
                width="w-48"
              />
            </div>
          </div>
          <div className="flex tools text-xs items-center">
            <button
              className={`${imageOptions.log ? "bg-primary-600 hover:bg-primary-700" : "bg-primary-500 hover:bg-primary-600"} text-white font-bold py-1 px-2 rounded-full mt-1`}
              onClick={() => dispatch({ type: SET_DATA_CALIBRATION_IMAGE_OPTIONS_LOG, payload: !imageOptions.log })}
            >
              Logarithmic
            </button>
          </div>
        </div>
        <div className="flex justify-center items-center w-full absolute">
          <div className={"flex justify-center items-center w-96 z-10 text-sx mt-2 ml-14"}>
            <DropDown
              DropDownOptions={visibleDiagrams}
              title={getTitle(activeTab)}
              setSelected={(value: string) => setActiveTab(value)}
              selected={activeTab}
              multiple={false}
              padding={"p-1"}
              closeOnClick={true}
            />
          </div>
        </div>
        <div className={`flex flex-col relative h-full w-full items-center justify-center ${activeTab === "azimuthal_integration_graph" ? "hidden" : ""}`}>
          <div
            ref={imageContainerRef}
            id="image-container"
            className="relative overflow-hidden cursor-crosshair"
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onClick={handleImageClick}
            onDoubleClick={handleDoubleClick}
          >
            <img
              ref={mainImageRef}
              src={getImage()}
              alt="Detector"
              className={`transform origin-top-left cursor-crosshair ${activeTab === "azimuthal_integration_graph" || !activeImagePath ? "hidden" : ""}`}
            />
            {dataFile && ((activeTab === "detector_image" && activeImagePath.length) || (activeTab === "detector_mask_image" && maskImage.length) || (activeTab === "detector_ring_image" && ringImage.length)) ? (
              <>
              {Object.entries(drawings).map(([key, drawing]) => (
                <canvas
                  key={key}
                  ref={(el) => (canvasRefs.current[key] = el)}
                  style={{
                    display: drawing.visible ? 'block' : 'none',
                    zIndex: 2,
                  }}
                  className={`absolute top-0 left-0`}
                  width={mainImageRef.current?.naturalWidth || 0}
                  height={mainImageRef.current?.naturalHeight || 0}
                  onMouseDown={handleCanvasMouseDown}
                  onMouseMove={handleCanvasMouseMove}
                  onMouseUp={handleCanvasMouseUp}
                />
              ))}
              <canvas
                ref={(el) => (canvasRefs.current["ringPicking"] = el)}
                style={{
                  zIndex: 1,
                }}
                className={`absolute top-0 left-0`}
                width={mainImageRef.current?.naturalWidth || 0}
                height={mainImageRef.current?.naturalHeight || 0}
                onMouseDown={handleCanvasMouseDown}
                onMouseMove={handleCanvasMouseMove}
                onMouseUp={handleCanvasMouseUp}
              />
              <canvas
                ref={(el) => (canvasRefs.current["ringPickingPeaks"] = el)}
                style={{
                  zIndex: 1,
                }}
                className={`absolute top-0 left-0`}
                width={mainImageRef.current?.naturalWidth || 0}
                height={mainImageRef.current?.naturalHeight || 0}
              />
              <div
                ref={zoomBoxRef}
                id="zoom-box"
                className="hidden border-2 border-blue-500 absolute cursor-crosshair"
              ></div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
};

function trimTrailingZeros(data) {
  let lastIndex = data.y.length - 1;

  // Find the index of the last non-zero value in the y array
  while (lastIndex >= 0 && data.y[lastIndex] === 0) {
      lastIndex--;
  }

  // Slice the arrays up to and including the last non-zero y value
  data.x = data.x.slice(0, lastIndex + 1);
  data.y = data.y.slice(0, lastIndex + 1);

  return data;
}

function findFirstPeak(yValues) {
  for (let i = 1; i < yValues.length - 1; i++) {
      if (yValues[i - 1] < yValues[i] && yValues[i] > yValues[i + 1]) {
          return yValues[i]; // Return the first peak y-value
      }
  }
  return null; // If no peak is found
}

export default DataCalibrationGraph;