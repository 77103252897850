import { faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import { cifFilesDirectories } from "../../../data/cif_files";
import { instrumentalFilesDirectories } from "../../../data/instrumental_files";
import toast from "react-hot-toast";
import { selectLeBailCifFiles, selectLeBailCifFilesABC, selectLeBailCifTemplateFiles, selectLeBailDataFiles, selectLeBailInstrumentalFile, selectLeBailInstrumentalFileWaveLength, selectLeBailInstrumentalTemplateFile, selectLeBailRangeHigher, selectLeBailRangeInitial, selectLeBailRangeLower, selectLeBailRefinedHistogram, selectLeBailWavelength } from "../../../redux/leBail/leBailSelectors";
import { SET_LE_BAIL_CIF_FILES, SET_LE_BAIL_INSTRUMENTAL_FILE, SET_LE_BAIL_WAVELENGTH, SET_LE_BAIL_CIF_TEMPLATE_FILES, SET_LE_BAIL_INSTRUMENTAL_TEMPLATE_FILE, GET_LE_BAIL_HISTOGRAM, SET_LE_BAIL_RANGE_LOWER, SET_LE_BAIL_RANGE_HIGHER, GET_LE_BAIL_REFINED_HISTOGRAM, SET_LE_BAIL_DATA_FILES, SET_LE_BAIL_CIF_FILES_ABC } from "../../../redux/leBail/leBailActions";
import { GET_INSTANCE_RUNNING_STATUS, GET_INSTRUMENTAL_FILE_WAVELENGTH, GET_DOWNLOAD } from "../../../redux/project/projectActions";
import { selectInstanceId } from "../../../redux/general/generalSelectors";
import { FileSelection } from "../shared/file-selection/file-selection";
import WavelengthInput from "../shared/wavelength";
import { CifFileABC } from "../shared/cifFileABC";


const LeBailInput = ({ dispatch  }: { dispatch: Dispatch }) => {
	const cifTemplateFiles = useSelector(selectLeBailCifTemplateFiles);
	const instrumentalTemplateFile = useSelector(selectLeBailInstrumentalTemplateFile);
	const instrumentalFiles = useSelector(selectLeBailInstrumentalFile);
	const cifFiles = useSelector(selectLeBailCifFiles)
	const rangeLower = useSelector(selectLeBailRangeLower);
	const rangeHigher = useSelector(selectLeBailRangeHigher);
	const wavelength = useSelector(selectLeBailWavelength);
	const fileWavelength = useSelector(selectLeBailInstrumentalFileWaveLength)
	const instanceId = useSelector(selectInstanceId);
	const initialRange = useSelector(selectLeBailRangeInitial);
	const dataFiles = useSelector(selectLeBailDataFiles);
	const instrumentalFile = useSelector(selectLeBailInstrumentalFile);
	const dataViewFile = useSelector(selectLeBailDataFiles);
	const refinedHistogram = useSelector(selectLeBailRefinedHistogram);
	const dataActiveFiles = useSelector(selectLeBailDataFiles);
	const cifFilesABC = useSelector(selectLeBailCifFilesABC);

	const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>(undefined);
	const [dispatchTimer, setDispatchTimer] = useState<NodeJS.Timeout | null>(null);

	useEffect(() => {
		if (dispatchTimer) clearTimeout(dispatchTimer);

		const newTimer = setTimeout(() => {
			if((!cifFiles.length && !cifTemplateFiles.length) || (!instrumentalFile && !instrumentalTemplateFile)) return;
			dispatch({ type: GET_LE_BAIL_HISTOGRAM });
		}, 1000);

		setDispatchTimer(newTimer);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, cifFiles, cifTemplateFiles, instrumentalFile, instrumentalTemplateFile, wavelength, dataFiles, dataViewFile, cifFilesABC]);


	useEffect(() => {
		if (intervalId !== undefined) clearInterval(intervalId);

		if (instanceId !== "") {
			const newIntervalId = setInterval(async () => {
				dispatch({ type: GET_INSTANCE_RUNNING_STATUS, payload: { instanceId: instanceId, projectType: "leBail" } });
			}, 5000);

			setIntervalId(newIntervalId);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [instanceId]);

	useEffect(() => {
		if (!instrumentalFile && !instrumentalTemplateFile) return;

		dispatch({ type: GET_INSTRUMENTAL_FILE_WAVELENGTH, payload: { type: "leBail" } });
	}, [dispatch, instrumentalFile, instrumentalTemplateFile]);

	return (
		<>
		<div className={"flex flex-col flex-1 bg-primary-800 rounded-md"}>
			<h1 className={"text-white mb-2 text-lg font-bold"}>Le Bail</h1>
			<div className={"p-2 flex flex-col bg-primary-600 mb-2 rounded-md w-full"}>
				<h1 className={"font-bold text-white text-base mb-2"}>
					Select Files
				</h1>
				<FileSelection
					dispatch={dispatch}
					selected={dataFiles}
					setSelected={files => dispatch({ type: SET_LE_BAIL_DATA_FILES, payload: files })}
					title="Select Data File(s)"
					extensions={[".dat"]}
					defaultExtension={".dat"}
					multiple={true}
				/>
				<br />
				<FileSelection
					dispatch={dispatch}
					selected={cifFiles}
					selectedTemplates={cifTemplateFiles}
					setSelected={files => dispatch({ type: SET_LE_BAIL_CIF_FILES, payload: files })}
					setSelectedTemplates={files => dispatch({ type: SET_LE_BAIL_CIF_TEMPLATE_FILES, payload: files })}
					title="Select Cif File(s)"
					extensions={[".cif"]}
					defaultExtension={".cif"}
					multiple={true}
					templates={cifFilesDirectories}
				/>
				<br />
				<FileSelection
					dispatch={dispatch}
					selected={instrumentalFiles}
					selectedTemplates={instrumentalTemplateFile}
					setSelected={file => dispatch({ type: SET_LE_BAIL_INSTRUMENTAL_FILE, payload: file })}
					setSelectedTemplates={file => dispatch({ type: SET_LE_BAIL_INSTRUMENTAL_TEMPLATE_FILE, payload: file })}
					title="Select Instrumental File"
					extensions={[".instprm"]}
					defaultExtension=".instprm"
					multiple={false}
					templates={instrumentalFilesDirectories}
				/>
				<br />
			</div>
			<div className={"p-2 flex flex-col bg-primary-600 mb-2 rounded-md w-full"}>
				<h1 className={"font-bold text-white text-base mb-2"}>
					Inputs
				</h1>
				<WavelengthInput
					fileWaveLength={fileWavelength}
					wavelength={wavelength}
					setWavelength={(wavelength: number) => dispatch({ type: SET_LE_BAIL_WAVELENGTH, payload: wavelength })}
				/>
				<CifFileABC
					dispatch={dispatch}
					cifFilesABC={cifFilesABC}
					cifFiles={cifFiles}
					cifTemplateFiles={cifTemplateFiles}
					analysisMethod={"leBail"}
					setABCValues={(abcValues) => dispatch({ type: SET_LE_BAIL_CIF_FILES_ABC, payload: abcValues })}
				/>
				<h1 className={"font-bold text-white text-base mt-4"}>Range</h1>
				<div className={"flex flex-row items-center justify-center"}>
					<div className={"flex flex-col items-center justify-center w-1/2 ml-5 mr-5"}>
						<label className={"text-white"}>
							Range Lower
						</label>
						<div className="flex flex-row">
							<input
								className={`${false ? "border-red-500" : "border-gray-500"}
									bg-primary-700 p-2 rounded-l-md border border-solid text-white w-full`}
								type={"number"}
								step="0.1"
								value={rangeLower}
								onChange={(event) => {dispatch({type: SET_LE_BAIL_RANGE_LOWER, payload: parseFloat(event.target.value)})}}
							/>
							<button
								className={"cursor-pointer text-white font-bold bg-primary-500 rounded-r-md p-2"}
								onClick={() => {dispatch({type: SET_LE_BAIL_RANGE_LOWER, payload: initialRange[0]})}}
							>
								<FontAwesomeIcon icon={faRotateRight} />
							</button>
						</div>
					</div>
					<div className={"flex flex-col items-center justify-center w-1/2 ml-5 mr-5"}>
						<label className={"text-white"}>
							Range Upper
						</label>
						<div className="flex flex-row">
							<input
								className={`${false ? "border-red-500" : "border-gray-500"}
									bg-primary-700 p-2 rounded-l-md border border-solid text-white w-full`}
								type={"number"}
								step="0.1"
								value={rangeHigher}
								onChange={(event) => {dispatch({type: SET_LE_BAIL_RANGE_HIGHER, payload: parseFloat(event.target.value)}); }}
							/>
							<button
								className={"cursor-pointer text-white font-bold bg-primary-500 rounded-r-md p-2"}
								onClick={() => {dispatch({type: SET_LE_BAIL_RANGE_HIGHER, payload: initialRange[1]}); }}
							>
								<FontAwesomeIcon icon={faRotateRight} />
							</button>
						</div>
					</div>
				</div>
			</div>
			<button
				className={"cursor-pointer text-white font-bold bg-primary-600 m-4 p-4 rounded-md"}
				onClick={() => {
					if(!dataActiveFiles.length) {
						toast.error("Please Select Data Files");
						return;
					}

					if (!rangeLower || !rangeHigher) {
						toast.error("Please Enter Range Lower and Range Higher");
						return;
					}

					if (!instrumentalFile && (!instrumentalTemplateFile && !wavelength)) {
						toast.error("Please Select Instrumental File or Enter Wavelength");
						return;
					}

					if (!cifFiles.length && !cifTemplateFiles.length) {
						toast.error("Please Select CIF Files");
						return;
					}
					
					dispatch({ type: GET_LE_BAIL_REFINED_HISTOGRAM })
				}}
			>
				Save and calculate
			</button>
			<button
				className={`cursor-pointer ${dataFiles.length && refinedHistogram && refinedHistogram.calculations && refinedHistogram.calculations.length ?  "text-white bg-primary-600" : "text-gray-600 bg-primary-700"} font-bold m-4 p-4 rounded-md`}
				onClick={() => {
					dispatch({ type: GET_DOWNLOAD, payload: { type: "leBail" } })
				}}
			>
				Download Data
			</button>
		</div>
	</>
	);
};

export default LeBailInput;

