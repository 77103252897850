import { colorHistogram, colorsGraph, colorsGraphMix } from "../../data/colors";
import { getName } from "../../helpers/name";

export function getPartitionHistogramGraph(histogram, partitions, selectedPartitionIndex, partitionFitting, dataViewFile) {
  let histogramData = []
  let chartShapesCurrent = []
  let traces = [];
  let currentChartShapes = {}

  let partitionFittingRes = partitionFitting && Object.keys(partitionFitting).length ? partitionFitting[dataViewFile] : undefined;

  if (histogram.instrumental.x && histogram.instrumental.y) {
    let new_x, new_y;

    if (partitions[selectedPartitionIndex].rangeLower?.x && partitions[selectedPartitionIndex].rangeHigher?.x) {
      new_x = histogram.instrumental.x.filter((x) => x >= partitions[selectedPartitionIndex].rangeLower.x && x <= partitions[selectedPartitionIndex].rangeHigher.x);
      new_y = histogram.instrumental.y.slice(histogram.instrumental.x.indexOf(new_x[0]), histogram.instrumental.x.indexOf(new_x[new_x.length - 1]) + 1);
    } else {
      new_x = histogram.instrumental.x;
      new_y = histogram.instrumental.y;
    }

    histogramData = [
      {
        x: histogram.instrumental.x,
        y: histogram.instrumental.y,
        type: "linear",
        name: "Data",
        mode: "lines",
        line: {
          color: "rgb(180, 180, 180)",
          width: 1,
        },
      },
      {
        x: new_x,
        y: new_y,
        type: "linear",
        name: "Data",
        mode: "lines",
        line: {
          color: colorsGraph[0],
          width: 2,
        },
      },
      ...histogram.phase.map((d, index) => ({
        x: [histogram.instrumental.x[0]],
        y: [histogram.instrumental.y[0]],
        type: "linear",
        name: getName(d.name),
        mode: "lines",
        line: {
          color: colorHistogram[index],
          width: 2,
          dash: "dash",
        },
      })),
    ];

    chartShapesCurrent = histogram.phase.flatMap((d, index) => (
      d.data.map((data) => {
        if (Array.isArray(data.x)) {
          return ({
            type: "line",
            x0: data.x[0],
            y0: 0,
            x1: data.x[0],
            y1: data.y_max * 1.2,
            opacity: 1,
            line: {
              dash: "dashdot",
              color: colorHistogram[index],
              width: 3,
            },
          })
        } else {
          return ({
            type: "line",
            x0: data.x,
            y0: 0,
            x1: data.x,
            y1: data.y_max * 1.2,
            opacity: 0.8,
            line: {
              dash: "dash",
              color: colorHistogram[index],
              width: 1.5,
            },
          })
        }
      })
    ));

    partitions.forEach((partition, index) => {
      const trace = {
        x: [],
        y: [],
        mode: 'markers',
        marker: {
          color: colorHistogram[index],
          size: 10,
        },
        name: `Partition ${partition.name} Peak`,
      };

      partition.peaks.forEach((position) => {
        trace.x.push(position.x);
        trace.y.push(position.y);
      });

      traces.push(trace)
    });
  }

  if (partitionFittingRes) {
    for (const key in partitionFittingRes) {
      let colorIndex = 0;

      const currentPartitionFitting = partitionFittingRes[key];

      const data = [
        {
          x: currentPartitionFitting.originalData.x,
          y: currentPartitionFitting.originalData.y,
          type: "linear",
          name: "Data",
          mode: "lines",
          line: {
            color: colorsGraphMix[colorIndex],
            width: 2,
            dash: 'dot',
          },
        },
        {
          x: currentPartitionFitting.bestFit.x,
          y: currentPartitionFitting.bestFit.y,
          type: "linear",
          name: "Best Fit",
          mode: "lines",
          line: {
            color: colorsGraphMix[colorIndex + 1],
            width: 2,
          },
        },
        {
          x: currentPartitionFitting.background.x,
          y: currentPartitionFitting.background.y,
          type: "linear",
          name: "Background",
          mode: "lines",
          line: {
            color: colorsGraphMix[colorIndex + 2],
            width: 2,
          },
        },
      ];

      colorIndex += 3

      if (currentPartitionFitting.components.gaussian.length) {
        currentPartitionFitting.components.gaussian.forEach((gaussian) => {
          data.push({
            x: gaussian.x,
            y: gaussian.y,
            type: "linear",
            mode: "lines",
            line: {
              color: colorsGraphMix[colorIndex],
              width: 2,
            },
            name: "Gaussian",
          })
        })
        colorIndex++;
      }

      if (currentPartitionFitting.components.lorentzian.length) {
        currentPartitionFitting.components.lorentzian.forEach(lorentzian => {
          data.push({
            x: lorentzian.x,
            y: lorentzian.y,
            type: "linear",
            name: "Lorentzian",
            mode: "lines",
            line: {
              color: colorsGraphMix[colorIndex],
              width: 1,
            },
          })
        })
        colorIndex++;
      }
      if (currentPartitionFitting.components.pseudoVoigt.length) {
        currentPartitionFitting.components.pseudoVoigt.forEach(pseudoVoigt => {
          data.push({
            x: pseudoVoigt.x,
            y: pseudoVoigt.y,
            type: "linear",
            name: "Pseudo Voigt",
            mode: "lines",
            line: {
              color: colorsGraphMix[colorIndex],
              width: 1,
            },
          })
        })
        colorIndex++;
      }
      if (currentPartitionFitting.components.pearson7.length) {
        currentPartitionFitting.components.pearson7.forEach(pearson7 => {
          data.push({
            x: pearson7.x,
            y: pearson7.y,
            type: "linear",
            name: "Pearson 7",
            mode: "lines",
            line: {
              color: colorsGraphMix[colorIndex],
              width: 1,
            },
          })
        })
        colorIndex++;
      }

      currentChartShapes[key] = data;
    }
  }

  return {
    histogramData: histogramData, 
    chartShapesCurrent: chartShapesCurrent,
    traces: traces,
    currentChartShapes: currentChartShapes
  }
}
