// SETTERS
export const SET_INSTRUMENTAL_FILE_CREATION = "SET_INSTRUMENTAL_FILE_CREATION";

export const SET_INSTRUMENTAL_FILE_CREATION_CIF_FILES = "SET_INSTRUMENTAL_FILE_CREATION_CIF_FILES";
export const SET_INSTRUMENTAL_FILE_CREATION_CIF_TEMPLATE_FILES = "SET_INSTRUMENTAL_FILE_CREATION_CIF_TEMPLATE_FILES";
export const SET_INSTRUMENTAL_FILE_CREATION_CIF_FILES_ABC = "SET_INSTRUMENTAL_FILE_CREATION_CIF_FILES_ABC";

export const SET_INSTRUMENTAL_FILE_CREATION_INSTRUMENTAL_TEMPLATE_FILE = "SET_INSTRUMENTAL_FILE_CREATION_INSTRUMENTAL_TEMPLATE_FILE";

export const SET_INSTRUMENTAL_FILE_CREATION_FILE_WAVELENGTH = "SET_INSTRUMENTAL_FILE_CREATION_FILE_WAVELENGTH";
export const SET_INSTRUMENTAL_FILE_CREATION_WAVELENGTH = "SET_INSTRUMENTAL_FILE_CREATION_WAVELENGTH";

export const SET_INSTRUMENTAL_FILE_CREATION_DATA_FILES = "SET_INSTRUMENTAL_FILE_CREATION_DATA_FILES";
export const SET_INSTRUMENTAL_FILE_CREATION_DATA_ACTIVE_FILES = "SET_INSTRUMENTAL_FILE_CREATION_DATA_ACTIVE_FILES";
export const SET_INSTRUMENTAL_FILE_CREATION_DATA_VIEW_FILE = "SET_INSTRUMENTAL_FILE_CREATION_DATA_VIEW_FILE";

export const SET_INSTRUMENTAL_FILE_CREATION_CRYSTALLITE_SIZE = "SET_INSTRUMENTAL_FILE_CREATION_CRYSTALLITE_SIZE";
export const SET_INSTRUMENTAL_FILE_CREATION_MICROSTRAIN = "SET_INSTRUMENTAL_FILE_CREATION_MICROSTRAIN";

export const SET_INSTRUMENTAL_FILE_CREATION_REFINEMENT_RANGE_LOWER = "SET_INSTRUMENTAL_FILE_CREATION_REFINEMENT_RANGE_LOWER";
export const SET_INSTRUMENTAL_FILE_CREATION_REFINEMENT_RANGE_UPPER = "SET_INSTRUMENTAL_FILE_CREATION_REFINEMENT_RANGE_UPPER";
export const SET_INSTRUMENTAL_FILE_CREATION_RANGE_HISTORY = "SET_INSTRUMENTAL_FILE_CREATION_RANGE_HISTORY";
export const SET_INSTRUMENTAL_FILE_CREATION_RANGE_INITIAL = "SET_INSTRUMENTAL_FILE_CREATION_RANGE_INITIAL";

export const SET_INSTRUMENTAL_FILE_CREATION_HISTOGRAM = "SET_INSTRUMENTAL_FILE_CREATION_HISTOGRAM";

export const SET_INSTRUMENTAL_FILE_CREATION_RANGE = "SET_INSTRUMENTAL_FILE_CREATION_RANGE"
export const SET_INSTRUMENTAL_FILE_CREATION_ANALYSIS = "SET_INSTRUMENTAL_FILE_CREATION_ANALYSIS";

export const RESET_INSTRUMENTAL_FILE_CREATION = "RESET_INSTRUMENTAL_FILE_CREATION";

// GETTERS
export const GET_INSTRUMENTAL_FILE_CREATION_HISTOGRAM = "GET_INSTRUMENTAL_FILE_CREATION_HISTOGRAM";
export const GET_INSTRUMENTAL_FILE_CREATION = "GET_INSTRUMENTAL_FILE_CREATION";
export const GET_INSTRUMENTAL_FILE_CREATION_CALLBACK = "GET_INSTRUMENTAL_FILE_CREATION_CALLBACK";
export const GET_INSTRUMENTAL_FILE_CREATION_DOWNLOAD = "GET_INSTRUMENTAL_FILE_CREATION_DOWNLOAD";
