export function compareKeys(a: string, b: string): number {
  const hasNumbersA = /\d/.test(a);
  const hasNumbersB = /\d/.test(b);

  if (hasNumbersA && hasNumbersB) {
    return naturalSort(a, b);
  } else if (hasNumbersA) {
    return -1;
  } else if (hasNumbersB) {
    return 1;
  } else {
    return a.localeCompare(b);
  }
}

function naturalSort(a: string, b: string): number {
  const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
  return collator.compare(a, b);
}


function extractNumberFromPath(path: string): number {
  const regex = /_(\d+)\.dat$/;
  const match = path.match(regex);
  return match ? parseInt(match[1], 10) : 0;
}

export function compareKeysV2(a: string, b: string): number {
  const numberA = extractNumberFromPath(a);
  const numberB = extractNumberFromPath(b);

  if (numberA !== numberB) {
      return numberA - numberB;
  }

  return a.localeCompare(b);
}

function base64DecodeUnicode(str: string) {
  return decodeURIComponent(atob(str).replace(/(.)/g, (m, p) => {
    const code = p.charCodeAt(0).toString(16).toUpperCase();
    return `%${code.length < 2 ? '0' : ''}${code}`;
  }));
}

export function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = base64DecodeUnicode(base64);
  return JSON.parse(jsonPayload);
}
