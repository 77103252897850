import { Histogram } from "../../models/analysis.general.model";
import { RietveldFitting, RietveldModel } from "../../models/analysis.rietveld.model";
import { SET_RIETVELD_DATA_FILES, SET_RIETVELD_CIF_FILES, SET_RIETVELD_INSTRUMENTAL_FILE, SET_RIETVELD_WAVELENGTH, SET_RIETVELD_RANGE, SET_RIETVELD_RANGE_LOWER, SET_RIETVELD_RANGE_HIGHER, SET_RIETVELD_HISTOGRAM, SET_RIETVELD_INSTRUMENTAL_TEMPLATE_FILE, SET_RIETVELD_CIF_TEMPLATE_FILES, SET_RIETVELD_REFINED_HISTOGRAM, SET_RIETVELD, SET_RIETVELD_DATA_VIEW_FILE, SET_RIETVELD_RANGE_INITIAL, SET_RIETVELD_RESET, SET_RIETVELD_INSTRUMENTAL_WAVELENGTH, SET_RIETVELD_MUSTRAINS, SET_RIETVELD_PARAMETERS, SET_RIETVELD_CRYSTALLITE_SIZE, SET_RIETVELD_DATA_ACTIVE_FILES, SET_RIETVELD_CIF_FILES_ABC } from "./rietveldActions";

const initialState: RietveldModel = {
  dataFiles: [],
  dataActiveFiles: [],
  cifFiles: [],
  cifFilesABC: {},
  instrumentalFile: "",
  wavelength: 0,
  rangeHistory: [0, 0],
  rangeLower: 0,
  rangeHigher: 0,
  histogram: {} as Histogram,
  refinedHistogram: {
    observations: [],
    background: [],
    calculations: [],
    residual: [],
  } as RietveldFitting,
  cifTemplateFiles: [],
  instrumentalTemplateFile: "",
  instrumentalFileWaveLength: 0,
  dataViewFile: "",
  rangeInitial: [0, 0],
  parameters: {},
  mustrains: {},
  crystalliteSize: 0
};

export default function rietveldReducer(state = initialState, { type, payload }: { type: string; payload: any }): RietveldModel {
  switch (type) {
    case SET_RIETVELD_DATA_FILES: {
      return {
        ...state,
        dataFiles: payload,
      }
    }
    case SET_RIETVELD_CIF_FILES: {
      return {
        ...state,
        cifFiles: payload,
      }
    }
    case SET_RIETVELD_INSTRUMENTAL_FILE: {
      return {
        ...state,
        instrumentalFile: payload,
      }
    }
    case SET_RIETVELD_WAVELENGTH: {
      return {
        ...state,
        wavelength: payload,
      }
    }
    case SET_RIETVELD_RANGE: {
      const range = state.rangeHistory;
      range[1] = range[0];
      range[0] = payload;

      return {
        ...state,
        rangeHistory: range,
        rangeLower: range[0] <= range[1] ? range[0] : range[1],
        rangeHigher: range[0] > range[1] ? range[0] : range[1],
      }
    }
    case SET_RIETVELD_RANGE_LOWER: {
      return {
        ...state,
        rangeLower: payload
      }
    }
    case SET_RIETVELD_RANGE_HIGHER: {
      return {
        ...state,
        rangeHigher: payload
      }
    }
    case SET_RIETVELD_HISTOGRAM: {
      return {
        ...state,
        histogram: payload,
      }
    }
    case SET_RIETVELD_INSTRUMENTAL_TEMPLATE_FILE: {
      return {
        ...state,
        instrumentalTemplateFile: payload,
      }
    }
    case SET_RIETVELD_CIF_TEMPLATE_FILES: {
      return {
        ...state,
        cifTemplateFiles: payload,
      }
    }
    case SET_RIETVELD_REFINED_HISTOGRAM: {
      return {
        ...state,
        refinedHistogram: payload,
      }
    }
    case SET_RIETVELD: {
      return {
        ...payload,
      }
    }
    case SET_RIETVELD_DATA_VIEW_FILE:
      return {
        ...state,
        dataViewFile: payload,
      }
    case SET_RIETVELD_RANGE_INITIAL:
      return {
        ...state,
        rangeInitial: payload,
      }
    case SET_RIETVELD_RESET:
      return {
        ...initialState,
      }
    case SET_RIETVELD_INSTRUMENTAL_WAVELENGTH:
      return {
        ...state,
        instrumentalFileWaveLength: payload
      }
    case SET_RIETVELD_PARAMETERS:
      return {
        ...state,
        parameters: payload
      }
    case SET_RIETVELD_MUSTRAINS:
      return {
        ...state,
        mustrains: {
          ...state.mustrains,
          ...payload
        }
      }
    case SET_RIETVELD_CRYSTALLITE_SIZE:
      return {
        ...state,
        crystalliteSize: payload
      }
    case SET_RIETVELD_DATA_ACTIVE_FILES:
      return {
        ...state,
        dataActiveFiles: payload,
      }
    case SET_RIETVELD_CIF_FILES_ABC:
      return {
        ...state,
        cifFilesABC: {
          ...payload
        },
      }
    default:
      return state;
  }
}
